import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TeamMemberService } from '../../../../services/settings/team-member/team-member.service';
import { InviteMemberDetails, MemberForDisplay } from '@airwallet/shared-models/invited-user';
import { LoggedInDashboardUser } from '@dashboard_models/dashboard-user';
import { ToastrService } from 'ngx-toastr';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { LoadingComponent } from '../../../loading/loading.component';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-invite-member-modal',
    templateUrl: './invite-member-modal.component.html',
    styleUrls: ['./invite-member-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgIf, MatRadioGroup, MatRadioButton, LoadingComponent, TranslateModule]
})
export class InviteMemberModalComponent implements OnInit {
    sendingData = false;
    inviteForm: UntypedFormGroup;
    formSubmitted = false;
    isEdit = false;
    isMobile: boolean;
    loggedInUser: LoggedInDashboardUser;

    @Input() editData: MemberForDisplay;
    @Output() emitTeamMember: EventEmitter<MemberForDisplay> = new EventEmitter<MemberForDisplay>();
    @Output() emitDelete: EventEmitter<MemberForDisplay> = new EventEmitter<MemberForDisplay>();
    constructor(
        public modalService: NgbModal,
        public translate: TranslateService,
        private toast: ToastrService,
        private teamMemberService: TeamMemberService,
        private breakpointObserver: BreakpointObserver,
        private localStorageService: LocalStorageService
    ) {
        this.inviteForm = new FormGroup({
            email: new UntypedFormControl(null, [Validators.required, Validators.email]),
            role: new UntypedFormControl('editor', Validators.required)
        });
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnInit(): void {
        this.loggedInUser = this.localStorageService.getItem('loggedInUser');
        if (this.editData) {
            this.isEdit = true;
            this.email.setValue(this.editData.mail);
            this.email.disable();
            this.role.setValue(this.editData.role);
            this.updateFormValueAndValidity();
        }
    }

    updateFormValueAndValidity() {
        this.email.updateValueAndValidity();
        this.role.updateValueAndValidity();
    }

    get email() {
        return this.inviteForm.get('email');
    }
    get role() {
        return this.inviteForm.get('role');
    }

    async emitRemoveMember(): Promise<void> {
        this.emitDelete.next(this.editData);
    }

    async onSubmit() {
        this.formSubmitted = true;

        const invitedUserParams: InviteMemberDetails = {
            email: this.editData && this.editData.mail ? this.editData.mail : null,
            ...this.inviteForm.value,
            inviteSentFrom: this.loggedInUser.name
        };

        if (this.inviteForm.valid) {
            this.sendingData = true;
            await this.teamMemberService
                .inviteOrEditMember(invitedUserParams, !!this.editData)
                .then(response => {
                    this.sendingData = false;
                    this.emitTeamMember.next(response as MemberForDisplay);
                    this.modalService.dismissAll();
                })
                .catch(error => {
                    this.sendingData = false;
                    if (error && error.error && error.error.error.includes('err_msg')) {
                        this.toast.warning(this.translate.instant(error.error.error), this.translate.instant('misc.warning'));
                    } else {
                        this.toast.warning(this.translate.instant('account.err_update_email'), this.translate.instant('misc.warning'));
                    }
                });
        }
    }
}
