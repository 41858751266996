import { SearchResult } from '@airwallet/shared-models/search';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Search from '@airwallet/shared-models/search';
import { Injectable } from '@angular/core';
import { HelperService } from '../helper/helper.service';
import { HttpService, RequestTypes } from '../helper/http.service';
import { searchSuggestions } from '../../components/navbar/search-preview/search-suggestions';
import { AuthService } from '../auth/auth.service';

interface RouteType {
    path: string;
    params?: object;
}

@Injectable({
    providedIn: 'root'
})
export class SearchService {
    searchResult: BehaviorSubject<SearchResult | null> = new BehaviorSubject<SearchResult | null>(null);
    searchQuery: string;
    searchFinished: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isAdmin$: Observable<boolean> = this.authService.isAdmin;
    stopSearch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    get getSearchResult() {
        return this.searchResult.asObservable();
    }

    constructor(
        private httpService: HttpService,
        private helperService: HelperService,
        private authService: AuthService
    ) {}

    async ReadSearchResults(searchString: string): Promise<void> {
        console.log('Searching for data');
        this.searchResult.next(null);
        this.searchFinished.next(false);
        this.searchQuery = searchString;
        searchString = this.cleanSearchString(searchString);

        if (!searchString) {
            this.searchResult.next({} as SearchResult);
        }

        const res: SearchResult = await this.httpService.dynamicHttp(`api_insights/search_V2`, RequestTypes.GET, { params: { searchString } });
        Object.keys(res).forEach(key => {
            if (res[key].length < 1) {
                delete res[key];
            }
        });
        this.searchResult.next(res);

        console.log('first search result', res);
        const statsRes: SearchResult = await this.httpService.dynamicHttp(`api_insights/searchStats`, RequestTypes.POST, { body: res });
        Object.keys(res).forEach(key => {
            if (res[key].length < 1) {
                delete res[key];
            }
        });

        // If the user has already clicked on a value, we dont want to show the search stats, since it will re-open the search
        if (this.stopSearch.value) {
            this.searchResult.next(null);
            this.searchFinished.next(true);
            return;
        }

        this.searchResult.next(statsRes);
        this.searchFinished.next(true);
    }

    setStopSearch(value: boolean): void {
        this.stopSearch.next(value);
    }

    getPreviewResults(searchResult: SearchResult, limit?: number): SearchResult {
        const defaultLimit = 5;
        const results = Object.assign({}, searchResult);
        if (results) {
            Object.keys(results).forEach(key => {
                results[key] = results[key].slice(0, limit ? limit : defaultLimit);
            });
        }
        return results ? results : ({} as SearchResult);
    }

    highlightText(text: string | number, phoneNo?: boolean): string {
        text = text.toString();

        if (!text || !this.searchQuery || text.startsWith(' • • • •')) {
            return text;
        }

        if (phoneNo) {
            const escapedSearchQuery = this.searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&').replace(/\s/g, '');
            const highlightedText = text.replace(/\s/g, '').replace(new RegExp(escapedSearchQuery, 'gi'), match => {
                return `<label class="highlight">${this.helperService.normalizePhoneNumber(match)}</label>`;
            });

            return highlightedText;
        } else {
            const escapedSearchQuery = this.searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const highlightedText = text.replace(new RegExp(escapedSearchQuery, 'gi'), match => {
                return `<label class="highlight">${match}</label>`;
            });

            return highlightedText;
        }
    }

    async getPath(cat: string, item: unknown, userUid: string): Promise<RouteType> {
        const route: RouteType = { path: '' };
        switch (cat) {
            case 'locations': {
                const location = item as Search.Location;
                console.log(item);
                if (location.owner_uid && location.owner_uid !== userUid) {
                    route.path = `customers/${location.owner_uid.split('_operated_by_')[0]}/locations/${location.location_key}`;
                } else {
                    route.path = `locations/${location.location_key}`;
                }
                route.params = { name: location.name };
                break;
            }
            case 'customers': {
                const customer = item as Search.Customer;
                route.path = `customers/${customer.customer_uid.split('_operated_by_')[0]}/locations`;
                route.params = { name: customer.name };
                break;
            }
            case 'devices': {
                const device = item as Search.Device;
                if (device.owner_uid && device.owner_uid !== userUid) {
                    route.path = `customers/${device.owner_uid.split('_operated_by_')[0]}/locations/${device.location_key}/devices/${device.device_key}`;
                } else {
                    route.path = `locations/${device.location_key}/devices/${device.device_key}`;
                }
                route.params = { name: device.name };
                break;
            }
            case 'terminals': {
                const terminal = item as Search.Terminal;
                if (await this.authService.getAdminStatus()) {
                    route.path = `support/anton-health`;
                    route.params = { highlight: terminal.serial };
                } else if (await this.authService.getOperatorStatus()) {
                    route.path = `operator/anton-health`;
                    route.params = { highlight: terminal.serial };
                } else {
                    console.log('user routing');

                    if (terminal.owner_uid && terminal.owner_uid !== userUid) {
                        route.path = `customers/${terminal.owner_uid.split('_operated_by_')[0]}/locations/${terminal.location_key}`;
                    } else {
                        route.path = `locations/${terminal.location_key}`;
                    }
                    route.params = { name: terminal.location_name };
                }
                break;
            }
            case 'contracts':
                route.params = { id: (item as Search.Contract).contract_key };
                route.path = `/operator/contracts`;
                break;
            case 'settlements':
                // route.params = { po_id: (item as Search.Settlement).payout_id };
                // route.path = `transactions`;
                window.open((item as Search.Settlement).link);
                break;
            case 'transactions': {
                route.path = `transactions`;
                route.params = { docId: (item as Search.Transaction).docId, uid: (item as Search.Transaction).uid.split('_operated_by_')[0] };
                const transactionUnit: object = item as Search.Transaction;

                if (Object.prototype.hasOwnProperty.call(transactionUnit, 'device_key')) {
                    // Device
                    const transactionDev = item as Search.Device;
                    console.log('device');
                    route.params = { location_id: transactionDev.location_key, device_id: transactionDev.device_key, customer_uid: transactionDev.owner_uid.split('_operated_by_')[0] };
                } else if (Object.prototype.hasOwnProperty.call(transactionUnit, 'location_key')) {
                    // Location
                    console.log('location');
                    const transactionLoc = item as Search.Location;
                    route.params = { location_id: transactionLoc.location_key, customer_uid: transactionLoc.owner_uid.split('_operated_by_')[0] };
                }
                break;
            }
            case 'users':
                route.path = `users/${(item as Search.User).id}`;
                route.params = { owner_uid: (item as Search.User).owner_uid };
                break;
            case 'coupons':
                route.path = `coupon/${(item as Search.Coupon).id}`;
                break;

            default:
                break;
        }
        return route;
    }

    cleanSearchString(searchString: string): string {
        let trimmedSearchString = '';

        for (const suggestion of searchSuggestions) {
            // trim spaces around the search query
            trimmedSearchString = searchString.includes(suggestion.short_code) ? `${suggestion.short_code}${searchString.split(suggestion.short_code)[1].trim()}` : searchString.trim();
        }

        if (
            // The backend renocgnizes the following string without prefix, but the ui wants to show it as an option
            trimmedSearchString.startsWith('a:') ||
            trimmedSearchString.startsWith('u:')
        ) {
            return trimmedSearchString.substring(2);
        } else if (trimmedSearchString.endsWith(':')) {
            // if prefix but nothing after it, then return empty string
            return '';
        } else {
            return trimmedSearchString;
        }
    }

    phoneNumberFormat(str: string) {
        let formattedStr = str;
        if (str.at(0) === '+' || str.includes('u:')) {
            // is most likely a phone number
            formattedStr = str.replaceAll(' ', '');
            formattedStr = formattedStr.replace(/\(.*?\)/g, '');

            if (str.at(0) !== '+' && !str.includes('+')) {
                formattedStr = `u:+${formattedStr.split('u:')[1]}`;
            }
        }
        return formattedStr;
    }
}
