import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { DetailedIncident } from '@airwallet/shared-models/anton-health';
import dayjs from 'dayjs';
import { HelperService } from '../../../../services/helper/helper.service';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AntonHealthServiceService } from '../../../../services/anton-health/anton-health-service.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AwCheckboxComponent } from '../../../misc/aw-checkbox/aw-checkbox.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-incident-comment-modal',
    templateUrl: './incident-comment-modal.component.html',
    styleUrls: ['./incident-comment-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgFor, NgIf, NgClass, LoadingComponent, FormsModule, ReactiveFormsModule, AwCheckboxComponent, TranslateModule]
})
export class IncidentCommentModalComponent implements AfterViewInit {
    @Input() incidentToEdit: DetailedIncident;
    @Input() serial: string;

    @Output() emitNewIncident = new EventEmitter<DetailedIncident>();

    @ViewChild('scrollBox') scrollBox: ElementRef;

    incidentForm: UntypedFormGroup;
    formBuilder: UntypedFormBuilder;
    sendingData = false;
    loadingNoteId: string = null;

    readonly maxLength: number = 600;
    protected isMobile = false;

    constructor(
        protected helperService: HelperService,
        private antonHealthService: AntonHealthServiceService,
        private toast: ToastrService,
        private translate: TranslateService,
        private renderer: Renderer2,
        private breakpointObserver: BreakpointObserver
    ) {
        this.incidentForm = new FormGroup({
            comment: new UntypedFormControl(null, [Validators.required, Validators.maxLength(this.maxLength)]),
            isSolution: new UntypedFormControl(null)
        });
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngAfterViewInit() {
        this.setPaddingForScrollBox();
    }

    get comment() {
        return this.incidentForm.get('comment');
    }

    get isSolution() {
        return this.incidentForm.get('isSolution');
    }

    setPaddingForScrollBox() {
        setTimeout(() => {
            const height = this.scrollBox.nativeElement.offsetHeight;
            const maxHeight = this.isMobile ? 300 : 500;
            console.log('isMobile', this.isMobile, 'height', height, 'maxHeight', maxHeight);
            if (height >= maxHeight) {
                // replace 500 with the height you want to check
                this.renderer.addClass(this.scrollBox.nativeElement, 'padding');
                this.scrollBox.nativeElement.scrollTo(0, this.scrollBox.nativeElement.scrollHeight);
            }
        }, 0);
    }

    countCharacters(textarea: HTMLTextAreaElement, counterId: string) {
        const characterCount: number = textarea.value.length;
        const countDisplay: HTMLElement = document.getElementById(counterId);
        countDisplay.textContent = characterCount + '/' + this.maxLength;
    }

    resetCharacterCount(counterId: string) {
        const countDisplay: HTMLElement = document.getElementById(counterId);
        countDisplay.textContent = '0/' + this.maxLength;
    }

    changeSolutionStatus(noteId: string, isSolution: boolean) {
        this.loadingNoteId = noteId;
        this.sendingData = true;
        this.antonHealthService
            .markAsSolution(this.serial, this.incidentToEdit.id, noteId, isSolution)
            .then((incident: DetailedIncident) => {
                this.incidentToEdit = incident;
                this.toast.success(this.translate.instant('operator.anton_health.solution_success'), this.translate.instant('misc.success'));
                this.emitNewIncident.next(incident);
            })
            .catch(() => {
                this.toast.error(this.translate.instant('operator.anton_health.solution_fail'), this.translate.instant('misc.error'));
            })
            .finally(() => {
                this.sendingData = false;
                this.loadingNoteId = null;
            });
    }

    async onSubmit() {
        if (this.incidentForm.valid) {
            this.sendingData = true;
            this.loadingNoteId = 'DUMMY';
            await this.antonHealthService
                .writeComment(this.comment.value, this.serial, this.incidentToEdit.id, this.isSolution.value)
                .then((incident: DetailedIncident) => {
                    this.incidentToEdit = incident;
                    this.comment.setValue(null);
                    this.toast.success(this.translate.instant('operator.anton_health.comment_success'), this.translate.instant('misc.success'));
                    this.emitNewIncident.next(incident);
                })
                .catch(() => {
                    this.toast.error(this.translate.instant('operator.anton_health.comment_fail'), this.translate.instant('misc.error'));
                })
                .finally(() => {
                    this.sendingData = false;
                    this.loadingNoteId = null;
                    this.resetCharacterCount('char-counter');
                    this.setPaddingForScrollBox();
                });
        }
    }

    protected readonly dayjs = dayjs;
}
