export type SearchSuggestions = { id: string; short_code: string; translation_code: string; example: string }[];
export const searchSuggestions: SearchSuggestions = [
    {
        id: 'custom_location_id',
        short_code: 'cl:',
        translation_code: 'search.suggestions.custom_location_id',
        example: 'cl:12345678'
    },
    {
        id: 'user_phone_number',
        short_code: 'u:',
        translation_code: 'search.suggestions.user_phone_number',
        example: 'u:+4500000000'
    },
    {
        id: 'contract_id',
        short_code: 'c:',
        translation_code: 'search.suggestions.contract_id',
        example: 'c:12345'
    },
    {
        id: 'last4',
        short_code: 'l4:',
        translation_code: 'search.suggestions.last4',
        example: 'l4:4200'
    },
    {
        id: 'anton_terminal',
        short_code: 'a:',
        translation_code: 'search.suggestions.anton_terminal',
        example: 'a:WPC123456789100'
    },
    {
        id: 'coupon_code',
        short_code: 'cc:',
        translation_code: 'search.suggestions.coupon_code',
        example: 'cc:BLACKFRIDAY50'
    },
    {
        id: 'settlement_id',
        short_code: 's:',
        translation_code: 'search.suggestions.settlement_id',
        example: 's:po-AbCdeFg9iJkL7yZ'
    }
];
