<!-- This Route: 'locations' || 'customers/:sub_customer_id/locations'-->

<app-page-layout [pageTitle]="!isCustomerOperated ? ('misc.locations' | translate) : (subCustomer && subCustomer.call_name) || (subCustomer && subCustomer.name)">
    <ng-container buttons>
        @if (subCustomer && subCustomerPermissions) {
            <app-location-control-panel [subCustomerPermissions]="subCustomerPermissions" [style]="isMobile ? '' : 'margin-right: 15px'" [isOperator]="isOperator" />
        }
        <app-aw-filter-button
            [style]="isMobile ? '' : 'margin-right: 15px'"
            [filterOptions]="filterOptions"
            [popupAnchor]="isMobile && !(subCustomer && subCustomerPermissions) ? 'left' : 'right'"
            (filterChanged)="catchFilterChanged($event)"
        />
        <app-aw-export-button
            [style]="isMobile ? '' : 'margin-right: 15px'"
            [disabled]="showLoadingIndicator || locations.length === 0"
            exportPath="api_location/export"
            exportMailPath="api_export/locations"
            [limitBeforeMail]="500"
            [uidOnBehalf]="subCustomerUid"
            filename="Airwallet_location_export"
            [filterSortParams]="params"
            [itemCount]="totalItems"
        />
        <button
            style="position: relative"
            [disabled]="showLoadingIndicator && (!isSubCustomer || (subCustomerPermissions && subCustomerPermissions.allow_location)) && authService.hasLimitedAccess('create_location', role$ | async)"
            class="btn btn-primary btn-tiny"
            (click)="openModal(createLocationModal)"
        >
            <div>
                <img class="plus-icon-on-btn" src="../../../assets/icons/plus-for-create.svg" alt="create customer" />
                {{ "locations.new_location" | translate }}
            </div>
        </button>
    </ng-container>
    <ng-container body>
        <div *ngIf="showLoadingIndicator" class="loading-height">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
        <app-aw-table
            [tableHeaders]="tableHeaderOptions"
            [filters]="params.filter"
            [pageNumber]="pageNumber"
            [pageSize]="pageSize"
            [totalItems]="totalItems"
            [sidescrollable]="isMobile"
            [hasExpandableOrClickableRows]="true"
            (handlePageChange)="handlePageActive($event)"
            (sortBy)="catchSortChanged($event)"
            [loadingData]="loadingLocations"
            *ngIf="!showLoadingIndicator"
            [minTableWidthPx]="700"
            [initalSort]="{ order: 'asc', key: this.params.sortBy }"
        >
            <ng-container table-body>
                <a [routerLink]="['../locations/' + location.id]" [queryParams]="{ name: location.name }" *ngFor="let location of locations | paginate: pageSize : pageNumber; let i = index" class="table-link row-container">
                    <row style="width: 100%; cursor: pointer">
                        <cell style="width: 40%">
                            <span>{{ location.name }}</span>
                        </cell>
                        <cell style="width: 20%; text-align: right">
                            <span style="width: 100%" *ngIf="authService.hasLimitedAccess('location_stats', role$ | async)">
                                {{ this.helperService.roundToTwoDecimals(location.daily_starts / 1000000) }}
                            </span>
                        </cell>
                        <cell style="width: 20%; text-align: right">
                            <span style="width: 100%" *ngIf="authService.hasLimitedAccess('location_stats', role$ | async)">
                                {{ location.totalRevenue === 0 ? 0 : this.helperService.localizeNumberWithCurrency(this.helperService.roundToTwoDecimals(location.revenue / 100), 2, 2, location.currency) }}
                            </span>
                        </cell>
                        <cell style="width: 20%; text-align: right">
                            <span style="width: 100%">
                                {{ helperService.createdLabelForLists(location.created) }}
                            </span>
                        </cell>
                    </row>
                </a>
            </ng-container>
        </app-aw-table>
    </ng-container>
</app-page-layout>

<!-- LOCATION CREATE MODAL -->
<ng-template #createLocationModal>
    @defer {
        <app-create-location-modal [customerDetails]="customerDetails" [defaultBookingSettings]="defaultBookingSettings" [subCustomer]="subCustomer" (locationCreated)="loadLocations()"></app-create-location-modal>
    }
</ng-template>
