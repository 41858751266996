<div class="page">
    <div class="main-container">
        <img src="../../../assets/logo/aw_logo.svg" alt="Airwallet logo" class="logo" />

        <div class="sign-in-and-signup-container">
            <div class="sign-in-and-signup-card" *ngIf="!initLoading">
                <form [formGroup]="setNewPasswordForm" autocomplete="off" (keydown.enter)="setNewPassword()" [hidden]="setNewPasswordDone" *ngIf="!invalidKey">
                    <div class="input-group-wrapper">
                        <label>{{ "account.choose_new_pass" | translate }}</label>
                        <input autocomplete="new-password" name="password" class="form-control" type="password" formControlName="password" />
                        <div *ngIf="formSubmitted && password.invalid">
                            <div class="error-msg" *ngIf="password.errors.required">
                                {{ "misc.password_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.maxlength">
                                {{ "misc.password_too_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.weakPassword">
                                {{ "misc.password_is_weak" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="input-group-wrapper">
                        <label>{{ "account.repeat_new_pass" | translate }}</label>
                        <input autocomplete="new-repeatPassword" name="repeatPassword" class="form-control" type="password" formControlName="repeatPassword" />
                        <div class="error-msg" *ngIf="repeatPassword.errors && repeatPassword.errors.pwNoMatch">
                            {{ "customers-set-password.password_not_matching" | translate }}
                        </div>
                        <div *ngIf="formSubmitted && repeatPassword.invalid">
                            <div class="error-msg" *ngIf="repeatPassword.errors.required">
                                {{ "customers-set-password.password_need_repeat" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="d-grid gap-2">
                        <button class="btn btn-secondary" (click)="setNewPassword()" [disabled]="loading">
                            {{ !loading ? ("account.update_pass" | translate) : "" }}
                            <loading-indicator *ngIf="loading" [size]="'large'" [color]="'white'"></loading-indicator>
                        </button>
                    </div>
                </form>

                <p *ngIf="setNewPasswordDone" class="text-center mb-4">
                    {{ "account.password_updated" | translate }}
                </p>

                <div class="d-grid gap-2 mb-2" *ngIf="setNewPasswordDone">
                    <button class="btn btn-secondary" (click)="signIn()" [disabled]="loading">
                        {{ !loading ? ("sign-in.sign_in" | translate) : "" }}
                        <loading-indicator *ngIf="loading" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>

                <div *ngIf="invalidKey">
                    <p style="text-align: center">
                        {{ "account.password_link_expired" | translate }}
                    </p>
                </div>

                <p class="support-call">
                    {{ "misc.need_help" | translate }}
                    <br />
                    <span
                        >{{ "misc.US" | translate }} <a href="{{ supportPhone.us.telLink }}" tabindex="-1">{{ supportPhone.us.phoneNumber }}</a></span
                    >
                    <br />
                    <span
                        >{{ "misc.other" | translate }} <a href="{{ supportPhone.main.telLink }}" tabindex="-1">{{ supportPhone.main.phoneNumber }}</a></span
                    >
                </p>
            </div>
            <div *ngIf="initLoading">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
        </div>
    </div>
    <ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
</div>
