<section class="page">
    <div class="main-container">
        <img src="../../../assets/logo/aw_logo.svg" alt="Airwallet logo" class="logo" />
        <div class="sign-in-and-signup-card">
            @if (isUnderMaintenance) {
                <div class="mb-3 mt-3 p-3 text-center">
                    <h2>{{ "sign-in.maintenance" | translate }}</h2>
                    <p>{{ "sign-in.back_at" | translate }}</p>
                    <p>{{ getFormattedBackAt() }}</p>
                </div>
            } @else if (!showLoad) {
                <div class="animation-outer">
                    @if (isMfaSignIn()) {
                        <h2 class="sign-in-title animation-inner" @fade>
                            {{ "account.mfa.two_factor" | translate }}
                        </h2>
                    } @else if (view === "sign-in") {
                        <h2 class="sign-in-title animation-inner" @fade>
                            {{ "sign-in.sign_in_to_acc" | translate }}
                        </h2>
                    } @else {
                        <h2 class="sign-in-title animation-inner" @fade>
                            {{ "sign_up.sign_up_for_aw" | translate }}
                        </h2>
                    }
                </div>

                <div class="outer-container" [@containerHeight]="getContainerState()">
                    @if (view === "sign-in") {
                        <app-sign-in class="inner-view" @fade />
                    } @else {
                        <app-sign-up class="inner-view" @fade />
                    }
                </div>

                <!--Submit-->
                <button class="btn btn-primary submit-button" (click)="handleSubmit()" [disabled]="loading()">
                    @if (!loading()) {
                        {{ (isMfaSignIn() ? "account.mfa.verify" : view === "sign-in" ? "sign-in.sign_in" : "sign_up.sign_up") | translate }}
                    } @else {
                        <loading-indicator *ngIf="loading()" [size]="'large'" [color]="'white'"></loading-indicator>
                    }
                </button>

                <div class="end-container">
                    <div class="animation-outer">
                        @if (isMfaSignIn()) {
                            <button class="sign-up-link animation-inner" [disabled]="loading()" (click)="isMfaSignIn.set(false)" tabindex="-1" @fade>
                                {{ "location.go_back" | translate }}
                            </button>
                        } @else if (view === "sign-in") {
                            <button class="sign-up-link animation-inner" [disabled]="loading()" (click)="toggleAuthPage()" tabindex="-1" @fade>
                                {{ "sign-in.need_account" | translate }}
                            </button>
                        } @else {
                            <button class="sign-up-link animation-inner" [disabled]="loading()" (click)="toggleAuthPage()" tabindex="-1" @fade>
                                {{ "sign-in.already_have_acc" | translate }}
                            </button>
                        }
                    </div>

                    <p class="support-call">
                        {{ "misc.need_help" | translate }}
                        <br />
                        <span
                            >{{ "misc.US" | translate }} <a href="{{ supportPhone.us.telLink }}" tabindex="-1">{{ supportPhone.us.phoneNumber }}</a></span
                        >
                        <br />
                        <span
                            >{{ "misc.other" | translate }} <a href="{{ supportPhone.main.telLink }}" tabindex="-1">{{ supportPhone.main.phoneNumber }}</a></span
                        >
                    </p>
                </div>
            }

            <div *ngIf="showLoad">
                <loading-indicator [size]="'large'" [color]="'primary'"></loading-indicator>
            </div>
        </div>
    </div>
    <p class="version-text">{{ "sign-in.version" | translate }}{{ version }}</p>
</section>
<ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
