<div class="page">
    <div class="page-title-wrapper">
        <h4 class="mb-3 page-title">{{ "customers-create.create" | translate }}</h4>
    </div>

    <div class="main-container">
        <div class="sign-in-and-signup-container">
            <div class="sign-in-and-signup-card">
                <app-aw-form-creator [schema]="schema" (onSuccess)="createCustomer()" [isFetching]="showLoading" [buttonLabel]="'customers-create.create'" [form]="subCustomerForm">
                    <ng-container #terms_and_conditions>
                        <div class="input-group-wrapper-feedback mt-2 mb-0">
                            <div class="checkbox mb-0">
                                <app-aw-checkbox id="terms_check" (checkStateEmitter)="toggleConsent($event, 'terms')" />
                                <label for="terms_check">
                                    {{ "customers-create.accept" | translate }}
                                    <a class="primary-color" href="{{ pickedRegion === 'us' ? usTermsOfUse : euTermsOfUse }}" target="_blank" tabindex="-1">
                                        {{ "customers-create.terms_n_conds" | translate }}
                                    </a>
                                </label>
                            </div>
                            <div class="feedback-container">
                                <div class="error-msg" *ngIf="termsAndConditions.errors">
                                    {{ termsAndConditions.errors["zod_error"] | translate }}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container #gdpr_policy>
                        <div class="input-group-wrapper-feedback terms">
                            <div class="checkbox">
                                <app-aw-checkbox id="gdpr_check" (checkStateEmitter)="toggleConsent($event, 'gdpr')" />
                                <label for="gdpr_check">
                                    {{ "customers-create.accept" | translate }}
                                    <a class="primary-color" href="{{ gdprPolicy }}" target="_blank" tabindex="-1">
                                        {{ "misc.gdpr" | translate }}
                                    </a>
                                </label>
                            </div>
                            <div class="feedback-container">
                                <div class="error-msg" *ngIf="gdpr.errors">
                                    {{ gdpr.errors["zod_error"] | translate }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </app-aw-form-creator>

                <br />
                <p class="support-call">
                    {{ "misc.need_help" | translate }}
                    <br />
                    <span
                        >{{ "misc.US" | translate }} <a href="{{ supportPhone.us.telLink }}" tabindex="-1">{{ supportPhone.us.phoneNumber }}</a></span
                    >
                    <br />
                    <span
                        >{{ "misc.other" | translate }} <a href="{{ supportPhone.main.telLink }}" tabindex="-1">{{ supportPhone.main.phoneNumber }}</a></span
                    >
                </p>
            </div>
        </div>
    </div>
</div>
