import { Hub, HubCreateParams } from '@airwallet/shared-models/hub/hub';
import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '@services/helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class HubService {
    constructor(private httpService: HttpService) {}

    async createHub(uid: string, createParams: HubCreateParams): Promise<Hub> {
        return this.httpService.dynamicHttp('hub', RequestTypes.POST, { body: { createParams }, onBehalf: uid });
    }

    async deleteHub(uid: string, serial: string, locationId: string): Promise<void> {
        return this.httpService.dynamicHttp('hub', RequestTypes.DELETE, { body: { serial_number: serial, location_id: locationId }, onBehalf: uid });
    }

    async restartHub(hubIccid: string): Promise<void> {
        return this.httpService.dynamicHttp('hub/remote_restart', RequestTypes.POST, { body: { iccid: hubIccid } });
    }
}
