import { Injectable } from '@angular/core';
import { DetailedUser, ShallowAppUser, UserCreate, UsersQueryResult } from '@airwallet/shared-models/user-management';
import { HttpService, RequestTypes } from '../helper/http.service';
import { Coupon } from '@airwallet/shared-models/coupon';
import { FilterSortParams } from '@airwallet/shared-models/search-params/FilterSortParams';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(private httpService: HttpService) {}

    async getCurrentUser(userId: string, owner_uid?: string): Promise<DetailedUser> {
        return this.httpService.dynamicHttp(`api_users/${userId}`, RequestTypes.GET, { onBehalf: owner_uid });
    }

    async getCoupons(userId: string, owner_uid: string): Promise<{ applied_coupons: DetailedUser['applied_coupons']; available_coupons: DetailedUser['available_coupons'] }> {
        return this.httpService.dynamicHttp(`api_users/${userId}/coupons`, RequestTypes.GET, { onBehalf: owner_uid });
    }

    async getUsers(data: FilterSortParams): Promise<UsersQueryResult> {
        return this.httpService.dynamicHttp(`api_users?params=${JSON.stringify(data)}`, RequestTypes.GET);
    }

    async createUser(user: UserCreate, onBehalf?: string) {
        return this.httpService.dynamicHttp('api_users/', RequestTypes.POST, { body: user, onBehalf });
    }

    async getSubcustomerNames(): Promise<{ value: string; label: string }[]> {
        return this.httpService.dynamicHttp(`api_operator/names_for_filter`, RequestTypes.GET);
    }

    async checkUserExists(phoneNo: string, fingerprint: string) {
        return this.httpService.dynamicHttp<{ user_exists: boolean; user_name: string; as_yours: boolean; fingerprint: string }>('api_users/check_user_exist/', RequestTypes.GET, { params: { phoneNo, fingerprint } });
    }

    updateUserPaymentInfo(value: any) {
        return this.httpService.dynamicHttp('api_users/update_user_payment_info', RequestTypes.POST, { body: value });
    }

    handleUserBlock(userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/handle_user_block', RequestTypes.POST, { body: { userId }, onBehalf });
    }

    handleTestUser(userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/handle_test_user', RequestTypes.POST, { body: { userId }, onBehalf });
    }

    deleteComment(commentId: string, userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/log_activity', RequestTypes.DELETE, { body: { commentId, userId }, onBehalf });
    }

    excludeCoupons(coupons: Coupon[], userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/exclude_coupons', RequestTypes.DELETE, { body: { coupons, userId, onBehalf } });
    }

    applyCoupons(coupons: Coupon[], userId: string, onBehalf: string) {
        return this.httpService.dynamicHttp('api_users/apply_coupons', RequestTypes.POST, { body: { coupons, userId, onBehalf } });
    }
    async getCustomersAndLocationNames(): Promise<{ customers: { value: string; label: string }[]; subcustomersLocationNames: Record<string, { value: string; label: string }[]> }> {
        return this.httpService.dynamicHttp(`api_operator/all_names_for_filter`, RequestTypes.GET);
    }
}
