import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Action, ActionLog, actionState, actionType } from '@airwallet/shared-models/operator/actions';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { Observable, Subject } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ActionsService } from '../../../../services/actions/actions.service';
import { ActionCardService } from '../../../../services/actions/actionCard.service';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { DateTimeService } from '../../../../services/date-time/date-time.service';
import { AuthService } from '../../../../services/auth/auth.service';
import * as Claims from '@airwallet/shared-models/claims';
import { MachineServicesResetModalComponent } from '../../../device/machine-services-reset-modal/machine-services-reset-modal.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-actions-mobile-modal',
    templateUrl: './actions-mobile-modal.component.html',
    styleUrls: ['./actions-mobile-modal.component.scss'],
    standalone: true,
    imports: [NgIf, CustomModalComponent, NgFor, LoadingComponent, MachineServicesResetModalComponent, AsyncPipe, TranslateModule]
})
export class ActionsMobileModalComponent implements OnInit, OnDestroy {
    @Input() action: Action;
    @Input() isSkeleton: boolean;
    @Output() emitRemoveMobileAction: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitMoveMobileActionToOpen: EventEmitter<any> = new EventEmitter<any>();
    @Output() multipleEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Output() singleEmitter: EventEmitter<any> = new EventEmitter<any>();

    user: DashboardUser;
    toggleHistory = false;

    checkLoading = false;
    role$: Observable<Claims.Roles> = this.authService.getRole;

    latestLogValue;
    modalLogKey: string;
    timestamp;
    reversedLogKeys: string[];
    reversedActiveLogKeys: string[];

    showAdviceAction = true;
    showAlertAction = true;

    initLoading = true;

    private ngUnsubscribe = new Subject<void>();

    constructor(
        public authService: AuthService, // used in html
        private translate: TranslateService,
        private actionService: ActionsService,
        private actionCardService: ActionCardService,
        public modalService: NgbModal,
        private dateTimeService: DateTimeService,
        private localStorageService: LocalStorageService
    ) {
        actionService.selectedAdviceToggled$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAdviceAction = value;
        });
        actionService.selectedAlertToggled$$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(value => {
            this.showAlertAction = value;
        });
    }

    ngOnInit(): void {
        this.initLoading = true;
        moment.locale(this.translate.currentLang);
        this.user = this.localStorageService.getItem('user');

        this.reversedLogKeys = this.getReversedLogKeys();
        if (this.action.active_actions) {
            this.reversedActiveLogKeys = this.getReversedActiveLogKeys();
        }

        this.latestLogValue = this.getLastestLogEntry();
        this.timestamp = this.timeSince(this.latestLogValue.timestamp);
        this.initLoading = false;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    timeSince(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, false);
    }

    getLastestLogEntry(): ActionLog {
        const firstElementKey = Object.keys(this.action.log).reverse()[0];
        return this.action.log[firstElementKey];
    }

    getReversedLogKeys(): string[] {
        return Object.keys(this.action.log).reverse();
    }

    getReversedActiveLogKeys(): string[] {
        return Object.keys(this.action.active_actions).reverse();
    }

    getState(actionLog: ActionLog): string {
        return this.translate.instant(`operator.actions.states.${actionLog.state}`);
    }

    getTitle(actionLog: ActionLog, isForLog: boolean): string {
        return this.actionCardService.getTitle(actionLog, isForLog, this.isSkeleton, this.action);
    }

    getDescription(actionLog: ActionLog, action: Action): string {
        return this.actionCardService.getDescription(actionLog, action);
    }

    toggleHistoryExpand() {
        this.toggleHistory = !this.toggleHistory;
    }

    checkIfActionIsSoapOrMaintenance(logKey) {
        return (
            (this.action.log[logKey].action_type === actionType.machineMaintenanceDue && this.action.log[logKey].state !== actionState.complete) ||
            (this.action.log[logKey].action_type === actionType.outOfSoap && this.action.log[logKey].state !== actionState.complete)
        );
    }

    async removeAction(logKey: string) {
        this.checkLoading = true;
        this.toggleHistory = false;
        const actionIndex = this.reversedActiveLogKeys.indexOf(logKey);
        this.reversedActiveLogKeys.splice(actionIndex, 1);
        this.emitRemoveMobileAction.emit({ logKey: logKey });
        this.checkLoading = false;
        if (this.reversedActiveLogKeys.length === 0) {
            this.modalService.dismissAll();
        }
    }

    async moveActionBackToOpen(actionLog: ActionLog) {
        this.checkLoading = true;
        this.toggleHistory = false;
        this.emitMoveMobileActionToOpen.emit({ actionLog: actionLog });
        this.checkLoading = false;
        this.modalService.dismissAll();
    }

    async emitSingleReset() {
        this.singleEmitter.emit({ logKey: this.modalLogKey });
    }

    async emitMultipleReset() {
        this.multipleEmitter.emit({ logKey: this.modalLogKey });
    }

    openModal(modal: any, logKey?: string) {
        //Responsible for passing the contract to contract-form-modal
        if (logKey) {
            this.modalLogKey = logKey;
        }
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        };
        const modalRef: NgbModalRef = this.modalService.open(modal, modalOptions);

        modalRef.result.then(
            () => {
                // on close
            },
            () => {
                // on error/dismiss
                // to remove the selected order and also reset the forced styling
            }
        );
    }

    protected readonly actionType = actionType;
}
