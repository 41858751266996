import z from 'zod';
import { UnitType } from '../../../../shared_models/device';
export const hubSchemaForm = z.object({
    serial_number: z.string().max(10).min(10)
});

export type ValidateHubZod = z.infer<typeof hubSchemaForm>;

export const hubSchema = z.object({
    serial_number: z.string().max(10).min(10),
    customer_uid: z.string(),
    unit_type: z.literal(UnitType.HUB)
});

export type HubZod = z.infer<typeof hubSchema>;

export const hubSchemaFull = z.object({
    id: z.string(),
    created: z.number(),
    serial_number: z.string().max(10).min(10),
    customer_uid: z.string(),
    unit_type: z.literal(UnitType.HUB),
    subscribed: z.optional(z.boolean()),
    insured: z.optional(z.boolean())
});
