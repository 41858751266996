<app-custom-modal>
    <ng-container modal-title>
        @if (!hub()) {
            <h4>{{ "location.create_hub" | translate }}</h4>
        } @else {
            <h4>{{ "location.hub" | translate }}</h4>
        }
    </ng-container>
    <ng-container modal-body>
        @if (!hub()) {
            <div class="illustration-wrapper" style="margin-bottom: 2rem">
                <img src="../../../../assets/illustrations/hub_serial number.svg" class="illustration-svg" alt="{{ 'location.illustration_get_serial' | translate }}" />
            </div>
        }

        @if (hub()) {
            <app-aw-form-creator [schema]="hubSchema" [form]="hubForm">
                <aw-form-creator-footer>
                    <div class="hub-buttons">
                        <app-animated-button>
                            <div class="animated-button-btns-wrapper">
                                <button class="btn btn-secondary btn-tiny hasLoadingCapability" [disabled]="isRestarting" (click)="restartHub()">
                                    {{ isRestarting ? "" : ("location.restart_hub" | translate) }}
                                    @if (isRestarting) {
                                        <loading-indicator [size]="'small'" [color]="'white'"></loading-indicator>
                                    }
                                </button>
                                @if (authService.hasLimitedAccess("edit_hub", role$ | async)) {
                                    <button class="btn btn-danger btn-tiny hasLoadingCapability" [disabled]="isDeleting" (click)="deleteHub()">
                                        {{ isDeleting ? "" : ("location.delete_hub" | translate) }}
                                        @if (isDeleting) {
                                            <loading-indicator [size]="'small'" [color]="'white'"></loading-indicator>
                                        }
                                    </button>
                                }
                            </div>
                        </app-animated-button>
                        <button class="btn btn-primary btn-tiny save-changes-btn" [disabled]="isSavingChanges" (click)="saveChanges()">
                            @if (isSavingChanges) {
                                <loading-indicator [size]="'small'" [color]="'white'"></loading-indicator>
                            } @else {
                                {{ "location.save_changes" | translate }}
                            }
                        </button>
                    </div>
                </aw-form-creator-footer>
            </app-aw-form-creator>
        } @else {
            <app-aw-form-creator [schema]="hubSchema" [buttonLabel]="'location.create_hub'" [form]="hubForm" [isFetching]="isCreating" (onSuccess)="create()"> </app-aw-form-creator>
        }
    </ng-container>
    <ng-container modal-footer> </ng-container>
</app-custom-modal>
