<app-page-layout [pageTitle]="'discount_tool.discount_tool' | translate">
    <ng-container buttons>
        <app-aw-filter-button [disabled]="!selectedUid" [filterOptions]="filterOptions" [popupAnchor]="isMobile ? 'left' : 'right'" (filterChanged)="catchFilterChanged($event)" />
        <app-discount-tool-manage
            [style]="isMobile ? '' : 'margin-left: 15px'"
            [globalDiscount]="globalDiscount * 100"
            [loadingDevices]="loadingDevices"
            [popupAnchor]="isMobile ? 'right' : 'left'"
            (globalDiscountEmitter)="ngOnInit()"
            (uidEmitter)="selectUid($event)"
        />
        <button [disabled]="tableSelection.selectedUnits.length < 1 && !tableSelection.selectAll" class="btn btn-primary btn-tiny" [style]="isMobile ? '' : 'margin-left: 15px'" (click)="openModal(discountCreateModal)">
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
            {{ "discount_tool.apply_discount" | translate }}
        </button>
        <button [disabled]="tableSelection.selectedUnits.length < 1 && !tableSelection.selectAll" class="btn btn-secondary btn-tiny" [style]="isMobile ? '' : 'margin-left: 15px'" (click)="deleteDiscount()">
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
            {{ "discount_tool.remove_discount" | translate }}
        </button>
    </ng-container>
    <ng-container body>
        @if (initialLoading) {
            <loading-indicator [size]="'large'" [color]="'secondary'" />
        } @else {
            <div>
                <app-aw-table
                    [tableHeaders]="tableHeaderOptions"
                    [filters]="params.filter"
                    [pageNumber]="params.pageNumber"
                    [pageSize]="params.pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [totalItems]="params.total"
                    (handlePageChange)="handlePage($event)"
                    (sortBy)="catchSortChanged($event)"
                    [loadingData]="loadingDevices"
                    [showCheckBoxPosition]="isMobile ? 'start' : 'end'"
                    [sidescrollable]="isMobile"
                    [initalSort]="params.sortBy"
                >
                    <ng-container header-checkbox>
                        <div [ngStyle]="{ display: 'flex', 'justify-content': isMobile ? 'start' : 'end', 'align-items': 'center', height: '8px', position: 'relative' }">
                            <app-aw-checkbox
                                style="position: absolute"
                                [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                [partial]="tableSelection.selectedUnits.length > 0 || tableSelection.unselectedUnits.length > 0"
                                [checkbox]="tableSelection.selectAll || tableSelection.selectedUnits.length > 0"
                                (checkStateEmitter)="selectAllUnits($event)"
                            />
                        </div>
                    </ng-container>

                    <ng-container table-body>
                        <row *ngFor="let unit of units">
                            <cell style="width: 5%" *ngIf="isMobile">
                                <div style="display: flex; justify-content: start; width: 100%">
                                    <app-aw-checkbox
                                        [checkbox]="isDeviceSelected(device)"
                                        [initialCheckedValue]="device.is_checked"
                                        [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                        [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                        (checkStateEmitter)="toggleDeviceSelection(device, $event)"
                                    />
                                </div>
                            </cell>
                            <cell style="width: 15%">
                                <span>{{ unit.serial }}</span>
                            </cell>
                            <cell style="width: 10%">
                                <span>{{ "billing." + (unit.subscriptionDocId !== null ? "active" : "inactive") | translate }}</span>
                            </cell>
                            <cell style="width: 15%">
                                <span>{{ getCustomerName(unit) }}</span>
                            </cell>
                            <cell style="width: 15%">
                                @if (unit.in_use) {
                                    <span>{{ getLocationName(unit) }}</span>
                                } @else {
                                    <div style="display: flex; align-items: center">
                                        <app-custom-tool-tip
                                            [spaceBetween]="true"
                                            [label]="'billing.not_in_use' | translate"
                                            [gap]="0.25"
                                            [fullWidth]="false"
                                            [labelClass]="'regular-label'"
                                            [description]="'billing.device_on_active_subscription' | translate"
                                        />
                                    </div>
                                }
                            </cell>
                            <cell style="width: 15%">
                                @if (unit.in_use) {
                                    <span>{{ unit.unit_type === "terminal" ? "Anton terminal " : unit.name }}</span>
                                } @else {
                                    <div style="display: flex; align-items: center">
                                        <app-custom-tool-tip
                                            [spaceBetween]="true"
                                            [label]="'billing.not_in_use' | translate"
                                            [gap]="0.25"
                                            [fullWidth]="false"
                                            [labelClass]="'regular-label'"
                                            [description]="'billing.device_on_active_subscription' | translate"
                                        />
                                    </div>
                                }
                            </cell>
                            <cell style="width: 15%">
                                @if (unit.icon) {
                                    <app-aw-hover-icon
                                        [hoverText]="unit.hoverText"
                                        [label]="unit.label"
                                        [hoverIcon]="'../../../assets/icons/clock-white.svg'"
                                        [icon]="'../../../assets/icons/' + unit.icon"
                                        [startColor]="unit.startColor"
                                        [endColor]="unit.endColor"
                                        [iconWidth]="13"
                                    />
                                } @else {
                                    <span>-</span>
                                }
                            </cell>
                            <cell style="width: 10%; text-align: right">
                                <div style="width: 100%">
                                    <span>{{ helperService.createdLabelForLists(unit.created) }}</span>
                                </div>
                            </cell>
                            <cell style="width: 5%" *ngIf="!isMobile">
                                <div style="display: flex; justify-content: end; width: 100%">
                                    <app-aw-checkbox
                                        [initialCheckedValue]="unit.isChecked"
                                        [checkbox]="unit.isChecked"
                                        [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                        [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                        (checkStateEmitter)="toggleUnitSelection(unit, $event)"
                                    />
                                </div>
                            </cell>
                        </row>
                    </ng-container>
                </app-aw-table>
            </div>
        }
    </ng-container>
</app-page-layout>

<ng-template #discountCreateModal>
    <app-discount-create-modal [uid]="selectedUid" [tableSelection]="tableSelection" [params]="params" [totalUnits]="params.total" (discountCreated)="ngOnInit()"></app-discount-create-modal>
</ng-template>
