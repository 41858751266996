import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractService } from '../../../../services/contract/contract.service';
import { Datepicker } from 'vanillajs-datepicker';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { DatePickerLanguages } from '../../../../constants/datepickerLanguages';
import { Contract, DetailedContract } from '@airwallet/shared-models/operator/contracts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CurrencyPipe, NgIf, NgFor } from '@angular/common';
import { HelperService } from '../../../../services/helper/helper.service';
import { OperatorService } from '../../../../services/operator/operator.service';
import { DashboardUser } from '../../../../../dashboard-models/dashboard-user';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ControlledUser } from '@airwallet/shared-models/controlled-user';
import { CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf } from '@angular/cdk/scrolling';
import { LoadingComponent } from '../../../loading/loading.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-contract-form',
    templateUrl: './contract-form.component.html',
    styleUrls: ['./contract-form.component.scss'],
    providers: [CurrencyPipe],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgIf, MatRadioGroup, MatRadioButton, MatCheckbox, NgFor, LoadingComponent, CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TranslateModule]
})
export class ContractFormComponent implements OnInit, OnDestroy {
    @Input() editableContract: DetailedContract;
    @Input() isEdit: boolean;
    @Output() parrentDeleteContract = new EventEmitter<string>();
    @Output() parrentUpdateContract = new EventEmitter<DetailedContract>();
    user: DashboardUser;
    customer: string;
    loading: boolean;
    datepickerStart: Datepicker;
    datepickerEnd: Datepicker;
    currency: string;
    showDeleteContract = false;
    deletingContractLoading = false;
    fromDate: number;
    toDate: number;
    formSubmitted = false;
    sendingData = false;
    customers: any[] = [];
    possibleLocations: any[] = [];
    subcustomerData;
    selectedCustomer: string;
    contractForm: UntypedFormGroup;
    formBuilder: UntypedFormBuilder;
    buttonText: string;
    showInfoHoverContract: boolean;
    showInfoHoverBillable: boolean;
    isMobile: boolean;

    constructor(
        formBuilder: UntypedFormBuilder,
        private contractService: ContractService,
        private translate: TranslateService,
        public modalService: NgbModal,
        private toast: ToastrService,
        private helperService: HelperService,
        private operatorService: OperatorService,
        private activatedRoute: ActivatedRoute,
        private breakpointObserver: BreakpointObserver
    ) {
        this.contractForm = new FormGroup({
            contract_id: new UntypedFormControl(null, [Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z0-9]+$')]),
            locations: new UntypedFormControl([], [Validators.required]),
            revenue_target: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
            timespan: new UntypedFormControl(null, Validators.required),
            billable: new UntypedFormControl(null),
            start_date: new UntypedFormControl(null),
            end_date: new UntypedFormControl(null)
        });
        operatorService.selectedFrom$.subscribe(value => {
            this.fromDate = value;
        });
        operatorService.selectedTo$.subscribe(value => {
            this.toDate = value;
        });
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnDestroy() {}

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.showDeleteContract = false;
        this.loading = true;
        this.currency = this.user.settings.currency;

        // Initialise datepicker
        this.initDatePicker();
        Object.assign(Datepicker.locales, DatePickerLanguages());
        Datepicker.locales['da'] ? (Datepicker.locales['da']['today'] = 'I dag') : null; // fix for incorrect danish translation in the library

        if (this.isEdit) {
            this.buttonText = this.translate.instant('device.save_changes');
            await this.editContractInit();
        }

        // Get and populate customer and location lists
        if (!this.isEdit) {
            this.timespan.setValue('Yearly');
            this.buttonText = this.translate.instant('operator.contract_modal.create_contract');
            this.billable.setValue(false);
            await this.getAllSubcustomerData();
        }

        this.onFormChange();
    }

    onFormChange() {
        this.contractForm.get('start_date').valueChanges.subscribe(val => {
            this.contractForm.controls['end_date'].setValidators([Validators.min(val)]);
            this.contractForm.controls['end_date'].updateValueAndValidity();
        });
    }

    async getAllSubcustomerData() {
        this.subcustomerData = await this.contractService.getAllContractInformation().then(response => {
            this.loading = false;
            return response;
        });
        Object.keys(this.subcustomerData).forEach(val => {
            this.customers.push({ id: this.subcustomerData[val].id, name: this.subcustomerData[val].name });
        });

        // workaround to wait for html to be ready
        return new Promise(x => {
            setTimeout(x);
        }).then(() => {
            this.onChangeCustomer(this.user.uid);
        });
    }

    async editContractInit() {
        this.customers = [{ id: this.editableContract.customer_id, name: this.editableContract.customer_name }];
        this.selectedCustomer = this.customers[0].id;

        this.contract_id.setValue(this.editableContract.contract_id);
        this.revenue_target.setValue(this.helperService.localizeNumber(this.editableContract.org_revenue_target / 100));
        this.timespan.setValue(this.editableContract.timespan);
        this.billable.setValue(this.editableContract.billable);
        this.start_date.setValue(this.editableContract.start_date);
        this.datepickerStart.setDate(this.editableContract.start_date * 1000);
        this.end_date.setValue(this.editableContract.end_date);
        this.datepickerEnd.setDate(this.editableContract.end_date * 1000);

        this.subcustomerData = await this.contractService.getSpecificContractInformation(this.editableContract.customer_id);
        this.setPossibleLocations(this.editableContract.customer_id);
        const tempArray = [];
        for (const loc in this.editableContract.locations) {
            tempArray.push(loc);
        }
        this.locations.setValue(tempArray);
        this.loading = false;
    }

    datePickerChanged(pickerChanged: 'start' | 'end') {
        pickerChanged === 'start'
            ? this.start_date.setValue(
                  parseInt(
                      moment(this.datepickerStart.dates[0] + moment(this.datepickerStart.dates[0]).utcOffset() * 60 * 1000, 'x')
                          .utc()
                          .startOf('day')
                          .format('X')
                  )
              )
            : this.end_date.setValue(
                  parseInt(
                      moment(this.datepickerEnd.dates[0] + moment(this.datepickerEnd.dates[0]).utcOffset() * 60 * 1000, 'x')
                          .utc()
                          .startOf('day')
                          .format('X')
                  )
              );
        this.datepickerEnd.setOptions({ minDate: this.datepickerStart.dates[0] });
    }

    initDatePicker() {
        this.datepickerStart = new Datepicker(document.getElementById('contract-start'), {
            language: this.translate.currentLang,
            todayButton: true,
            clearButton: true,
            autohide: true,
            format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
        });
        this.datepickerEnd = new Datepicker(document.getElementById('contract-end'), {
            language: this.translate.currentLang,
            todayButton: true,
            clearButton: true,
            autohide: true,
            format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
        });
    }

    onChangeCustomer(uid: string) {
        this.selectedCustomer = uid;
        this.possibleLocations = [];
        if (this.selectedCustomer !== '') {
            this.setPossibleLocations(uid);
        }
        this.sortLocations();
        this.changeLocationsStyling();
    }

    changeLocationsStyling() {
        const length = this.possibleLocations.length;
        const ele = document.getElementsByClassName('cdk-virtual-scroll-content-wrapper')[0] as HTMLElement;
        if (length > 4) {
            ele.style.paddingRight = '0.75rem';
        } else {
            ele.style.paddingRight = '0';
        }
    }

    setPossibleLocations(uid: string) {
        const list = this.subcustomerData[uid];
        this.possibleLocations = [];
        this.locations.setValue([]);
        for (const loc in list.locations) {
            if (this.isEdit && (!list.locations[loc].isOnContract || Object.keys(this.editableContract.locations).includes(loc))) {
                this.possibleLocations.push({ id: loc, name: list.locations[loc].name });
            } else if (!list.locations[loc].isOnContract) {
                this.possibleLocations.push({ id: loc, name: list.locations[loc].name });
            }
        }
    }

    sortLocations(): void {
        function propCompare() {
            return function compare(a, b) {
                return a['name'].localeCompare(b['name'], 'en', { numeric: true, sensitivity: 'base' });
            };
        }
        this.possibleLocations = this.possibleLocations.slice().sort(propCompare());
    }

    onLocationChange(e) {
        if (e.target.checked) {
            const tempArray = this.locations.value;
            tempArray.push(e.target.value);
            this.locations.setValue(tempArray);
        } else {
            let i = 0;
            const tempArray = this.locations.value;
            this.locations.value.forEach(item => {
                if (item == e.target.value) {
                    tempArray.splice(i, 1);
                    return;
                }
                i++;
            });
            this.locations.setValue(tempArray);
        }
    }

    checkIfIsOnContract(location_id: string): boolean {
        return Object.keys(this.editableContract.locations).includes(location_id);
    }

    deleteContract() {
        this.deletingContractLoading = true;
        this.contractService
            .deleteContract(this.editableContract.customer_id, this.editableContract.contract_key)
            .then(() => {
                this.toast.success(this.translate.instant('operator.contract_modal.success_delete'), this.translate.instant('misc.success'));
                this.modalService.dismissAll();
                this.parrentDeleteContract.next(this.editableContract.contract_key);
            })
            .catch(() => {
                this.toast.error(this.translate.instant('operator.contract_modal.error_delete'), this.translate.instant('misc.error'));
                this.modalService.dismissAll();
            });
    }

    async onSubmit() {
        this.formSubmitted = true;
        if (this.contractForm.valid) {
            this.sendingData = true;

            const map: Record<string, boolean> = {};
            for (const ele of this.contractForm.value.locations) {
                map[ele] = true;
            }

            const formData: Contract = {
                ...this.contractForm.value,
                locations: map,
                timespan: this.timespan.value.toLowerCase(),
                billable: this.billable.value,
                revenue_target: parseInt(this.revenue_target.value.replaceAll(',', '').replaceAll('.', '')),
                customer_id: this.isEdit ? this.editableContract.customer_id : this.selectedCustomer,
                customer_name: this.isEdit ? this.editableContract.customer_name : this.subcustomerData[this.selectedCustomer].name,
                contract_key: this.isEdit ? this.editableContract.contract_key : null,
                currency: this.currency
            };

            await this.contractService.createOrEditContract(formData, this.selectedCustomer, this.isEdit, this.fromDate, this.toDate).then(response => {
                this.toast.success(this.isEdit ? this.translate.instant('operator.contract_modal.contract_edit_success') : this.translate.instant('operator.contract_modal.contract_create_success'), this.translate.instant('misc.success'));
                const updatedContract: DetailedContract = response as DetailedContract;
                if (this.isEdit) {
                    this.parrentUpdateContract.next(updatedContract);
                } else {
                    this.operatorService.setNewContract(updatedContract);
                    this.modalService.dismissAll();
                }
            });

            this.sendingData = false;
        }
    }

    checkTranslate(str: string): string {
        if (str === 'my_account') return this.translate.instant('operator.contract_modal.my_account');

        return str;
    }

    storeValue(event: any) {
        let minimumRevenue = event.target.value.replaceAll(',', '.');
        let decimalFactor = 1;
        if (minimumRevenue.includes('.')) {
            const lengthAfterPoint = minimumRevenue.substring(minimumRevenue.lastIndexOf('.') + 1).length;
            decimalFactor = lengthAfterPoint === 0 ? 1 : lengthAfterPoint === 1 ? 10 : lengthAfterPoint === 2 ? 100 : 1;
        }
        minimumRevenue = String(minimumRevenue).replaceAll('.', '');
        minimumRevenue = minimumRevenue / decimalFactor;
        const valueToSet: string = this.helperService.localizeNumber(minimumRevenue, 2, 2);
        if (valueToSet === 'NaN') {
            this.contractForm.get('revenue_target').setErrors({ invalidNumber: true });
        } else {
            this.contractForm.patchValue({ revenue_target: this.helperService.localizeNumber(minimumRevenue, 2, 2) });
        }
    }

    get contract_id() {
        return this.contractForm.get('contract_id');
    }
    get billable() {
        return this.contractForm.get('billable');
    }
    get revenue_target() {
        return this.contractForm.get('revenue_target');
    }
    get start_date() {
        return this.contractForm.get('start_date');
    }
    get end_date() {
        return this.contractForm.get('end_date');
    }
    get timespan() {
        return this.contractForm.get('timespan');
    }
    get locations() {
        return this.contractForm.get('locations');
    }
}
