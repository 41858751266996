import { Component, input, InputSignal, OnInit, output, OutputEmitterRef } from '@angular/core';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as Claims from '@airwallet/shared-models/claims';
import { HelperService } from '@services/helper/helper.service';
import { AuthService } from '@services/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { CardService } from '@services/location/card/card.service';
import { LocationComponent } from '../location.component';
import { HubComponent, UpdateParams } from '../hub/hub.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { Hub } from '@airwallet/shared-models/hub/hub';
@Component({
    selector: 'app-hub-list',
    standalone: true,
    imports: [TranslateModule, HubComponent, LoadingComponent],
    templateUrl: './hub-list.component.html',
    styleUrl: './hub-list.component.scss'
})
export class HubListComponent implements OnInit {
    locationId: InputSignal<string> = input.required();
    modalRef: InputSignal<NgbModalRef> = input.required();
    inputHubList: InputSignal<BehaviorSubject<Hub[]>> = input.required();
    hubList: Hub[];
    deleteHub: OutputEmitterRef<Hub> = output();
    initLoading = true;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    user: DashboardUser;
    isCustomerOperated: boolean;
    uid: string;

    selectedHub: Hub;

    constructor(
        private helperService: HelperService,
        public authService: AuthService,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private cardService: CardService,
        private locationComp: LocationComponent
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.inputHubList().subscribe(value => {
            if (!value) return;
            this.updateList(value);
            console.log(value, this.hubList);
            this.initLoading = false;
        });
    }

    updateList(list: Hub[]) {
        this.hubList = list;
    }

    cardClickAction(unit: Hub, modal: any, modalTemplateString: string) {
        this.cardService.setHub(unit);
        this.selectedHub = unit;
        this.locationComp.selectedHub(modal, modalTemplateString);
    }

    updateHubs(details: UpdateParams) {
        if (details.delete) {
            this.deleteHub.emit(this.selectedHub);
        }

        this.locationComp.modalRef.close();
    }
}
