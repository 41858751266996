import { Component, Input } from '@angular/core';
import { LoadingComponent } from '../../loading/loading.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExportExcelService } from '../../../services/excel/export-excel.service';
import { HelperService } from '../../../services/helper/helper.service';
import { ExportParams, FilterSortParams } from '@airwallet/shared-models/search-params/FilterSortParams';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { Language } from '@constants/supportedLanguages';

@Component({
    selector: 'app-aw-export-button',
    standalone: true,
    imports: [LoadingComponent, TranslateModule],
    templateUrl: './aw-export-button.component.html',
    styleUrl: './aw-export-button.component.scss'
})
export class AwExportButtonComponent {
    exporting = false;
    fingerprint: string;
    @Input() filename = 'Airwallet_file';
    @Input() filterSortParams: FilterSortParams;
    @Input() exportPath: string;
    @Input() exportMailPath: string;
    @Input() disabled = false;
    @Input() limitBeforeMail: number;
    @Input() itemCount: number;
    @Input() uidOnBehalf: string;

    exportParams: ExportParams;
    activeToast_export: ActiveToast<unknown>;

    constructor(
        private exportExcelService: ExportExcelService,
        private helperService: HelperService,
        private translate: TranslateService,
        private toast: ToastrService,
        private localStorageService: LocalStorageService
    ) {}

    async startExport() {
        this.exporting = true;
        const mail: string = this.localStorageService.getItem('loggedInUser').email;
        const lang: Language = this.localStorageService.getItem('language');
        const language_code: string = lang ? lang.language_code : navigator.language;
        this.exportParams = { ...this.filterSortParams, language_code, mail };
        if (this.itemCount > this.limitBeforeMail) {
            this.sendViaMailToast();
            this.exporting = false;
        } else {
            await this.export();
        }
    }

    async export(toMail?: boolean) {
        if (toMail) this.exporting = false;

        if (toMail)
            return this.exportExcelService.exportOnMail(this.exportMailPath, JSON.stringify(this.exportParams), this.exportParams?.filter?.customer_key ?? this.uidOnBehalf).then(() => {
                this.exporting = false;
            });

        this.exportParams.mail = null;
        this.exportExcelService.exportToExcel(this.exportPath, JSON.stringify(this.exportParams), this.filename, this.exportParams?.filter?.customer_key ?? this.uidOnBehalf).then((res: any) => {
            this.exporting = false;
            if (!res) return;
        });
    }

    async sendViaMailToast() {
        const clickHandler = async (event: any) => {
            // If the clicked element doesn't have the right selector, bail
            if (!event.target.matches('.sendViaMailTarget')) {
                return;
            }
            event.preventDefault();

            this.toast.remove(this.activeToast_export.toastId);
            new Promise(resolve => setTimeout(resolve, 500)).then(() => {
                this.helperService.defaultHtmlToast('', `${this.translate.instant('transactions.export_sent_by_mail')}`, 'Info');
            });
            // Perform desired actions below
            this.export(true);

            // Remove the click event listener after execution
            document.removeEventListener('click', clickHandler);
        };
        document.removeEventListener('click', clickHandler);
        const count: string = this.helperService.formatNumberWithThousandSeparators(this.limitBeforeMail) + '+';
        this.activeToast_export = this.toast.info(
            `
            <div class="toast-container-text">
                <div>
                    <span>${this.translate.instant('transactions.exporting_')} ${count} ${this.translate.instant('transactions.exporting_2')} </span>
                    </br></br>
                </div>
                <div class="toast-container-text-button">
                    <div class="sendViaMailTarget btn btn-primary btn-tiny">
                        <p class="sendViaMailTarget">${this.translate.instant('misc.email')}: ${this.exportParams.mail}</p>
                    </div>
                </div>
            </div>
            `,
            this.translate.instant('transactions.export'),
            {
                disableTimeOut: true,
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false
            }
        );
        document.addEventListener('click', clickHandler, false);
    }
}
