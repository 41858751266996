<div class="location-hr-wrapper">
    <div class="custom-location-hr">
        <span>{{ "misc.hubs" | translate }}</span>
    </div>
</div>

<!-- LOADING -->
@if (initLoading) {
    <div class="loading-height">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>
}

@if (!initLoading) {
    <div>
        @if (hubList.length > 0) {
            <div class="card-list">
                @for (hub of hubList; track $index) {
                    <div>
                        <div class="card-item" (click)="cardClickAction(hub, hubModal, '')">
                            <table>
                                <tr class="card-item-title-area">
                                    <td colspan="2" style="width: 100%">
                                        <div class="wrapper">
                                            <div class="title">Hub</div>
                                            <div class="subscription-state mobile"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="12" class="">
                                        <hr class="customized" />
                                    </td>
                                </tr>

                                <tr>
                                    <td class="label">{{ "location.serial_num" | translate }}:</td>
                                    <td class="value">{{ hub.serial_number }}</td>
                                </tr>

                                <tr>
                                    <td>
                                        <!-- empty -->
                                    </td>
                                </tr>

                                <tr>
                                    <td class="subscription-state desktop"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="empty-page-list">
                <p>{{ "location.no_hubs_on_location" | translate }}</p>
            </div>
        }
    </div>
}
<ng-template #hubModal let-modal>
    <app-hub [locationId]="locationId()" [hub]="selectedHub" (updateList)="updateHubs($event)"></app-hub>
</ng-template>
