import { z } from 'zod';
import { BusinessType } from '../../../shared_models/stripe';

export const createSubCustomerSchema = z
    .object({
        email: z.string().email(),
        business_type: z.enum([BusinessType.COMPANY, BusinessType.INDIVIDUAL]),
        country: z.string().min(1),
        terms_and_conditions: z.boolean().refine(val => val === true, {
            message: 'zod.errors.terms_and_conditions.required'
        }),
        gdpr_policy: z.boolean().refine(val => val === true, {
            message: 'zod.errors.gdpr_policy.required'
        })
    })
    .strict();

export type CreateSubCustomerZod = z.infer<typeof createSubCustomerSchema>;
