<section>
    <div class="input-container">
        <div class="card-details-wrapper">
            <div style="display: flex; justify-content: space-between">
                <label class="input-label"
                    ><strong>{{ "users.payment_method" | translate }}</strong></label
                >
                <div>
                    <button *ngIf="updateCard || !card" class="edit-btn" [disabled]="loading" (click)="handleOtherPM()">{{ "billing.other_pm" | translate }}</button>
                    <button *ngIf="card" class="edit-btn" (click)="handleUpdateCard()">{{ (updateCard ? "billing.cancel" : "overview.edit") | translate }}</button>
                </div>
            </div>
            @if (initialLoading) {
                <div style="height: 30.8px; display: flex; justify-content: center; align-items: center">
                    <loading-indicator [size]="'small'" [color]="'secondary'"></loading-indicator>
                </div>
            } @else {
                @if (!card || updateCard) {
                    <div class="form-control" id="card-element"></div>
                } @else {
                    <div style="display: flex; justify-content: flex-start; align-items: center; gap: 0.5rem; height: 30.8px">
                        <div>
                            {{card.bank_name ? card.bank_name + ' ' : ''}} {{ "• • • • " + card.last4  }} {{  getCardExp() }}
                        </div>
                        <div style="display: flex; gap: 0.3125rem; justify-content: flex-end; align-items: center" *ngIf="card.type">
                            <img style="width: 19px; aspect-ratio: 1" [title]="card.type" [src]="'../../../assets/icons/payment-methods/' + card.type + '.svg'" />
                        </div>
                        <div>
                            
                        </div>
                    </div>
                }
            }
        </div>
    </div>
</section>
