import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { CreditReport, CreditUser, CreditUserCreateParams, CreditUserDeleteResponse, CreditUserUpdateParams } from '@airwallet/shared-models/credit-payment';
import { HttpService, RequestTypes } from '@services/helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class CreditPaymentService {
    Details: AngularFireObject<any>;

    constructor(
        private db: AngularFireDatabase,
        private httpService: HttpService
    ) {}

    async getCreditUsers(locationId: string, uid: string): Promise<CreditUser[]> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/user`, RequestTypes.GET, { onBehalf: uid });
    }

    async reportReadCSV(locationId: string, start_date: number, uid: string): Promise<any> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/report`, RequestTypes.GET, { params: { start_date }, onBehalf: uid });
    }

    async createCreditUser(createParams: CreditUserCreateParams, locationId: string, uid: string): Promise<CreditUser[]> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/user`, RequestTypes.POST, { body: createParams, onBehalf: uid });
    }

    async updateCreditUser(updateParams: CreditUserUpdateParams, userId: string, locationId: string, uid: string): Promise<{ message: string; credit_user: CreditUser }> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/user/${userId}`, RequestTypes.PUT, { body: updateParams, onBehalf: uid });
    }

    async deleteUser(locationId: string, userId: string, uid: string): Promise<CreditUserDeleteResponse> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/user/${userId}`, RequestTypes.DELETE, { onBehalf: uid });
    }

    async createCode(locationId: string, uid: string): Promise<{ code: string }> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/code`, RequestTypes.GET, { onBehalf: uid });
    }

    async newCode(locationId: string, userId: string, uid: string, codeRequest: { smsNotification: boolean; newCode: string }): Promise<void> {
        return this.httpService.dynamicHttp(`credit/location/${locationId}/user/${userId}/code`, RequestTypes.PUT, { onBehalf: uid, body: { code: codeRequest.newCode, sms_notification: codeRequest.smsNotification } });
    }

    readReports(uid: string, locId: string): AngularFireObject<Record<string, CreditReport>> {
        return this.db.object(`customers/${uid}/credit_reports/${locId}`);
    }
}
