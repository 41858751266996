import { ZodArray, ZodObject, z, ZodEffects } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
        if (issue.received === 'null') {
            return { message: `zod.errors.` + issue.path[0] + `.required` };
        }
    }

    return { message: `zod.errors.` + issue.path[0] + `.` + issue.code };
};

z.setErrorMap(customErrorMap);

export function isValidRequestBody<T extends z.ZodRawShape>(body: any, schema: ZodObject<T, any> | ZodArray<any> | ZodEffects<ZodObject<any>>): true | { [key: string]: any } {
    try {
        schema.parse(body);
        return true;
    } catch (error) {
        return (error as { errors: any }).errors; // Return error messages
    }
}
export function isValidFormBody<T extends z.ZodRawShape>(formGroup: any, schema: ZodObject<T> | ZodArray<any> | ZodEffects<ZodObject<T>>): boolean {
    const res: z.SafeParseSuccess<T[]> | z.SafeParseError<T[]> | z.SafeParseSuccess<Record<string, T>> | z.SafeParseError<Record<string, T>> = schema.safeParse(formGroup.getRawValue());
    if (res.success === true) {
        return res.success as boolean;
    } else {
        const issues: z.ZodIssue[] = res.error.issues;
        for (const issue of issues) {
            const path = issue.path[0];
            if (formGroup.controls && !formGroup.controls[path]) {
                continue;
            }
            const message = issue.message;
            if (issue.code === z.ZodIssueCode.too_small) {
                setFormControlErrors(formGroup, path, message, issue.minimum);
            } else if (issue.code === z.ZodIssueCode.too_big) {
                setFormControlErrors(formGroup, path, message, issue.maximum);
            } else {
                setFormControlErrors(formGroup, path, message);
            }
        }

        console.error('Zod validation error', issues);

        throw formGroup;
    }
}

const setFormControlErrors = (formGroup: any, path: string | number, message: string, details?: number | bigint) => {
    const error: { zod_error: string; details?: number | bigint } = { zod_error: message };
    if (details !== undefined) {
        error.details = details;
    }
    formGroup.controls[path].setErrors(error);
    formGroup.controls[path].markAsTouched();
    formGroup.controls[path].markAsDirty();
    formGroup.updateValueAndValidity();
};
