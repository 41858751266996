import { Component, OnDestroy, OnInit } from '@angular/core';
import { FireOrder, Reason, RefundParams } from '@airwallet/shared-models/order';
import { TransactionService } from '../../services/transaction/transaction.service';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { CustomerService } from '../../services/customer/customer.service';
import { HelperService } from '../../services/helper/helper.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { Observable, Subscription } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Datepicker } from 'vanillajs-datepicker';
import { DatePickerLanguages } from '../../constants/datepickerLanguages';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../loading/loading.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { DateTimeService } from '@services/date-time/date-time.service';
import { FilterOption, FilterType } from '@airwallet/shared-models/aw-components/filterOption';
import { NgSelectModule } from '@ng-select/ng-select';
import { AwExportButtonComponent } from '@components/misc/aw-export-button/aw-export-button.component';
import { AwFilterButtonComponent } from '@components/misc/aw-filter-button/aw-filter-button.component';
import { FilterSortParams, TransactionSpecificFilterParams } from '@airwallet/shared-models/search-params/FilterSortParams';
import { PageLayoutComponent } from '@components/misc/aw-page-layout/page-layout.component';
import { AwTableComponent } from '@components/misc/aw-table/aw-table.component';
import { TableHeaderOptions } from '@airwallet/shared-models/aw-components/tableHeaderOptions';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import dayjs from 'dayjs';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ControlledUser } from '@airwallet/shared-models/controlled-user';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrls: ['./transactions.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        AsyncPipe,
        NgFor,
        LoadingComponent,
        CustomModalComponent,
        TranslateModule,
        PaginatePipe,
        NgSelectModule,
        AwExportButtonComponent,
        AwFilterButtonComponent,
        PageLayoutComponent,
        AwTableComponent
    ]
})
export class TransactionsComponent implements OnInit, OnDestroy {
    isOperator$: Observable<boolean>;
    orders: FireOrder[] = [];
    chosenCustomerUid: string;
    locationId: string;

    exportingExcel: boolean;
    subCustomerPermission: Subscription;
    user: DashboardUser;
    orderKeyToRefund = '';
    showRefund = true;
    orderToRefund: FireOrder;
    refundReason: Reason = Reason.requested_by_customer;
    otherReason: string;
    placeholderText: string;
    protected isMobile = false;
    fetchingData = false;
    initialLoading = true;

    params: FilterSortParams = {
        filter: {},
        sortBy: { key: 'timestamp', order: 'desc' },
        pageSize: 15,
        pageNumber: 0,
        action: 'next',
        total: 0,
    };

    filterOptions: FilterOption[] = [
        {
            key: 'timestamp',
            type: FilterType.DATE_RANGE,
            value: null,
            isDateRange: true,
            label: 'customers.created'
        },
        {
            key: 'customer_key',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.customers',
            selectOptions: [],
            hasDependencyOn: 'location_key'
        },
        {
            key: 'location_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.locations',
            selectOptions: [],
            hasDependencyOn: 'device_id'
        },
        {
            key: 'device_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.devices',
            placeholder: 'transactions.choose_location',
            selectOptions: [],
            disabled: true
        },
        {
            key: 'status',
            type: FilterType.SELECT,
            value: null,
            label: 'transactions.status',
            selectOptions: [
                { value: 'completed', label: this.translate.instant('transactions.completed') },
                { value: 'refund', label: this.translate.instant('transactions.refunded') }
            ]
        }
    ];

    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: '',
            title: this.translate.instant('transactions.transaction_id'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.date'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.amount'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.customer'),
            width: '10%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.location'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('misc.device'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.phone_no'),
            width: '15%'
        },
        {
            sortKey: '',
            title: this.translate.instant('transactions.refund'),
            width: '10%',
            alignment: 'center'
        }
    ];

    constructor(
        private authService: AuthService,
        private transactionService: TransactionService,
        private toast: ToastrService,
        private customerService: CustomerService,
        protected helperService: HelperService,
        public translate: TranslateService,
        private modalService: NgbModal,
        private dateTimeService: DateTimeService,
        private breakpointObserver: BreakpointObserver,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    ngOnDestroy() {
        if (this.subCustomerPermission) this.subCustomerPermission.unsubscribe();
    }

    async ngOnInit() {
        this.placeholderText = this.translate.instant('transactions.refund_enter_reason');
        this.user = this.helperService.getUser();
        this.isOperator$ = this.authService.isOperator;
        Object.assign(Datepicker.locales, DatePickerLanguages());
        Datepicker.locales['da']['today'] = 'I dag'; // fix for incorrect danish translation in the library
        this.setSubscriptions();
        this.getSubcustomers();
        const searchUid: string = this.route.snapshot.queryParamMap.get('uid');
        this.initializeFilterToMyAccount(searchUid);

        // const po_id: string = this.route.snapshot.queryParamMap.get('po_id');
        await this.loadTransactions();
        this.initialLoading = false;
    }

    private async initializeFilterToMyAccount(uid?: string) {
        this.chosenCustomerUid = uid ?? this.user.uid;
        const filterOption: FilterOption = this.filterOptions.find(option => option.key === 'customer_key');
        filterOption.value = this.chosenCustomerUid;
        this.hideCustomerFilterIfNotOperator(filterOption);
        this.getLocationNames();
    }

    private async hideCustomerFilterIfNotOperator(filterOption: FilterOption) {
        const isOperator: boolean = await this.authService.getOperatorStatus();
        if (!isOperator) filterOption.hidden = true; // Hide customer field, if not operator
    }

    async loadTransactions() {
        this.fetchingData = true;

        // const terminal: string = this.route.snapshot.queryParamMap.get('terminal');
        const specificSearch: TransactionSpecificFilterParams = {
            docId: this.route.snapshot.queryParamMap.get('docId') ?? null,
            user_id: this.route.snapshot.queryParamMap.get('user_id') ?? null,
            pm_fingerprint: this.route.snapshot.queryParamMap.get('pm_fingerprint') ?? null,
        };
        this.params.transactionSpecificFilterParams = specificSearch;

        await this.transactionService.getTransactions(this.params, this.chosenCustomerUid).then(data => {
            this.orders = data.orders;
            this.params.total = data.total;
            if (data.next_cursor) this.params.next_cursor = data.next_cursor;
            if (data.prev_cursor) this.params.prev_cursor = data.prev_cursor;
        }).catch(() => {
            this.toast.info(this.translate.instant('transactions.unknown'), this.translate.instant('misc.error'), { timeOut: 15000 });
        })
        this.fetchingData = false;
    }

    async handlePage(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.params.pageSize !== e.pageSize) {
            this.params.pageSize = e.pageSize;
            this.params.pageNumber = 0;
            this.params.prev_cursor = null;
            this.params.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.params.pageNumber = e.pageIndex;
        this.params.action = action;
        await this.loadTransactions();
    }

    getCustomerName(uid: string): string {
        if (!uid) return '';
        const customerFilter = this.filterOptions.find(option => option.key === 'customer_key');
        const customer = customerFilter.selectOptions.find(customer => customer.value === uid.split('_operated_by_')[0]);
        if (customer) return customer.label;
        return '';
    }

    setSubscriptions() {
        if (this.user.uid.includes('_operated_by_')) {
            this.subCustomerPermission = this.customerService
                .readSubCustomerPermission(this.user.uid)
                .snapshotChanges()
                .subscribe(action => {
                    this.subCustomerPermission.unsubscribe();
                    this.showRefund = action.payload.val().allow_refund;
                    if (!this.showRefund) {
                        this.tableHeaderOptions.pop();
                        this.tableHeaderOptions[0].width = '20%';
                    }
                });
        }
    }

    handleFilterValueChange(event: Record<string, any>) {
        if (event.key === 'location_key') {
            this.chosenCustomerUid = event.value;
            this.getLocationNames();
            this.getDeviceNames();
        }
        if (event.key === 'device_id') {
            this.locationId = event.value;
            this.getDeviceNames();
        }
    }

    async catchFilterChanged(event: Record<string, any>) {
        this.params.pageNumber = 0;
        this.params.total = 0;
        this.params.prev_cursor = null;
        this.params.next_cursor = null;
        if (Object.keys(event).length === 0) this.initializeFilterToMyAccount();
        else this.params.filter = event;
        await this.loadTransactions();
    }

    async getSubcustomers() {
        this.transactionService.getSubcustomerNames().then(subCustomerNames => {
            const customerFilter = this.filterOptions.find(option => option.key === 'customer_key');
            customerFilter.selectOptions = [{ value: this.user.uid, label: this.translate.instant('transactions.my_account') }, ...subCustomerNames];
        });
    }

    async getLocationNames() {
        this.transactionService.getLocationNames(this.chosenCustomerUid).then(locationNames => {
            this.filterOptions.find(option => option.key === 'location_id').selectOptions = locationNames;
        });
    }

    async getDeviceNames() {
        if (!this.locationId) {
            this.filterOptions.find(option => option.key === 'device_id').selectOptions = []; // reset device names
            this.filterOptions.find(option => option.key === 'device_id').disabled = true;
            this.filterOptions.find(option => option.key === 'device_id').placeholder = 'transactions.choose_location';
        } else {
            this.filterOptions.find(option => option.key === 'device_id').placeholder = 'misc.devices';
            this.transactionService.getDeviceNames(this.chosenCustomerUid, this.locationId).then(deviceNames => {
                this.filterOptions.find(option => option.key === 'device_id').disabled = false;
                this.filterOptions.find(option => option.key === 'device_id').selectOptions = deviceNames;
            });
        }
    }

    localizeNumber = (number: number, currency: string): string => {
        return `${currency ? currency.toUpperCase() : this.user.settings.currency.toUpperCase()} ${this.helperService.localizeNumber(number)}`;
    };

    openRefundModal(modal: any, order: FireOrder) {
        this.orderToRefund = order;
        this.openModal(modal);
    }

    refundReasonCorrector(): string {
        if (this.orderToRefund.refund_reason === 'duplicate') return this.translate.instant('transactions.refund_reason_duplicate');
        if (this.orderToRefund.refund_reason === 'fraudulent') return this.translate.instant('transactions.refund_reason_fraudulent');
        if (this.orderToRefund.refund_reason === 'requested_by_customer') return this.translate.instant('transactions.refund_reason_requested');
        if (this.orderToRefund.refund_reason === 'other') return this.translate.instant('transactions.refund_reason_other');
    }

    getUserName(): string {
        const controlledUser: ControlledUser | null = this.localStorageService.getItem('controlled_user') as ControlledUser;
        if (controlledUser) {
            return 'Airwallet';
        } else {
            return this.localStorageService.getItem('loggedInUser').name;
        }
    }

    async refund(order: FireOrder & { docId: string }, refundReason: Reason) {
        if (this.otherReason && this.otherReason.length > 255) {
            this.toast.info(this.translate.instant('transactions.reason_too_long'), this.translate.instant('misc.info'));
            return;
        }
        if (order.timestamp < dayjs().subtract(180, 'days').unix()) {
            this.toast.info(this.translate.instant('transactions.order_too_old'), this.translate.instant('misc.info'));
            return;
        }

        this.modalService.dismissAll();
        this.orderKeyToRefund = order.docId;
        const refundParams: RefundParams = {
            docId: order.docId,
            paymentId: order.payment_id,
            refundReason: refundReason,
            otherReason: this.otherReason ? this.otherReason : '',
            userName: this.getUserName(),
            customerUid: order.owner_uid,
        };
        await this.transactionService
            .refund(refundParams, this.chosenCustomerUid)
            .then(() => {
                order.refund = true;
                order.refund_reason = refundReason;
                order.refund_details = this.otherReason ? this.otherReason : '';
                order.user_name = this.getUserName();
            })
            .catch(() => {
                this.toast.info(this.translate.instant('transactions.unknown'), this.translate.instant('misc.error'), { timeOut: 15000 });
            });
        this.orderKeyToRefund = '';
        this.orderToRefund = null;
    }

    getLocalTime(timestamp: number): string {
        return this.dateTimeService.getDateAndTime(timestamp, false, true);
    }

    openModal(modal: any) {
        this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });
    }
}
