import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { SnapshotAction } from '@angular/fire/compat/database/interfaces';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../../services/customer/customer.service';
import { DeviceService } from '../../services/device/device.service';
import { HelperService } from '../../services/helper/helper.service';
import { LocationService } from 'src/app/services/location/location.service';
import { FixedPrice, IncrementPicker, Basic, PriceUnit, IncrementUnit, ProgramPicker, DeviceTypes, MachineProgram, UnitType } from '@airwallet/shared-models/device';
import { ProductType } from '@airwallet/shared-models/product-type';
import { Config } from '@airwallet/shared-models/config';
import { Details } from '@airwallet/shared-models/details';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PricingModel, GuidObject, GuidVars, ProductCategoryVars, PricingModelVars, DetailsVars, Signal, StartSignalVars, ReconfigValues } from './setup-models';
import { Location } from '@airwallet/shared-models/location';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { AwWizardComponent } from '../misc/aw-wizard-component/aw-wizard/aw-wizard.component';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { AwWizardStepComponent } from '../misc/aw-wizard-component/aw-wizard-step/aw-wizard-step.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { environment } from '../../../environments/environment';
import { PaginateFetchCacheService } from '@services/paginate-fetch-cache/paginate-fetch-cache.service';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-device-setup',
    templateUrl: './device-setup.component.html',
    styleUrls: ['./device-setup.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, FormsModule, ReactiveFormsModule, AwWizardComponent, AwWizardStepComponent, NgFor, CustomModalComponent, TranslateModule]
})
export class DeviceSetupComponent implements OnInit, OnDestroy {
    @ViewChild(AwWizardComponent, { static: false }) wizard: AwWizardComponent;

    user: DashboardUser;
    now: number = parseInt(moment().format('X'));
    typeOfDevice: DeviceTypes;
    deviceSetupForm: UntypedFormGroup;
    brandList: string[] = this.helperService.brandEnumToArray();
    customerUid: string;
    details: Details;
    ePriceUnit = PriceUnit;
    eProductType = ProductType;
    ePricingModel = PricingModel;
    eSignal = Signal;
    eIncrementUnit = IncrementUnit;
    isProgramPicked: boolean;
    isFullDurationProgram: boolean;
    guidChanged = new Subject<string>();
    PULSE_INCREMENT_ARR_FOR_TIME: number[] = this.helperService.buildMinOrMsValuesArray(30, 1); // only used on "time controlled", for "cost controlled" it is a free-number input field.
    reconfigValues: ReconfigValues;
    validatingGuid: boolean;
    setupDeviceLoading: boolean;
    continuedR2Setup: boolean;
    agreementType: string;
    location: Location;
    protocolVersion: string = null;
    currency: string = this.helperService.getUser().settings.currency;
    subCustomerUid: string | null;
    busySignal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    // translate
    loading: string;
    complete_tr: string;
    cost: string;
    time: string;
    customerDetailsSub: Subscription;
    locationSub: Subscription;
    nextStep: string;
    overwritePriceUnit: { unit: PriceUnit.Hour | PriceUnit.Minute | PriceUnit.Increment; label: string };
    uid: string;
    isCustomerOperated: boolean;
    hideFixedDuration: boolean;
    setupVars: {
        guidVars: GuidVars;
        productCategoryVars: ProductCategoryVars;
        pricingModelVars: PricingModelVars;
        startSignalVars: StartSignalVars;
        detailsVars: DetailsVars;
    } = {
        guidVars: {
            valid: null,
            guidObject: null,
            protocolVersion: null,
            guidValidationMsg: null,
            isR1: true,
            relayNumber: null,
            nextStepFired: null,
            anyOpenRelay: null
        },
        productCategoryVars: {
            productType: null,
            goToPricingStepFired: null
        },
        pricingModelVars: {
            pricingModel: null,
            goToStartSignalStepFired: null
        },
        startSignalVars: {
            signal: null,
            pulseOptions: null,
            goToOptionsStepFired: null
        },
        detailsVars: {
            disableSubmitBtn: null,
            formSubmitted: null
        }
    };

    deviceNamesOnLocation: string[] = [];

    formGroup = {
        // MACHINE SPPECIFICATIONS, should be present on all new devices.
        brand: [null, [Validators.required]],
        model: [null, [Validators.required, Validators.maxLength(25)]],
        serial_number: [null, []],
        production_year: [
            null,
            [
                // Current year (+1) and 40 years back, as a list in a dropdown. Format is: YYYY
                Validators.required,
                Validators.min(parseInt(moment().format('YYYY')) - 40),
                Validators.max(parseInt(moment().format('YYYY')) + 1),
                Validators.maxLength(4),
                Validators.minLength(4),
                Validators.pattern(/^([1-9][0-9][0-9][0-9])$/)
            ]
        ],
        note: [null, [Validators.maxLength(250)]],
        coin_drop_installed: [false],
        coin_feedback_enabled: [null],
        open_door: [false],
        // DEVICE OPTIONS
        guid: [null, [Validators.required]],
        name: [null, [Validators.required, Validators.maxLength(22), this.uniqueNamesOnlyValidator()]],
        price: [null, [Validators.required, Validators.pattern(/^(\d+(?:[\.\,]\d{1,2})?)$/)]], // float with one or two decimals
        startup_time: [null, [Validators.required]],
        pulse_duration: [null, [Validators.required, Validators.pattern(/^(?:2[5-9]|[3-9]\d|[1-9]\d{2}|[1-2]\d{3}|3000)$/)]], // range from 25 to 3000
        pulse_increment: [null], // validated in validateFormBasedOnConfig()
        pulse_number: [null, [Validators.required, Validators.min(1), Validators.max(100)]],
        fixed_duration: [null],
        ac_detect_mode: [null, [Validators.required]],
        machine_programs: [null]
    };

    constructor(
        private customerService: CustomerService,
        private formBuilder: UntypedFormBuilder,
        private deviceService: DeviceService,
        private locationService: LocationService,
        private helperService: HelperService,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastrService,
        private modalService: NgbModal,
        private translate: TranslateService,
        private localStorageService: LocalStorageService
    ) {
        this.deviceSetupForm = this.formBuilder.group(this.formGroup);
        if (this.route.snapshot.paramMap.get('reconfig_values')) {
            this.reconfigValues = localStorageService.getItem('reconfigValues');
            if (!this.reconfigValues.continued_setup) {
                this.deviceSetupForm.patchValue(this.reconfigValues.device);
                this.deviceSetupForm.patchValue(this.reconfigValues.device.machine_specifications);
            }
            this.guid.setValue(this.reconfigValues.serial);
            this.guid.updateValueAndValidity();
            this.setupVars.guidVars.valid = true;
            this.setupVars.guidVars.guidObject = {
                serial: null,
                password: this.reconfigValues.password, // only password is needed to be set in this object to be shipped on complete.
                used: null
            };

            if (this.reconfigValues.relay_number) {
                this.setupVars.guidVars.isR1 = false;
                this.setupVars.guidVars.relayNumber = this.reconfigValues.relay_number;
            } else {
                this.setupVars.guidVars.isR1 = true;
            }
        } else {
            localStorageService.removeItem('reconfigValues');
        }
    }

    ngOnDestroy(): void {
        this.customerDetailsSub ? this.customerDetailsSub.unsubscribe() : null;
        this.locationSub ? this.locationSub.unsubscribe() : null;
    }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.subCustomerUid = this.route.snapshot.paramMap.get('sub_customer_id');
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.loading = this.translate.instant('device_setup.loading');
        this.complete_tr = this.translate.instant('device_setup.complete');
        this.cost = this.translate.instant('device_setup.cost');
        this.time = this.translate.instant('device_setup.time');
        this.nextStep = this.translate.instant('device_setup.next_step');

        if (this.reconfigValues) {
            await this.validateGuid(this.guid.value).catch(err => {
                console.error(err);
            });
        }
        this.setSubscriptions();
    }

    uniqueNamesOnlyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.reconfigValues) {
                const forbidden = this.deviceNamesOnLocation.includes(control.value) && this.reconfigValues.device.name !== control.value;
                return forbidden ? { uniqueName: { value: control.value } } : null;
            } else {
                const forbidden = this.deviceNamesOnLocation.includes(control.value);
                return forbidden ? { uniqueName: { value: control.value } } : null;
            }
        };
    }

    setSubscriptions() {
        this.customerDetailsSub = this.customerService
            .readCustomerDetails(this.uid)
            .snapshotChanges()
            .subscribe((action: SnapshotAction<Details>) => {
                this.customerDetailsSub.unsubscribe();
                this.details = action.payload.val();
                this.customerUid = action.payload.ref.parent.key;
                this.agreementType = this.details.agreement_type;
            });

        this.locationSub = this.locationService
            .readLocation(this.uid, this.route.snapshot.paramMap.get('location_id'))
            .snapshotChanges()
            .subscribe(action => {
                this.locationSub.unsubscribe();
                this.location = action.payload.val();
                this.deviceNamesOnLocation = this.location.devices ? Object.keys(this.location.devices).map(key => this.location.devices[key].name) : [];
                this.currency = action.payload.val().currency ? action.payload.val().currency : this.user.settings.currency;
            });
    }

    getCurrencyInUppercase(currency: string): string {
        return currency.toUpperCase();
    }

    getDefaultStartStep(): number {
        if (this.reconfigValues) {
            return 1;
        }

        return 0; // if not this.reconfigValues, then return 0 as default step
    }

    resetVars(skipRecord?: string[]) {
        for (const key in this.setupVars) {
            if (!skipRecord.includes(key)) for (const key2 in this.setupVars[key]) this.setupVars[key][key2] = null;
        }
    }

    async goToProductCategory() {
        this.setupVars.guidVars.valid = false;
        this.setupVars.guidVars.nextStepFired = true;
        this.validatingGuid = true;

        await this.validateGuid(this.guid.value)
            .then(() => {
                if (this.setupVars.guidVars.valid) {
                    this.validatingGuid = false;
                    this.resetVars(['guidVars']);
                    this.wizard.goToStep(1);
                }
            })
            .catch(() => {
                this.validatingGuid = false;
            });
    }

    goToPricingStep() {
        this.wizard.goToStep(2);
    }

    goToStartSignalStep() {
        this.setupVars.pricingModelVars.goToStartSignalStepFired = true;
        if (this.setupVars.pricingModelVars.pricingModel) this.wizard.goToStep(3);
    }

    goToOptionsStep() {
        this.wizard.goToStep(this.wizard.wizardSteps.length - 1);
    }

    goBackFromOptionsStep() {
        this.wizard.goToStep(this.wizard.wizardSteps.length - 2);
    }

    goBackFromPricingModelStep() {
        this.wizard.goToStep(1);
    }

    goBackFromStartSignalStep() {
        this.wizard.goToStep(2);
    }

    removeRadioChecks(id: string) {
        const idElement: Element = document.getElementById(id);
        const radios: NodeListOf<Element> = idElement.querySelectorAll('input[type="radio"]');
        radios.forEach(radio => {
            (radio as HTMLInputElement).checked = false;
        });
    }

    removeValuesAhead(stepIndex: number) {
        if (this.setupVars.productCategoryVars.productType != ProductType.Solarium) {
            console.log('removeValuesAhead', stepIndex);
            if (stepIndex === 1) {
                this.setupVars.pricingModelVars.pricingModel = null;
                this.setupVars.pricingModelVars.goToStartSignalStepFired = false;
                //Remove radio button checks
                this.removeRadioChecks('pricing-model');

                this.setupVars.startSignalVars.signal = null;
                this.setupVars.startSignalVars.pulseOptions = null;
                this.setupVars.startSignalVars.goToOptionsStepFired = false;
                //Remove radio button checks
                this.removeRadioChecks('start-signal');

                this.isProgramPicked = false;
            }

            if (stepIndex === 2) {
                this.setupVars.startSignalVars.signal = null;
                this.setupVars.startSignalVars.pulseOptions = null;
                this.setupVars.startSignalVars.goToOptionsStepFired = false;
                this.isProgramPicked = false;

                //Remove radio button checks
                this.removeRadioChecks('start-signal');
            }

            if (stepIndex === 3) {
                this.isProgramPicked = false;
            }
        }
    }

    setProductCategory(value: ProductType) {
        if (this.setupVars.productCategoryVars.productType !== value) {
            this.removeValuesAhead(this.wizard.currentStepIndex);
        }
        this.setupVars.productCategoryVars.productType = value;
    }

    setPricingModel(value: PricingModel) {
        if (this.setupVars.pricingModelVars.pricingModel != value) {
            this.removeValuesAhead(this.wizard.currentStepIndex);
        }
        this.setupVars.pricingModelVars.pricingModel = value;
    }

    setStartSignal(value: Signal) {
        console.log('setStartSignal', value);
        this.setupVars.startSignalVars.signal = value;
        this.setupVars.startSignalVars.goToOptionsStepFired = false;
        this.setupVars.startSignalVars.pulseOptions = null;
        if (value === Signal.FullDuration) {
            this.deviceSetupForm.patchValue({ pulse_increment: 5 }, { emitEvent: false });
            this.typeOfDevice = 'fullDuration';
        } else {
            this.deviceSetupForm.patchValue({ emitEvent: false });
        }

        if (value === Signal.UserStartTime && this.setupVars.pricingModelVars.pricingModel === PricingModel.Fixed) {
            this.deviceSetupForm.patchValue({ pulse_duration: this.helperService.protocolVersionValidator(this.protocolVersion).legacy ? 4 : 15 }, { emitEvent: false });
            this.typeOfDevice = 'fixedUserStartTime';
        } else {
            this.deviceSetupForm.patchValue({ emitEvent: false });
        }

        if (this.setupVars.startSignalVars.signal === Signal.Pulse && !this.setupVars.startSignalVars.pulseOptions) {
            this.deviceSetupForm.patchValue({ pulse_duration: null });
        }

        if (this.setupVars.pricingModelVars.pricingModel === PricingModel.Fixed && this.setupVars.startSignalVars.signal === Signal.Pulse) {
            this.typeOfDevice = 'fixedPulse';
            this.deviceSetupForm.patchValue({ pulse_duration: null });
        }

        console.log(this.typeOfDevice);

        this.showFixedDuration();
        this.removeValuesAhead(this.wizard.currentStepIndex);
    }

    setPulseOption(value: IncrementUnit | 'program') {
        // if value is null, program is selected
        if (this.setupVars.startSignalVars.pulseOptions !== value) {
            this.removeValuesAhead(this.wizard.currentStepIndex);
        }

        if (this.setupVars.startSignalVars.signal === Signal.FullDuration) {
            if (value === IncrementUnit.Time) {
                this.setupVars.startSignalVars.pulseOptions = value;
                return;
            }
        }

        this.isFullDurationProgram = false; // resets the value
        if (value === 'program') {
            this.isProgramPicked = true;
            this.typeOfDevice = 'programPicker';
            if (this.setupVars.startSignalVars.signal === Signal.FullDuration) {
                this.isFullDurationProgram = true;
            }
        } else if (value === IncrementUnit.Time) {
            this.typeOfDevice = 'incrementPickerTime';
        } else if (value === IncrementUnit.Money) {
            this.typeOfDevice = 'incrementPickerCost';
        }

        this.setupVars.startSignalVars.pulseOptions = value;

        console.log(this.typeOfDevice);
    }

    nextStepFromProductOrSignal(caller: 'product' | 'signal') {
        if (this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Time || this.setupVars.startSignalVars.signal === Signal.FullDuration) {
            this.togglePriceUnit(this.overwritePriceUnit && this.overwritePriceUnit.unit ? this.overwritePriceUnit.unit : PriceUnit.Hour); // init
        }

        if (
            !(
                this.isProgramPicked ||
                (this.setupVars.guidVars.isR1 &&
                    ((this.setupVars.startSignalVars.signal === this.eSignal.Pulse && !this.setupVars.startSignalVars.pulseOptions) ||
                        (this.setupVars.startSignalVars.signal === this.eSignal.UserStartTime && !this.setupVars.startSignalVars.pulseOptions))) ||
                this.setupVars.startSignalVars.pulseOptions === this.eIncrementUnit.Money
            )
        ) {
            this.deviceSetupForm.patchValue(
                {
                    ac_detect_mode: null,
                    fixed_duration: null
                },
                { emitEvent: false }
            );
        }

        this.setupVars.productCategoryVars.goToPricingStepFired = false;
        this.setupVars.startSignalVars.goToOptionsStepFired = false;
        if (caller === 'product') this.setupVars.productCategoryVars.goToPricingStepFired = true;
        if (caller === 'signal') this.setupVars.startSignalVars.goToOptionsStepFired = true;

        if ((caller === 'product' && !this.setupVars.productCategoryVars.productType) || (caller === 'signal' && !this.setupVars.startSignalVars)) {
            return;
        }
        if (this.setupVars.startSignalVars.signal === Signal.FullDuration && !this.setupVars.startSignalVars.pulseOptions) {
            return;
        }

        if (caller === 'product') {
            this.goToPricingStep();
            return;
        }

        if (caller === 'signal') {
            if (this.isProgramPicked) {
                //Program picked
                this.goToOptionsStep();
                return;
            }

            if (this.setupVars.startSignalVars.signal === Signal.Pulse && this.setupVars.startSignalVars.pulseOptions) {
                // Caller == signal is pulse
                this.goToOptionsStep();
                return;
            }
            if (this.setupVars.startSignalVars.signal === Signal.Pulse && this.setupVars.pricingModelVars.pricingModel === PricingModel.Fixed) {
                // Caller == signal is pulse with fixed price
                this.goToOptionsStep();
                return;
            }

            if (this.setupVars.startSignalVars.signal === Signal.UserStartTime || this.setupVars.startSignalVars.signal === Signal.FullDuration) {
                // Caller == signal, userStartTime or Full
                this.goToOptionsStep();
                return;
            }
        }
    }

    getBasicModel(config: Config, maximum_value: number, minimum_value: number, price_unit: PriceUnit, product_type: ProductType): Basic {
        const formValues = { ...this.deviceSetupForm.value };

        return {
            ac_detect_mode: this.ac_detect_mode.value || this.ac_detect_mode.value === 0 ? this.ac_detect_mode.value : null,
            config, // previous known as 'type'
            created_on: this.now,
            customer_uid: this.customerUid,
            currency: this.currency,
            guid: formValues.guid,
            id: this.reconfigValues && this.reconfigValues.dev_id ? this.reconfigValues.dev_id : null,
            last_used: this.now,
            location_id: this.location.id,
            location_name: this.location.name,
            minimum_value, // is in ms
            maintenance: this.reconfigValues && this.reconfigValues.device && this.reconfigValues.device.maintenance ? true : false,
            subscribed: this.agreementType === 'life' ? false : null, // only for life accounts do we wanna skip this value
            maximum_value,
            machine_specifications: {
                brand: this.brand.value,
                model: this.model.value,
                note: this.note.value,
                production_year: this.production_year.value,
                serial_number: this.serial_number.value,
                machine_spec_service: {
                    maintenance_limit: 1000,
                    current_maintenance_count: 0
                }
            },
            name: this.name.value,
            coin_drop_installed: this.coin_drop_installed.value,
            coin_feedback_enabled: this.ac_detect_mode.value !== null ? (this.coin_drop_installed.value && (this.ac_detect_mode.value === 1 || this.ac_detect_mode.value === 3) ? true : false) : null,
            open_door: this.open_door.value,
            password: this.setupVars.guidVars.guidObject.password,
            price: formValues.price || formValues.price === 0 ? this.helperService.roundToTwoDecimals(formValues.price * 100) : null, // to be saved in 100's (cents). It is done with toFixed and parseFloat because of rounding issues. Explained in this post: https://stackoverflow.com/questions/21472828/javascript-multiplying-by-100-giving-weird-result
            price_unit,
            product_type, // e.i. 'WASHER' or 'DRYER' etc...
            protocol_version: this.helperService.protocolVersionValidator(this.protocolVersion).version,
            relay_number: this.setupVars.guidVars.relayNumber,
            startup_time: parseInt(this.startup_time.value) ? parseInt(this.startup_time.value) : null, // if instant start, it is set to 0 and corrected here to null, because we won't ship values that does not provide any value -.-
            full_duration_program: this.isFullDurationProgram ? true : false,
            unit_type: UnitType.DEVICE
        };
    }

    getIncrementModel(config: Config, priceUnit: PriceUnit, incrementUnit: IncrementUnit | null, isSolarium?: boolean): IncrementPicker | FixedPrice | ProgramPicker {
        const obj = {
            ...this.getBasicModel(
                config,
                isSolarium ? 30 * 60 * 1000 : 300 * 60 * 1000, // solariums is 30 mins, and 300 mins (5 hours) for all others
                1 * 60 * 1000,
                priceUnit,
                this.setupVars.productCategoryVars.productType
            ),
            increment_unit: incrementUnit,

            pulse_duration: this.pulse_duration.value //  if(pulse_duration)
                ? this.setupVars.startSignalVars.signal === Signal.UserStartTime //      if(user start time)
                    ? parseInt(this.pulse_duration.value) * 60000 //          else pulse_duration * 60000 (entered in mins)
                    : this.pulse_duration.value && !isNaN(this.pulse_duration.value)
                      ? parseInt(this.pulse_duration.value)
                      : null //      else pulse_duration (entered in ms 5-3000)
                : null, //  else null

            pulse_increment: isSolarium ? 1 : this.pulse_increment.value && !isNaN(this.pulse_increment.value) ? this.pulse_increment.value * (incrementUnit && incrementUnit === IncrementUnit.Money ? 100 : 1) : null,

            pulse_number:
                !this.setupVars.guidVars.isR1 && this.helperService.protocolVersionValidator(this.protocolVersion).legacy && this.pulse_duration.value
                    ? 1
                    : this.pulse_number.value && !isNaN(this.pulse_number.value)
                      ? parseInt(this.pulse_number.value)
                      : null,

            pulse_pause: this.setupVars.startSignalVars.signal === Signal.Pulse ? (this.pulse_duration.value && !isNaN(this.pulse_duration.value) ? parseInt(this.pulse_duration.value) : this.pulse_duration.value) : null,

            fixed_duration: this.fixed_duration.value === 0 || this.fixed_duration.value ? this.fixed_duration.value : null,

            machine_programs: this.getProgramValues(this.machine_programs.value, this.pulse_duration.value, this.reconfigValues ? this.reconfigValues.device : null)
        };
        return obj;
    }

    getProgramValues(programs: Record<string, MachineProgram>, pulse_duration?: number, reconfigDevice?: Basic | IncrementPicker | FixedPrice | ProgramPicker): Record<string, MachineProgram> | null {
        if (!programs) return null;
        if (reconfigDevice) {
            const device: ProgramPicker = reconfigDevice as ProgramPicker;

            // Pulse program to pulse program
            if (device.pulse_duration && pulse_duration) {
                return programs;
            }

            // Fullduration program to fullduration program
            if (!device.pulse_duration && !pulse_duration) {
                return programs;
            }
        }
        return null;
    }

    getCompleteDeviceModel(): Basic | IncrementPicker | FixedPrice | ProgramPicker {
        if (this.setupVars.productCategoryVars.productType) {
            if (this.ac_detect_mode.value > 0) {
                this.deviceSetupForm.patchValue(
                    {
                        fixed_duration: 180
                    },
                    { emitEvent: false }
                );
            }
            console.log('product type');

            // solarium
            if (this.setupVars.productCategoryVars.productType === ProductType.Solarium) {
                console.log('Device model used: "solarium"');
                return this.getIncrementModel(Config.IncrementPicker, PriceUnit.Minute, IncrementUnit.Time, true);
            }

            // variable, cost, pulse
            if (this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Money) {
                console.log('Device model used: "variable, cost, pulse"');
                return this.getIncrementModel(Config.IncrementPicker, PriceUnit.None, IncrementUnit.Money, null);
            }

            // variable, time, pulse
            if (this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Time && this.setupVars.startSignalVars.signal === Signal.Pulse) {
                console.log('Device model used: "variable, time, pulse"');
                return this.getIncrementModel(
                    Config.IncrementPicker,
                    this.overwritePriceUnit && this.overwritePriceUnit.unit === PriceUnit.Hour ? PriceUnit.Hour : this.overwritePriceUnit.unit === PriceUnit.Minute ? PriceUnit.Minute : PriceUnit.Increment,
                    IncrementUnit.Time,
                    null
                );
            }
            //variable, program, pulse
            if (this.isProgramPicked) {
                console.log('Device model used: "variable, program, pulse');
                return this.getIncrementModel(Config.ProgramPicker, PriceUnit.None, null, null);
            }

            // variable, full duration
            if (this.setupVars.startSignalVars.signal === Signal.FullDuration && !this.isProgramPicked) {
                console.log('Device model used: "variable, full duration"');
                return this.getIncrementModel(
                    Config.IncrementPicker,
                    this.overwritePriceUnit && this.overwritePriceUnit.unit === PriceUnit.Hour ? PriceUnit.Hour : this.overwritePriceUnit.unit === PriceUnit.Minute ? PriceUnit.Minute : PriceUnit.Increment,
                    IncrementUnit.Time,
                    null
                );
            }

            // fixed, cost, pulse
            if (this.setupVars.startSignalVars.signal === Signal.Pulse && !this.setupVars.startSignalVars.pulseOptions) {
                console.log('Device model used: "fixed, cost, pulse"');
                return this.getIncrementModel(Config.FixedPrice, PriceUnit.Fixed, null, null);
            }
            // fixed, cost, user start time
            if (this.setupVars.startSignalVars.signal === Signal.UserStartTime && !this.setupVars.startSignalVars.pulseOptions) {
                console.log('Device model used: "fixed, cost, user start time"');
                return this.getIncrementModel(Config.FixedPrice, PriceUnit.Fixed, null, null);
            }
        }

        return null; // if nothing matches
    }

    validateFormBasedOnConfig() {
        for (const key in this.formGroup) {
            this.deviceSetupForm.controls[key].setValidators(this.formGroup[key][1]);
            this.deviceSetupForm.controls[key].updateValueAndValidity();
        }

        function pulseDurationOnLegacy(control: AbstractControl): Record<string, any> | null {
            if (control.value % 250 !== 0) {
                return { pulseDurationOnLegacy: true };
            }
            return null;
        }
        console.log(this.setupVars);

        // Validating: Solarium
        if (this.setupVars.productCategoryVars.productType === ProductType.Solarium) {
            this.pulse_duration.setValidators(null);
            this.pulse_increment.setValidators(null);
            this.pulse_number.setValidators(null);
            this.ac_detect_mode.setValidators(null);
            this.deviceSetupForm.patchValue(
                {
                    pulse_duration: null,
                    pulse_increment: null,
                    pulse_number: null,
                    ac_detect_mode: null,
                    open_door: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );
        } else {
            this.startup_time.setValidators(null);
            this.deviceSetupForm.patchValue(
                {
                    startup_time: null
                },
                { emitEvent: false }
            );
        }

        // Validating: Variable, pulse, cost
        if (this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Money && this.setupVars.startSignalVars.signal !== Signal.FullDuration) {
            this.pulse_increment.setValidators([Validators.required, Validators.min(0.01), Validators.max(100000)]);
            this.fixed_duration.setValidators([Validators.required, Validators.min(0), Validators.max(180)]);
            this.price.setValidators(null);
            if (this.protocolVersion === '1.5.0' || this.protocolVersion === '1.0.0') {
                this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
            }
            this.deviceSetupForm.patchValue(
                {
                    price: null,
                    open_door: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );
        }

        // Validating: Variable, pulse, time
        if (this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Time && this.setupVars.startSignalVars.signal !== Signal.FullDuration) {
            this.pulse_increment.setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
            if (this.protocolVersion === '1.5.0' || this.protocolVersion === '1.0.0') {
                this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
            }
            this.deviceSetupForm.patchValue(
                {
                    open_door: null,
                    machine_programs: null,
                    fixed_duration: null
                },
                { emitEvent: false }
            );

            if (this.ac_detect_mode.value === 0) {
                this.ac_detect_mode.setValidators(null);
            } else {
                this.ac_detect_mode.setValidators([Validators.required]);
            }
        }

        // Validating: Variable, pulse, program
        if (this.isProgramPicked && this.setupVars.startSignalVars.signal !== Signal.FullDuration) {
            this.price.setValidators(null);
            this.pulse_number.setValidators(null);
            this.pulse_increment.setValidators(null);
            console.log(this.protocolVersion);
            if (this.protocolVersion === '1.5.0' || this.protocolVersion === '1.0.0') {
                this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
            }
            this.deviceSetupForm.patchValue(
                {
                    price: null,
                    open_door: null,
                    pulse_increment: null
                },
                { emitEvent: false }
            );
        }

        // Validating: Variable, full duration
        if (this.setupVars.startSignalVars.signal === Signal.FullDuration && !this.isProgramPicked) {
            this.pulse_increment.setValidators([Validators.required, Validators.min(1), Validators.max(30)]);
            this.pulse_number.setValidators(null);
            this.pulse_duration.setValidators(null);
            this.deviceSetupForm.patchValue(
                {
                    pulse_number: null,
                    startup_time: null,
                    pulse_duration: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );

            if (this.ac_detect_mode.value === 0) {
                this.ac_detect_mode.setValidators(null);
            } else {
                this.ac_detect_mode.setValidators([Validators.required]);
            }
        }

        // Validating: Variable, Full Duration Program,
        if (this.setupVars.startSignalVars.signal === Signal.FullDuration && this.isProgramPicked) {
            this.pulse_increment.setValidators(null);
            this.price.setValidators(null);
            this.pulse_duration.setValidators(null);

            this.deviceSetupForm.patchValue(
                {
                    pulse_increment: null,
                    pulse_number: 1,
                    startup_time: null,
                    pulse_duration: null,
                    price: null
                },
                { emitEvent: false }
            );
        }

        // Validating: Fixed, user start time
        if (this.setupVars.pricingModelVars.pricingModel === PricingModel.Fixed && this.setupVars.startSignalVars.signal === Signal.UserStartTime) {
            console.log('Fixed, user start time');
            this.pulse_number.setValidators(null);
            this.pulse_increment.setValidators(null);
            //Accepts only single digits starting from 1, double digits and triple digits up to 180
            this.pulse_duration.setValidators([Validators.required, Validators.pattern(this.protocolVersion === '1.5.0' || this.protocolVersion === '1.0.0' ? /^([1-4])$/ : /^([1-9]|[1-9][0-9]|1[0-7][0-9]|180)$/)]);
            this.deviceSetupForm.patchValue(
                {
                    pulse_number: null,
                    pulse_increment: null,
                    open_door: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );
        }

        // Validating: Fixed, pulse
        if (this.setupVars.pricingModelVars.pricingModel === PricingModel.Fixed && this.setupVars.startSignalVars.signal === Signal.Pulse) {
            if (this.protocolVersion === '1.5.0' || this.protocolVersion === '1.0.0') {
                this.pulse_duration.setValidators([Validators.required, Validators.pattern(null), Validators.min(250), pulseDurationOnLegacy]);
            }

            this.deviceSetupForm.patchValue(
                {
                    open_door: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );
        }

        // R2's
        if (!this.setupVars.guidVars.isR1) {
            this.ac_detect_mode.setValidators(null);
            this.deviceSetupForm.patchValue(
                {
                    ac_detect_mode: null,
                    machine_programs: null
                },
                { emitEvent: false }
            );
        }

        for (const key in this.formGroup) {
            this.deviceSetupForm.controls[key].updateValueAndValidity();
        }
    }

    async validateGuid(guid: string): Promise<unknown> {
        const promise = new Promise<unknown>((resolve, reject) => {
            if (this.reconfigValues) {
                this.protocolCheck(guid);
                return resolve(null);
            }

            if (!guid) {
                this.setGuidValidationState('error', 1);
                return reject('error 1a');
            } else if (guid.length === 0 && this.setupVars.guidVars.nextStepFired) {
                this.setGuidValidationState('error', 1);
                return reject('error 1b');
            } else if (guid.length < 12 && this.setupVars.guidVars.nextStepFired) {
                this.setGuidValidationState('error', 2);
                return reject('error 2');
            } else if (guid.length > 12 && this.setupVars.guidVars.nextStepFired) {
                this.setGuidValidationState('error', 3);
                return reject('error 3');
            } else {
                document.getElementById('serial-validation-msg').classList.remove('error-msg');
                document.getElementById('serial-validation-msg').classList.add('text-primary');
                this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.loading_wait');

                return this.deviceService
                    .readSerialRecord(guid)
                    .snapshotChanges()
                    .subscribe(async (serialObjSnapAction: SnapshotAction<GuidObject>[]) => {
                        if (serialObjSnapAction.length > 0 && serialObjSnapAction[0].payload.val()['data'] !== '') {
                            this.setupVars.guidVars.guidObject = serialObjSnapAction[0].payload.val();
                            const guid = this.setupVars.guidVars.guidObject.serial;
                            this.setupVars.guidVars.valid = true;

                            await this.protocolCheck(guid);
                            if (guid.substring(guid.length - 2) === 'R1') {
                                this.setupVars.guidVars.isR1 = true;
                                if (this.setupVars.guidVars.guidObject['used']) {
                                    this.setGuidValidationState('error', 4);
                                    return reject('error 4');
                                }
                                return resolve(this.setupVars.guidVars.guidObject);
                            } else {
                                this.setupVars.guidVars.isR1 = false;

                                if (this.setupVars.guidVars.guidObject['used'] && this.setupVars.guidVars.guidObject['used2']) {
                                    // both in use
                                    this.setGuidValidationState('error', 4);
                                    return reject('error 4');
                                }

                                this.setupVars.guidVars.relayNumber = this.setupVars.guidVars.guidObject['used'] ? 2 : 1;

                                // do a check to see if both relays are now in use
                                if (this.setupVars.guidVars.relayNumber === 1 && !this.setupVars.guidVars.guidObject['used2']) {
                                    this.setupVars.guidVars.anyOpenRelay = true;
                                }

                                return resolve(this.setupVars.guidVars.guidObject);
                            }
                        } else if (this.setupVars.guidVars.nextStepFired) {
                            this.setGuidValidationState('invalid');
                            return reject('invalid');
                        } else {
                            setTimeout(() => {
                                this.setupVars.guidVars.guidValidationMsg = '';
                            }, 800);
                            return reject('last step');
                        }
                    });
            }
        });

        return Promise.resolve(promise);
    }

    setGuidValidationState(state: string, msg?: number) {
        if (!document.getElementById('device-setup-page'))
            // returns if page is redirected and this is hanging. E.g. after continued R2 setup or completed device setup.
            return;

        switch (state) {
            case 'valid':
                document.getElementById('serial-validation-msg').classList.remove('error-msg');
                document.getElementById('serial-validation-msg').classList.remove('text-primary');
                document.getElementById('serial-validation-msg').classList.add('success-msg');
                this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_ok');
                this.setupVars.guidVars.valid = true;
                break;
            case 'invalid':
                document.getElementById('serial-validation-msg').classList.remove('error-msg');
                document.getElementById('serial-validation-msg').classList.add('text-primary');
                this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_not_valid');
                this.setupVars.guidVars.valid = false;
                break;
            case 'error':
                switch (msg) {
                    case 1:
                        this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_num_req');
                        break;
                    case 2:
                        this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_num_min');
                        break;
                    case 3:
                        this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_num_max');
                        break;
                    case 4:
                        this.setupVars.guidVars.guidValidationMsg = this.translate.instant('device_setup.serial_num_used');
                        break;
                    default:
                        break;
                }
                document.getElementById('serial-validation-msg').classList.remove('text-primary');
                document.getElementById('serial-validation-msg').classList.remove('success-msg');
                document.getElementById('serial-validation-msg').classList.add('error-msg');
                this.setupVars.guidVars.valid = false;
                break;
            default:
                break;
        }
    }

    protocolCheck(guid: string): Promise<void> {
        return new Promise(async (resolve: any, reject: any) => {
            return this.deviceService
                .getProtocolVersion(guid)
                .then((res: any) => {
                    this.setGuidValidationState('valid');
                    const protocolVersion = res.data;
                    if (protocolVersion && protocolVersion !== '') {
                        this.setupVars.guidVars.protocolVersion = protocolVersion;
                        this.protocolVersion = protocolVersion;
                    }
                    return resolve();
                })
                .catch(err => {
                    console.error(err);
                    this.toast.info(`${this.translate.instant('device_setup.fail_protocol_lookup')}`, this.translate.instant('misc.info'));
                    return reject();
                });
        });
    }

    async complete(modalRef: any) {
        this.setupDeviceLoading = true;
        this.setupVars.detailsVars.disableSubmitBtn = true;
        this.setupVars.detailsVars.formSubmitted = true;
        document.getElementById('deviceSetupForm').classList.add('submitted');
        this.validateFormBasedOnConfig();
        console.log(this.deviceSetupForm.controls);

        if (this.deviceSetupForm.invalid) {
            this.setupVars.detailsVars.disableSubmitBtn = false;
            console.error('Invalid form');
            console.error(this.deviceSetupForm);

            // for debugging purposes
            const getErrors = (key: string): string => {
                if (!this.deviceSetupForm.controls[key].errors) return '';

                let keys = '';
                Object.keys(this.deviceSetupForm.controls[key].errors).forEach(key => {
                    keys += `${key},`;
                });

                return keys.substring(0, keys.length - 1);
            };

            const errObj: object = {};
            for (const key in this.deviceSetupForm.controls) {
                errObj[key] = { field: key, value: this.deviceSetupForm.controls[key].value, errors: getErrors(key) };
            }
            this.setupDeviceLoading = false;
            return;
        }

        this.setupVars.detailsVars.formSubmitted = false;
        document.getElementById('deviceSetupForm').classList.remove('submitted');
        const device: Basic | IncrementPicker | FixedPrice = this.getCompleteDeviceModel();
        // In order to get coin feedback to work, ac detect mode has to be set on all devices where it is installed, if it is not installed and ac detect mode is none, we'll not set the value
        if (
            ((this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Time && this.setupVars.startSignalVars.signal !== Signal.FullDuration) ||
                (this.setupVars.startSignalVars.signal === Signal.FullDuration && !this.isProgramPicked)) &&
            device.ac_detect_mode === 0
        ) {
            device.ac_detect_mode = null;
        }

        if (!this.reconfigValues || (this.reconfigValues && this.reconfigValues.continued_setup)) {
            // Complete function as "create"
            console.log('Creating');
            return this.deviceService
                .createDevice(this.location.id, device, this.uid)
                .then(() => {
                    this.setupDeviceLoading = false;
                    if (this.setupVars.guidVars.anyOpenRelay) {
                        // there is a open relay left
                        this.openModal(modalRef); // opens continueWithNextRelay modal
                    } else {
                        // no open relay left or R1's
                        this.localStorageService.removeItem('reconfigValues');
                        this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}` : `locations/${this.location.id}`], { queryParams: { new_device: true } });
                        this.toast.success(this.translate.instant('device_setup.device_setup_complete'), this.translate.instant('misc.success'));
                    }

                    return;
                })
                .catch(err => {
                    this.setupDeviceLoading = false;
                    this.setupVars.detailsVars.disableSubmitBtn = false;
                });
        } else if (this.reconfigValues && !this.reconfigValues.continued_setup) {
            // Complete function as "reconfig"
            await this.deviceService
                .getProtocolVersion(this.reconfigValues.serial)
                .then((res: any) => {
                    const protocolVersion = res.data;
                    if (protocolVersion && protocolVersion !== '') {
                        this.protocolVersion = protocolVersion;
                    }
                })
                .catch(err => {
                    this.toast.info(`${this.translate.instant('device_setup.fail_protocol_lookup')}`, this.translate.instant('misc.info'));
                });

            device.subscribed = this.reconfigValues.device.subscribed !== undefined ? this.reconfigValues.device.subscribed : null;
            device.insured = this.reconfigValues.device.insured !== undefined ? this.reconfigValues.device.insured : null;
            console.log('Updating');
            return this.deviceService
                .updateDevice(this.location.id, device, this.uid, this.typeOfDevice)
                .then(() => {
                    this.setupDeviceLoading = false;
                    this.localStorageService.removeItem('reconfigValues');
                    this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}` : `locations/${this.location.id}`]);
                    this.toast.success(this.translate.instant('device_setup.device_reconfig'), this.translate.instant('misc.success'));
                    return;
                })
                .catch(err => {
                    this.setupDeviceLoading = false;
                    this.setupVars.detailsVars.disableSubmitBtn = false;
                    console.error(err);
                });
        }
    }

    nextRelay(shouldContinue: boolean) {
        // called from the HTML
        if (shouldContinue) {
            this.modalService.dismissAll();
            this.reconfigValues = {
                serial: this.deviceSetupForm.value.guid,
                password: this.setupVars.guidVars.guidObject.password,
                relay_number: 2,
                continued_setup: true
            };
            this.localStorageService.removeItem('reconfigValues');
            this.localStorageService.setItem('reconfigValues', this.reconfigValues);
            this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}/device_setup/continued` : `locations/${this.location.id}/device_setup/continued`]);
            this.toast.success(`Continuing setup with second relay`, this.translate.instant('misc.success'), { timeOut: 7000 });
        } else {
            this.modalService.dismissAll();
            this.router.navigate([this.subCustomerUid ? `customers/${this.subCustomerUid}/locations/${this.location.id}` : `locations/${this.location.id}`]);
            this.toast.success(this.translate.instant('device_setup.device_setup_complete'), this.translate.instant('misc.success'));
        }
    }

    togglePriceUnit(priceUnit: PriceUnit.Hour | PriceUnit.Minute | PriceUnit.Increment) {
        switch (priceUnit) {
            case PriceUnit.Hour:
                this.overwritePriceUnit = {
                    unit: PriceUnit.Hour,
                    label: this.helperService.getPriceUnit(this.setupVars.productCategoryVars.productType, this.setupVars.pricingModelVars.pricingModel, this.currency, PriceUnit.Hour)
                };
                break;

            case PriceUnit.Minute:
                this.overwritePriceUnit = {
                    unit: PriceUnit.Minute,
                    label: this.helperService.getPriceUnit(this.setupVars.productCategoryVars.productType, this.setupVars.pricingModelVars.pricingModel, this.currency, PriceUnit.Minute)
                };
                break;

            case PriceUnit.Increment:
                this.overwritePriceUnit = {
                    unit: PriceUnit.Increment,
                    label: this.helperService.getPriceUnit(this.setupVars.productCategoryVars.productType, this.setupVars.pricingModelVars.pricingModel, this.currency, PriceUnit.Increment, this.pulse_increment.value)
                };
                break;

            default:
                console.error('Could not set price unit');
                break;
        }
    }

    onAcDetectModeChange() {
        if ((this.setupVars.startSignalVars.pulseOptions === IncrementUnit.Time && this.setupVars.startSignalVars.signal === Signal.Pulse) || this.setupVars.startSignalVars.signal === Signal.FullDuration || this.isProgramPicked) {
            // variable, time, pulse or variable, full duration device
            // then we do not want to show or set fixed_duration
            this.hideFixedDuration = true;
            return;
        }
        this.hideFixedDuration = false;

        // new rule added the 11th of Oct. 2022 with Christian: "Add Busy Signal to Variable"
        // We add a new field labeled "Occupied for" (OF) but really is fixed_duration, and takes 0-180 min. from user input.
        // Logic as follows:
        // if ac_detect_mode === 0
        //      OF enabled = true // user can set the fixed_duration
        // else
        //      fixed_duration = 180
        //      OF enabled = false // hardcode 180 in fixed_duration
        this.showFixedDuration();
        if (this.ac_detect_mode.value > 0) {
            this.fixed_duration.setValue(180);
        }
        if (this.busySignal$) {
            this.fixed_duration.setValue(15);
        } else {
            this.fixed_duration.setValue(null);
        }
        this.fixed_duration.updateValueAndValidity();
    }

    showFixedDuration(): void {
        this.busySignal$.next((this.setupVars.startSignalVars.signal === this.eSignal.UserStartTime || this.setupVars.startSignalVars.signal === this.eSignal.Pulse) && this.ac_detect_mode.value <= 0 ? true : false);
    }

    openModal(modal) {
        const modalOptions: NgbModalOptions = {
            ariaLabelledBy: 'modal-basic-title'
        };
        this.modalService.open(modal, modalOptions);
    }

    restartSetup() {
        location.reload();
    }

    setProgramPicked(res: boolean) {
        this.isProgramPicked = res;
    }

    get brand() {
        return this.deviceSetupForm.get('brand');
    }
    get model() {
        return this.deviceSetupForm.get('model');
    }
    get serial_number() {
        return this.deviceSetupForm.get('serial_number');
    }
    get production_year() {
        return this.deviceSetupForm.get('production_year');
    }
    get note() {
        return this.deviceSetupForm.get('note');
    }
    get coin_drop_installed() {
        return this.deviceSetupForm.get('coin_drop_installed');
    }
    get coin_feedback_enabled() {
        return this.deviceSetupForm.get('coin_feedback_enabled');
    }
    get open_door() {
        return this.deviceSetupForm.get('open_door');
    }
    get guid() {
        return this.deviceSetupForm.get('guid');
    }
    get name() {
        return this.deviceSetupForm.get('name');
    }
    get price() {
        return this.deviceSetupForm.get('price');
    }
    get startup_time() {
        return this.deviceSetupForm.get('startup_time');
    }
    get pulse_duration() {
        return this.deviceSetupForm.get('pulse_duration');
    }
    get pulse_increment() {
        return this.deviceSetupForm.get('pulse_increment');
    }
    get pulse_number() {
        return this.deviceSetupForm.get('pulse_number');
    } // called "Repetition" in the ui
    get fixed_duration() {
        return this.deviceSetupForm.get('fixed_duration');
    }
    get ac_detect_mode() {
        return this.deviceSetupForm.get('ac_detect_mode');
    }
    get machine_programs() {
        return this.deviceSetupForm.get('machine_programs');
    }
}
