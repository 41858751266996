<div class="container mb-5 page-top-margin">
    <div class="search-wrapper">
        <h3>{{ "search.common.search_results" | translate }}: {{ searchQuery }}</h3>
        <div class="mt-4 mb-3" *ngIf="showLoadingIndicator">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
        <div class="list-wrapper" *ngIf="isDataAvailable && !showLoadingIndicator">
            <!-- Each Category group wrapper -->
            <div *ngFor="let category of results | keyvalue">
                <!-- Wrapper for each category-->
                <div [className]="showingResults ? 'search-results-cat remove-margin-bottom' : 'search-results-cat'" *ngIf="results[category.key].length > 0 && isChoosenCategory(category.key)">
                    <div class="d-flex justify-content-between">
                        <h4 *ngIf="isChoosenCategory(category.key)">{{ "misc." + category.key | translate }}</h4>
                        <div class="link-result-wrapper" (click)="view(category.key)" *ngIf="!showingResults">
                            <a class="link-results"
                                >{{ "search.common.view" | translate }}
                                {{ results[category.key].length }}
                                {{ (results[category.key].length === 1 ? "search.common.lowecap_result" : ("search.common.lowecap_results" | translate)) | translate }}</a
                            >
                            <span class="arrow-right"></span>
                        </div>
                    </div>
                    @defer {
                        <app-search-category-result
                            [rates]="rates"
                            [user]=""
                            [categoryResult]="categoryChoosen === category.key ? results[category.key] : previewResults[category.key]"
                            [categoryKey]="category.key"
                            [isMobile]="isMobile"
                            [searchService]="searchService"
                            [slices]="[sliceStart, sliceEnd]"
                        ></app-search-category-result>
                    }

                    <div *ngIf="isChoosenCategory(category.key) && results[category.key].length > 20 && showingResults">
                        {{ category.length }}
                        <mat-paginator id="matpage" class="pagination" (page)="handlePaginate($event)" [length]="results[category.key].length" [pageSize]="20" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex" showFirstLastButtons>
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>

        <!-- No Search Results -->
        <div class="search-no-data" *ngIf="!isDataAvailable && !showLoadingIndicator">
            <img class="search-icon mb-2" src="../../../assets/icons/search-icon-active.svg" />
            <h4>{{ "search.common.no_results" | translate }}</h4>
            <label>{{ "search.common.try_again" | translate }}</label>
        </div>
    </div>
</div>
