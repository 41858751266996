import { Component, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartType, defaults } from 'chart.js';
import { Chart, LinearScale, Tooltip, Title } from 'chart.js';
import moment from 'moment';
import { HelperService } from '../../services/helper/helper.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { EventEmitter } from '@angular/core';
import { TooltipItem } from 'chart.js';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf } from '@angular/common';

Chart.register(LinearScale, Tooltip, Title); // Register the components you are using

defaults.font.family = '"Barlow", sans-serif';
defaults.color = '#03045E';

export interface DataSets {
    last30Days: lineData;
    last7Days: lineData;
    last12Months: lineData;
}

export interface lineData {
    line: { amount: number; chartLabel: string }[];
}

@Component({
    selector: 'app-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss'],
    standalone: true,
    imports: [NgIf, NgChartsModule, LoadingComponent]
})
export class LineComponent {
    @Output()
    doneLoading = new EventEmitter();

    isMobile: boolean;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private helperService: HelperService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
            if (this.isMobile && this.lineChartOptions.scales.x[0] && this.lineChartOptions.scales.x[0]) {
                this.lineChartOptions.scales.x[0].ticks.display = false;
                this.lineChartOptions.scales.y[0].ticks.display = false;
            }
        });
    }

    @ViewChild(BaseChartDirective) chart: BaseChartDirective;

    showLoadingIndicator = true;
    lineChartReady = false;
    today: moment.Moment = moment().startOf('day');
    lineChartLegend = false;
    lineChartType: ChartType = 'line';

    gradient: CanvasGradient = null;
    lineChartData: ChartConfiguration['data'] = {
        datasets: [
            {
                data: [],
                label: 'Total Revenue',
                backgroundColor: context => {
                    const chart = context.chart;
                    const ctx = chart.ctx;
                    const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                    gradient.addColorStop(0, 'rgba(41,225,180,0.4)');
                    gradient.addColorStop(1, 'rgba(41,225,180,0.01)');
                    return gradient;
                },
                borderColor: '#29E1B4',
                pointBackgroundColor: '#29E1B4',
                pointBorderColor: 'white',
                pointHoverBorderWidth: 1,
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#29E1B4',
                pointBorderWidth: 1,
                borderWidth: 2,
                fill: 'origin'
            }
        ]
    };

    plugins: object = {
        annotations: {
            annotation: {
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-0',
                borderWidth: 2
            }
        }
    };

    lineChartOptions: ChartConfiguration['options'] = {
        elements: {
            line: {
                tension: 0.25
            }
        },

        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            ...this.plugins,
            tooltip: {
                callbacks: {
                    label: (context: TooltipItem<ChartType>) => {
                        return `${this.helperService.localizeNumberWithCurrency(context.parsed.y)}`;
                    }
                }
            }
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    maxRotation: 90,
                    minRotation: 90,
                    padding: 8
                }
            },
            y: {
                beginAtZero: true,
                position: 'left',
                ticks: {
                    callback: (value: number) => {
                        if (value !== 0 && value <= 1) {
                            // hide labels if no transaction has been made (this is a fix, if transaction has been done but below 1 kr the label will still not be shown)
                            return '';
                        }
                        return `${this.helperService.localizeNumberWithCurrency(value, 0, 0)}`;
                    }
                }
            }
        }
    };

    setChart(dataSet: { amount: number; chartLabel: string }[]) {
        const labels: string[] = [];
        const amounts: number[] = [];
        dataSet.forEach(data => {
            amounts.push(data.amount / 100);
            labels.push(data.chartLabel);
        });
        this.lineChartData.datasets[0].data = amounts;
        this.lineChartData.labels = labels;

        setTimeout(() => {
            this.lineChartReady = true;
            this.showLoadingIndicator = false;
            this.doneLoading.emit({ value: true });

            if (this.chart) {
                this.chart.update();
            }
        }, 100); // 100ms for better UX
    }
}
