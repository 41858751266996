import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Contract } from '@airwallet/shared-models/operator/contracts';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContractService {
    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) {}

    async getExpiringContracts() {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/contract/overview/expire`))
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getPerformanceContracts(params: string) {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/contract/overview/performance?${params}`))
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getContracts(params: string) {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/contract/all?${params}`))
                .then(res => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getSpecificContractInformation(uid: string) {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/location?uid=${uid}`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async getAllContractInformation() {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(`${environment.baseUrl}/api_operator/location/all`))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async createOrEditContract(contract: Contract, selectedUid: string, isEdit: boolean, fromDate: number, toDate: number) {
        if (isEdit) {
            return await this.editContract(contract, selectedUid, fromDate, toDate);
        } else {
            return await this.createContract(contract, selectedUid, fromDate, toDate);
        }
    }

    async createContract(contract: Contract, selectedUid: string, fromDate: number, toDate: number) {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(`${environment.baseUrl}/api_operator/contract`, { contract, selectedUid, fromDate, toDate }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async editContract(contract: Contract, selectedUid: string, fromDate: number, toDate: number) {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(`${environment.baseUrl}/api_operator/contract`, { contract, selectedUid, fromDate, toDate }))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }

    async deleteContract(selectedUid: string, contract_key: string) {
        return new Promise(async (resolve: any, reject: any) => {
            const options = {
                body: { selectedUid, contract_key }
            };
            return lastValueFrom(this.http.delete(`${environment.baseUrl}/api_operator/contract`, options))
                .then((res: any) => {
                    return resolve(res);
                })
                .catch(err => {
                    return reject(err);
                });
        });
    }
}
