import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AwCheckboxComponent } from '../../misc/aw-checkbox/aw-checkbox.component';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { NgForOf, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AwDatepickerComponent } from '../../misc/aw-datepicker/aw-datepicker.component';
import { DiscountService } from '../../../services/discount/discount.service';
import { HelperService } from '../../../services/helper/helper.service';
import { FilterSortParams, TableSelection } from '@airwallet/shared-models/search-params/FilterSortParams';
import { BillingDiscount } from '@airwallet/shared-models/billing/billing';
import dayjs from 'dayjs';

@Component({
    selector: 'app-discount-create-modal',
    standalone: true,
    imports: [AwCheckboxComponent, CustomModalComponent, LoadingComponent, MatRadioButton, MatRadioGroup, NgForOf, NgIf, ReactiveFormsModule, TranslateModule, AwDatepickerComponent, FormsModule],
    templateUrl: './discount-create-modal.component.html',
    styleUrl: './discount-create-modal.component.scss'
})
export class DiscountCreateModalComponent {
    @Output() discountCreated: EventEmitter<void> = new EventEmitter<void>();
    @Input() totalUnits: number;
    @Input() uid: string;
    @Input() tableSelection: TableSelection;
    @Input() params: FilterSortParams;
    discount: number;
    expireDate: number;
    loading: boolean = false;

    constructor(
        private discountService: DiscountService,
        private helperService: HelperService,
        private translate: TranslateService
    ) {}

    selectedUnitsCount(): number {
        return this.tableSelection.selectAll ? this.totalUnits - this.tableSelection.unselectedUnits.length : this.tableSelection.selectedUnits.length;
    }
    async applyDiscount() {
        this.loading = true;
        const discount: BillingDiscount = {
            percentage: this.discount / 100,
            applied_by: this.helperService.getUser().email,
            created: dayjs().unix(),
            expires_at: this.expireDate ?? null
        };
        await this.discountService
            .createDiscount(this.uid, discount, this.params, this.tableSelection)
            .then(() => {
                this.discountCreated.emit();
                this.helperService.defaultHtmlToast('', this.translate.instant('discount_tool.success_applied'), 'Success');
            })
            .catch(error => {
                console.log(error.error.error);

                if (error.error && error.error.error && error.error.error.units && error.error.error.code === 'expires_before_renew') {
                    this.helperService.defaultHtmlToast('', `${error.error.error.units.length} unit(s) renews after the discount expires and will not have any effect. Please adjust the selected units or expiration date`, 'Error');
                } else {
                    this.helperService.defaultHtmlToast('', 'Unkown error', 'Error');
                }
            });
        this.loading = false;
    }
}
