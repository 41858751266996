import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService, RequestTypes } from '@services/helper/http.service';
import { BillingDiscount, BillingUnit, HoverIconProperties } from '@airwallet/shared-models/billing/billing';
import { FilterSortParams, TableSelection } from '@airwallet/shared-models/search-params/FilterSortParams';
import dayjs from 'dayjs';

@Injectable({
    providedIn: 'root'
})
export class DiscountService {
    constructor(
        private http: HttpService,
        private translate: TranslateService
    ) {}

    async createDiscount(uid: string, discount: BillingDiscount, params: FilterSortParams, tableSelection: TableSelection): Promise<void> {
        return this.http.dynamicHttp(`api_support/discount`, RequestTypes.POST, { body: { params, discount, tableSelection, uid } });
    }
    async deleteDiscount(uid: string, params: FilterSortParams, tableSelection: TableSelection): Promise<{ message: string; unitsChanged: number }> {
        return this.http.dynamicHttp(`api_support/discount`, RequestTypes.DELETE, { body: { params, tableSelection, uid } });
    }
    async globalDiscount(uid: string, discount: BillingDiscount | null): Promise<void> {
        return this.http.dynamicHttp(`api_support/discount/global`, RequestTypes.POST, { body: { discount, uid } });
    }
    async getGlobalDiscount(uid: string): Promise<BillingDiscount | null> {
        return this.http.dynamicHttp(`api_support/discount/global`, RequestTypes.GET, { params: { uid } });
    }

    /**
     * Author: Jesper
     * Green (currently active) - The unit has a disount aplied and it will continue into the next billing period
     * Orange (active until) - The units latest billing period had a discount applied, but it will not continue into the next billing period
     * Blue (will apply) - The unit has new a discount that will be applied in the upcoming billing period
     *          Note to blue: if the units subscription is canceled adjust text to "would have applied" instead of "will apply"
     */
    getHoverIconProperties(unit: BillingUnit, global_discount: number): HoverIconProperties {
        const appliedDiscount: string | null = unit.applied_discount ? displayPercentage(unit.applied_discount.percentage) : null;
        const incomingDiscount: { percentage: string; expire?: number } | null = unit.discount
            ? { percentage: displayPercentage(unit.discount.percentage), expire: unit.discount.expires_at }
            : global_discount
              ? { percentage: displayPercentage(global_discount) }
              : null;
        const discountExpiresBeforeRenew = unit.discount && unit.discount.expires_at ? unit.discount?.expires_at && unit.period_end && unit.period_end > unit.discount.expires_at : false;
        if (!appliedDiscount && !incomingDiscount) {
            // nothing to show
            return {
                hoverText: '-',
                label: '',
                icon: '',
                startColor: '',
                endColor: ''
            };
        } else if (incomingDiscount && incomingDiscount.percentage === appliedDiscount && !unit.canceled && !discountExpiresBeforeRenew) {
            // active
            return {
                hoverText:
                    `${incomingDiscount.percentage}% ${this.translate.instant('discount_tool.currently_active')}` +
                    (incomingDiscount.expire ? ` - ${this.translate.instant('discount_tool.expires')} ${dayjs.unix(incomingDiscount.expire).format('DD/MM/YYYY')}` : ''),
                label: `${incomingDiscount.percentage}%`,
                icon: 'clock-green.svg',
                startColor: '#67C2AC',
                endColor: '#29E1B4'
            };
        } else if (appliedDiscount && (!incomingDiscount || discountExpiresBeforeRenew)) {
            // active until
            return {
                hoverText: `${appliedDiscount}% ${this.translate.instant('discount_tool.active_until')}`,
                label: `${appliedDiscount}%`,
                icon: 'clock-orange.svg',
                startColor: '#FC9124',
                endColor: '#FFB66B'
            };
        } else if (incomingDiscount) {
            // will apply
            return {
                hoverText:
                    `${incomingDiscount.percentage}% ${this.translate.instant(unit.subscribed ? (unit.canceled ? 'discount_tool.would_have_applied' : 'discount_tool.will_apply_next') : 'discount_tool.will_apply')}` +
                    (incomingDiscount.expire ? ` - ${this.translate.instant('discount_tool.expires')} ${dayjs.unix(incomingDiscount.expire).format('DD/MM/YYYY')}` : ''),
                label: `${incomingDiscount.percentage}%`,
                icon: 'clock-blue.svg',
                startColor: '#03045E',
                endColor: '#0150B7'
            };
        } else {
            console.error('unknown unit discount state', unit, global_discount);
        }
    }
}

function displayPercentage(percentage: number): string {
    let display = (percentage * 100).toFixed(2);
    if (display.endsWith('.00')) display = display.slice(0, -3);
    return display;
}
