import { Component, input, InputSignal, OnInit, output, OutputEmitterRef } from '@angular/core';
import { CustomModalComponent } from '@components/misc/custom-modal/custom-modal.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AwFormCreatorComponent } from '../../misc/aw-form-creator/aw-form-creator.component';
import { hubSchemaForm } from '@airwallet/shared-zod/forms/units/hub/hub';
import { HubForm } from '@components/misc/aw-form-creator/form-models/units';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextControl } from '@components/misc/aw-form-creator/aw-forms';
import { HubService } from '@services/hub/hub.service';
import { UnitType } from '@airwallet/shared-models/device';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from '@services/helper/helper.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Claims from '@airwallet/shared-models/claims';
import { AuthService } from '@services/auth/auth.service';
import { LoadingComponent } from '@components/loading/loading.component';
import { AnimatedButtonComponent } from '../../misc/animated-button/animated-button.component';
import { AsyncPipe } from '@angular/common';
import { Hub } from '@airwallet/shared-models/hub/hub';
import { ActivatedRoute } from '@angular/router';

export interface UpdateParams {
    hub: Hub;
    delete: boolean;
    add: boolean;
}

@Component({
    selector: 'app-hub',
    standalone: true,
    imports: [CustomModalComponent, TranslateModule, AwFormCreatorComponent, LoadingComponent, AnimatedButtonComponent, AsyncPipe],
    templateUrl: './hub.component.html',
    styleUrl: './hub.component.scss'
})
export class HubComponent implements OnInit {
    showDeleteHub = false;
    hubSchema = hubSchemaForm;
    hubForm: HubForm;
    user: DashboardUser;
    locationId: InputSignal<string> = input.required();
    hub: InputSignal<Hub> = input();
    updateList: OutputEmitterRef<UpdateParams> = output();
    isCreating = false;
    isDeleting = false;
    isRestarting = false;
    isCustomerOperated: boolean;
    uid: string;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    isSavingChanges = false;
    constructor(
        public modalService: NgbModal,
        private hubService: HubService,
        private helperService: HelperService,
        private toastService: ToastrService,
        private translate: TranslateService,
        public authService: AuthService,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.hubForm = {
            serial_number: new TextControl({
                label: 'location.serial_number',
                value: this.hub() ? this.hub().serial_number : '',
                required: this.hub() ? false : true,
                order: 1,
                type: 'text',
                disabled: this.hub() ? true : false
            })
        };
    }

    async create() {
        this.isCreating = true;
        this.hubService
            .createHub(this.uid, {
                serial_number: this.hubForm.serial_number.value,
                unit_type: UnitType.HUB,
                location_id: this.locationId()
            })
            .then((hub: Hub) => {
                this.toastService.success(this.translate.instant('location.hub_created'), this.translate.instant('misc.success'));
                this.updateList.emit({
                    hub: hub,
                    delete: false,
                    add: true
                });
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);

                if (err.error.code === 'create-hub/already-in-use') {
                    this.toastService.error(this.translate.instant('location.hub_already_in_use'), this.translate.instant('misc.error'));
                } else if (err.error.code === 'create-hub/hub-not-found-in-teltonika') {
                    this.toastService.error(this.translate.instant('location.hub_not_found'), this.translate.instant('misc.error'));
                } else {
                    this.toastService.error(this.translate.instant('location.hub_creation_error'), this.translate.instant('misc.error'));
                }
                console.error('Error creating hub', err);
            })
            .finally(() => {
                this.isCreating = false;
            });
    }

    async deleteHub() {
        this.isDeleting = true;
        this.hubService
            .deleteHub(this.uid, this.hub().serial_number, this.locationId())
            .then(() => {
                this.toastService.success(this.translate.instant('location.hub_deleted'), this.translate.instant('misc.success'));
                this.updateList.emit({
                    hub: this.hub(),
                    delete: true,
                    add: false
                });
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                if (err.error.code === 'delete-hub/hub-not-found') {
                    this.toastService.error(this.translate.instant('location.hub_not_found'), this.translate.instant('misc.error'));
                }

                if (err.error.code === 'delete-hub/invalid_request_body') {
                    this.toastService.error(this.translate.instant('location.hub_could_not_delete'), this.translate.instant('misc.error'));
                }

                console.error('Error deleting hub', err);
            })
            .finally(() => {
                this.isDeleting = false;
            });
    }

    async restartHub() {
        this.isRestarting = true;
        this.hubService
            .restartHub(this.hub().iccid)
            .then(() => {
                this.toastService.success(this.translate.instant('location.hub_restarted'), this.translate.instant('misc.success'));
            })
            .catch((err: HttpErrorResponse) => {
                this.toastService.error(this.translate.instant('location.hub_restart_error'), this.translate.instant('misc.error'));
                console.error('Error restarting hub', err);
            })
            .finally(() => {
                this.isRestarting = false;
            });
    }

    saveChanges() {
        this.isSavingChanges = true;
        this.modalService.dismissAll();
        this.toastService.success(this.translate.instant('location.hub_updated'), this.translate.instant('misc.success'));
        this.isSavingChanges = false;
    }
}
