import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../helper/http.service';
import { DetailedAntonHealth, DetailedIncident } from '@airwallet/shared-models/anton-health';

@Injectable({
    providedIn: 'root'
})
export class AntonHealthServiceService {
    constructor(private httpService: HttpService) {}

    async getAntonHealthData(): Promise<DetailedAntonHealth[]> {
        return this.httpService.dynamicHttp('api_terminal/health', RequestTypes.GET);
    }

    async writeComment(comment: string, serial: string, incidentId: string, isSolution: boolean): Promise<DetailedIncident> {
        return this.httpService.dynamicHttp(`api_terminal/health/${serial}/incidents/${incidentId}/note`, RequestTypes.POST, {
            body: { note: comment, mark_solution: isSolution }
        });
    }

    async markAsSolution(serial: string, incidentId: string, noteId: string, isSolution: boolean): Promise<DetailedIncident> {
        return this.httpService.dynamicHttp(`api_terminal/health/${serial}/incidents/${incidentId}/note/${noteId}`, RequestTypes.PUT, {
            body: { mark_solution: isSolution }
        });
    }
}
