<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ "location.device_order" | translate }}</h4>
    </ng-container>

    <ng-container modal-body>
        <div class="d-column d-justifycontent">
            <form cdkDropList (cdkDropListDropped)="drop($event)">
                <div class="drag-wrapper">
                    <div *ngFor="let unit of orderedUnitArr; let i = index" cdkDrag style="margin-bottom: 1rem">
                        <div class="dragndrop-input">
                            <div class="dragndrop-handle" cdkDragHandle>
                                <div class="dragndrop-dots"></div>
                            </div>
                            <!-- Rest goes here -->
                            <div class="inner-content">
                                <div class="inner-content-name">
                                    <label for="" class="my-1 label-name">{{ unit.name }}</label>
                                </div>
                                <div class="inner-content-price">
                                    <label for="" class="my-1 label-price" *ngIf="unit.price_unit && unit.config !== 'PROGRAM_PICKER'">
                                        <strong>{{ unit.price / 100 }}</strong> {{ unit.currency.toUpperCase() }}{{ cardService.getPriceUnit(unit) }}
                                    </label>
                                    <label for="" class="my1 label-price" *ngIf="unit.config === 'PROGRAM_PICKER'">{{ unit.currency.toUpperCase() }}</label>
                                </div>
                                <div class="inner-content-hover">
                                    <span [className]="i === 0 ? 'fake-arrow' : 'arrow-up'" (click)="i === 0 ? null : moveUp(i)"> </span>
                                    <span [className]="orderedUnitArr.length === i + 1 ? 'fake-arrow' : 'arrow-down'" (click)="orderedUnitArr.length === i + 1 ? null : moveDown(i)"> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

    <ng-container modal-footer>
        <div class="d-grid gap-2">
            <button class="btn btn-secondary" [disabled]="savingDeviceOrdering || !authService.hasLimitedAccess('location_settings', role$ | async) || removingWeight" (click)="saveOrderSettings()">
                {{ savingDeviceOrdering ? "" : ("location.save_settings" | translate) }}
                <loading-indicator *ngIf="savingDeviceOrdering" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
            <a
                tabindex="-1"
                class="center-text"
                *ngIf="authService.hasLimitedAccess('location_settings', role$ | async)"
                (click)="savingDeviceOrdering || removingWeight ? null : resetWeightedDevices()"
                [disabled]="removingWeight || savingDeviceOrdering"
                >{{ "location.reset_order" | translate }}</a
            >
        </div>
    </ng-container>
</app-custom-modal>
