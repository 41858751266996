import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { FireUser, paymentTypesList, UserPaymentMethod } from '@airwallet/shared-models/user-management';
import { UsersService } from 'src/app/services/users/users.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FilterOption, FilterType } from '@airwallet/shared-models/aw-components/filterOption';
import { FilterSortParams, Sort } from '@airwallet/shared-models/search-params/FilterSortParams';
import { TableHeaderOptions } from '@airwallet/shared-models/aw-components/tableHeaderOptions';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { CreateUserModalComponent } from './user-modals/create-user-modal/create-user-modal.component';
import { AwHoverIconComponent } from '../misc/aw-hover-icon/aw-hover-icon.component';
import { AwTableComponent } from '../misc/aw-table/aw-table.component';
import { LoadingComponent } from '../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { AwFilterButtonComponent } from '../misc/aw-filter-button/aw-filter-button.component';
import { PageLayoutComponent } from '../misc/aw-page-layout/page-layout.component';
import { AwExportButtonComponent } from '@components/misc/aw-export-button/aw-export-button.component';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    standalone: true,
    imports: [PageLayoutComponent, AwFilterButtonComponent, NgIf, LoadingComponent, AwTableComponent, NgFor, AwHoverIconComponent, MatPaginator, CreateUserModalComponent, TranslateModule, PaginatePipe, AwExportButtonComponent]
})
export class UsersComponent implements OnInit {
    user: DashboardUser;
    isMobile = false;
    users: FireUser[] = [];
    customersLocationsNames: Record<string, { value: string; label: string }[]> = {};
    locations: { id: string; name: string }[] = [
        {
            id: 'all',
            name: this.translate.instant('transactions.all_locations')
        }
    ];
    isOperator$: Observable<boolean>;
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'lname',
            title: this.translate.instant('users.name'),
            width: '20%'
        },
        {
            sortKey: '',
            title: this.translate.instant('users.phone_no'),
            width: '15%'
        },
        {
            sortKey: 'platform',
            title: this.translate.instant('users.platform'),
            width: '9%'
        },
        {
            sortKey: '',
            title: this.translate.instant('users.payment_method'),
            width: '15%'
        },
        {
            sortKey: 'total_spend_sum',
            title: this.translate.instant('users.total_spend'),
            width: '12%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'payments',
            title: this.translate.instant('users.payments'),
            width: '9%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'refunds_sum',
            title: this.translate.instant('users.refunds'),
            width: '10%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('users.created'),
            width: '10%',
            alignment: 'right',
            sortDirection: 'desc'
        }
    ];

    filterOptions: FilterOption[] = [
        {
            key: 'customer_id',
            type: FilterType.SELECT,
            value: null,
            label: this.translate.instant('misc.customer'),
            selectOptions: [],
            hasDependencyOn: 'location_id'
        },
        {
            key: 'location_id',
            type: FilterType.SELECT,
            value: null,
            label: this.translate.instant('misc.location'),
            selectOptions: []
        },
        {
            key: 'platform',
            type: FilterType.SELECT,
            value: null,
            label: this.translate.instant('users.platform'),
            selectOptions: [
                { label: 'App', value: 'app' },
                { label: 'Terminal', value: 'terminal' }
            ]
        },
        {
            key: 'payment_method',
            type: FilterType.SELECT,
            value: null,
            label: this.translate.instant('billing.payment_method'),
            selectOptions: []
        },
        {
            key: 'created',
            type: FilterType.DATE_RANGE,
            value: null,
            label: this.translate.instant('customers.created'),
            isDateRange: true
        }
    ];

    // Loading states for the component
    loadingUsers = true;
    initialLoading = false;

    // Necessary inputs for FE pagination
    pageSizeOptions = [15, 50, 250];

    params: FilterSortParams = {
        filter: {},
        sortBy: { key: 'created', order: 'desc' },
        pageSize: 15,
        pageNumber: 0,
        action: 'next',
        total: 0
    };

    constructor(
        private modal: NgbModal,
        private breakpoint: BreakpointObserver,
        protected helperService: HelperService,
        private router: Router,
        private usersService: UsersService,
        private translate: TranslateService,
        private authService: AuthService
    ) {
        this.breakpoint.observe(['(max-width: 768px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.initialLoading = true;
        this.user = this.helperService.getUser();
        await this.getCustomersAndLocationNames();
        this.isOperator$ = this.authService.isOperator;
        this.hideCustomerFilterIfNotOperator();
        this.getCustomers(); // fetch all locations for customer
        this.paymentMethods(); // fetch all payment methods
        await this.loadUsers();
        this.initialLoading = false;
    }

    async getCustomersAndLocationNames() {
        await this.usersService.getCustomersAndLocationNames().then(data => {
            this.customersLocationsNames = data.subcustomersLocationNames;
            data.customers.unshift({ value: this.user.uid, label: this.translate.instant('transactions.my_account') });
            this.filterOptions.find(option => option.key === 'customer_id').selectOptions = data.customers;
            console.log(this.customersLocationsNames);
        });
    }

    private async hideCustomerFilterIfNotOperator() {
        const filterOption: FilterOption = this.filterOptions.find(option => option.key === 'customer_id');
        const isOperator: boolean = await this.authService.getOperatorStatus();
        if (!isOperator) {
            // Hide customer field, if not operator
            filterOption.hidden = true;
            filterOption.value = this.user.uid;
            this.handleFilterValueChange({ key: 'location_id', value: this.user.uid });
        }
    }

    handleFilterValueChange(event: Record<string, any>) {
        if (event.key === 'location_id') this.getLocations();
    }

    //#region filter functions
    paymentMethods() {
        const paymentTypes: { label: string; value: string }[] = [];
        paymentTypesList.forEach(paymentType => {
            paymentTypes.push({ label: this.translate.instant(`users.payment_methods.type.${paymentType}`), value: paymentType });
        });
        this.filterOptions.find(option => option.key === 'payment_method').selectOptions = paymentTypes;
    }

    async getCustomers() {
        await this.usersService.getSubcustomerNames().then(data => {
            data.unshift({ value: this.user.uid, label: this.translate.instant('transactions.my_account') });
            this.filterOptions.find(option => option.key === 'customer_id').selectOptions = data;
        });
    }

    async getLocations() {
        const uid: string = this.filterOptions.find(option => option.key === 'customer_id').value;
        if (uid) {
            this.filterOptions.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[uid];
        } else {
            this.filterOptions.find(option => option.key === 'location_id').selectOptions = [];
        }
    }
    //#endregion

    async loadUsers(resetCursors?: boolean): Promise<void> {
        if (resetCursors) {
            this.params.prev_cursor = null;
            this.params.next_cursor = null;
        }
        this.loadingUsers = true;
        this.usersService
            .getUsers(this.params)
            .then(data => {
                this.users = data.users;
                this.params.total = data.total;
                if (data.next_cursor) this.params.next_cursor = data.next_cursor;
                if (data.prev_cursor) this.params.prev_cursor = data.prev_cursor;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.loadingUsers = false;
            });
    }

    convertCardNumber(pm: UserPaymentMethod) {
        if (pm.type === 'visa' || pm.type === 'mastercard') {
            return `• • • • ${pm.last4}`;
        }
    }

    getTitleForWallet(payment_method: UserPaymentMethod['wallet']) {
        if (payment_method.includes('_')) {
            const split = payment_method.split('_');
            const first_letter = split[0].charAt(0).toUpperCase();
            const second_letter = split[1].charAt(0).toUpperCase();
            return `${first_letter}${split[0].slice(1)} ${second_letter}${split[1].slice(1)}`;
        } else {
            return payment_method.charAt(0).toUpperCase() + payment_method.slice(1);
        }
    }

    getUserPaymentDisplayText(userObj: FireUser): string {
        if (userObj.pm === 'test') return 'users.payment_methods.type.test';
        if (userObj.pm && userObj.last4) return `• • • • ${userObj.last4}`;
        return 'users.payment_methods.type.' + userObj.pm.toLowerCase();
    }

    //#region HTML helper functions
    openModal(modalRef: TemplateRef<NgbModalRef>) {
        this.modal
            .open(modalRef, {})
            .result.then(result => {
                console.log('Closed successfully');
            })
            .catch(error => {
                console.log('Dismiss');
            });
    }

    async openUser(userObj: FireUser) {
        this.router.navigate([`users/${userObj.uid}`], { queryParams: { owner_uid: userObj.owner_uid } });
    }

    getLocationName(userObj: FireUser): string {
        if (!this.customersLocationsNames[userObj.owner_uid]) return;
        return this.customersLocationsNames[userObj.owner_uid].find(location => location.value === userObj.location_id)?.label;
    }
    //#endregion

    //#region Table Functions
    async catchFilterChanged(event: any) {
        this.params.filter = event;
        if (!event) this.hideCustomerFilterIfNotOperator();
        this.params.pageNumber = 0;
        this.params.total = 0;
        this.params.prev_cursor = null;
        this.params.next_cursor = null;
        await this.loadUsers();
    }

    async handlePage(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.params.pageSize !== e.pageSize) {
            this.params.pageSize = e.pageSize;
            this.params.pageNumber = 0;
            this.params.prev_cursor = null;
            this.params.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.params.pageNumber = e.pageIndex;
        this.params.pageSize = e.pageSize;
        this.params.action = action;
        await this.loadUsers();
    }

    async catchSortChanged(event: Sort) {
        this.params.pageNumber = 0;
        this.params.sortBy = event;
        this.params.prev_cursor = null;
        this.params.next_cursor = null;
        await this.loadUsers();
    }
    //#endregion
}
