import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Location } from '@airwallet/shared-models/location';
import { Unit } from '@airwallet/shared-models/terminal';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { HelperService } from 'src/app/services/helper/helper.service';
import { TerminalService } from 'src/app/services/terminal/terminal.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as Claims from '@airwallet/shared-models/claims';
import { CardService } from 'src/app/services/location/card/card.service';
import { languages } from '../../../constants/languages';
import { StripeRegions } from '@airwallet/shared-models/stripe';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingComponent } from '../../loading/loading.component';
import { AnimatedButtonComponent } from '../../misc/animated-button/animated-button.component';
import { NgIf, NgFor, AsyncPipe, KeyValuePipe } from '@angular/common';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { CustomToolTipComponent } from '@components/misc/custom-tool-tip/custom-tool-tip.component';
import { AwSwitchComponent } from '../../misc/aw-switch/aw-switch.component';

@Component({
    selector: 'app-terminal-modal',
    templateUrl: './terminal-modal.component.html',
    styleUrls: ['./terminal-modal.component.scss'],
    standalone: true,
    imports: [AwSwitchComponent, CustomModalComponent, NgIf, FormsModule, ReactiveFormsModule, NgFor, AnimatedButtonComponent, LoadingComponent, AsyncPipe, KeyValuePipe, TranslateModule, CustomToolTipComponent, AwSwitchComponent]
})
export class TerminalModalComponent implements OnInit {
    @Input() locationId: string;
    @Input() terminal: Unit;
    @Input() isCustomerOperated: boolean;
    @Input() locations: Record<string, Location>;
    @Input() modalRef: any;
    @Output() deletedTerminalId = new EventEmitter<string>();
    @Output() updatedTerminal = new EventEmitter<Unit>();
    deletingTerminalLoading: boolean;
    restartTerminalLoading: boolean;
    showDeleteTerminal = false;
    updatingTerminal: boolean;
    subCustomerAllowed = true; // IS this real???
    uid: string;
    user: DashboardUser;
    terminalForm: UntypedFormGroup;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    languages: Record<string, string> = languages;
    region: StripeRegions;

    constructor(
        private terminalService: TerminalService,
        private helperService: HelperService,
        private toast: ToastrService,
        public translate: TranslateService,
        public modalService: NgbModal,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public authService: AuthService,
        private cardService: CardService
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.region = this.user.settings.stripe_region;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.setupTerminalForm();
        this.terminal = this.cardService.getTerminal();
        this.terminalForm.patchValue(
            {
                default_language: this.terminal.default_language ? this.terminal.default_language : null,
                booking_enabled: this.terminal.booking_enabled ?? false
            },
            { emitEvent: false }
        );
        this.terminalForm.updateValueAndValidity();
    }

    deleteTerminal() {
        this.deletingTerminalLoading = true;
        this.terminalService
            .deleteTerminal(this.uid, this.locationId, this.terminal.id, this.terminal.uid ?? '')
            .then(() => {
                this.toast.success(this.translate.instant('location.terminal_success_delete'), this.translate.instant('misc.success'));
                this.modalRef.close();
                this.deletingTerminalLoading = false;
                this.showDeleteTerminal = false;
                console.log('deleteing terminal', this.terminal.id);

                this.deletedTerminalId.next(this.terminal.id);
            })
            .catch(() => {
                this.toast.warning(this.translate.instant('location.something_wrong'), this.translate.instant('misc.attention'));
            });
    }

    setupTerminalForm() {
        this.terminalForm = this.fb.group({
            terminal_serial_number: [null, Validators.required],
            default_language: [null, Validators.required],
            booking_enabled: [true, Validators.required]
        });
    }

    // TO-DO - This functions checks should be made on the backend
    checkForDuplicate(str: string): { valid: boolean; message?: string } {
        for (const key in this.locations) {
            if (this.locations[key] && this.locations[key].terminal_readers) {
                for (const readerId in this.locations[key].terminal_readers) {
                    if (this.locations[key].terminal_readers[readerId].serial_number === str) {
                        return { valid: false, message: this.translate.instant('location.serial_in_use') };
                    }
                }
            }
        }

        return { valid: true, message: 'ok' };
    }

    async restartTerminal() {
        this.restartTerminalLoading = true;
        this.terminalService
            .restartTerminal(this.uid, this.locationId, this.terminal.serial_number)
            .then(() => {
                this.toast.success(this.translate.instant('location.success_terminal_restart'), this.translate.instant('misc.success'));
                this.restartTerminalLoading = false;
            })
            .catch((error: HttpErrorResponse) => {
                if (error.error.error === 'Terminal not found' || 'ICCID not found') {
                    this.toast.error(this.translate.instant('location.error_terminal_not_set_up'), this.translate.instant('misc.error'));
                } else {
                    this.toast.error(this.translate.instant('location.error_terminal_restart'), this.translate.instant('misc.error'));
                }
                this.restartTerminalLoading = false;
            });
    }

    updateTerminal(): void {
        this.updatingTerminal = true;
        const details: any = { uid: this.uid, locationId: this.locationId, terminal: { ...this.terminal, ...this.terminalForm.value } };
        this.terminalService
            .updateTerminal(details)
            .then(() => {
                this.updatingTerminal = false;
                this.toast.success(this.translate.instant('location.terminal_success_update'), this.translate.instant('misc.success'));
                this.modalService.dismissAll();
                this.updatedTerminal.next({ ...this.terminal, ...this.terminalForm.value });
            })
            .catch(() => {
                this.updatingTerminal = false;
                this.toast.warning(this.translate.instant('location.something_wrong'), this.translate.instant('misc.attention'));
            });
    }
    toggleBookingEnabled() {
        this.booking_enabled.setValue(!this.booking_enabled.value);
    }
    get booking_enabled() {
        return this.terminalForm.get('booking_enabled');
    }
    get terminal_serial_number() {
        return this.terminalForm.get('terminal_serial_number');
    }
    get default_language() {
        return this.terminalForm.get('default_language');
    }
}
