import { Component, input, InputSignal } from '@angular/core';
import { CustomToolTipComponent } from '../../custom-tool-tip/custom-tool-tip.component';
import { FormBase } from '../aw-forms';
import { TranslateModule } from '@ngx-translate/core';
@Component({
    selector: 'app-aw-form-label',
    standalone: true,
    imports: [CustomToolTipComponent, TranslateModule],
    templateUrl: './aw-form-label.component.html',
    styleUrl: './aw-form-label.component.scss'
})
export class AwFormLabelComponent<T> {
    control: InputSignal<FormBase<T>> = input.required();
    size: InputSignal<'sm' | 'lg'> = input('lg');
}
