import { AwDotPopupButtonComponent } from '../../misc/aw-dot-popup-button/aw-dot-popup-button.component';
import { AwCheckboxComponent } from '../../misc/aw-checkbox/aw-checkbox.component';
import { AwHoverIconComponent } from '../../misc/aw-hover-icon/aw-hover-icon.component';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { AwFilterButtonComponent } from '../../misc/aw-filter-button/aw-filter-button.component';
import { PageLayoutComponent } from '../../misc/aw-page-layout/page-layout.component';
import { AwExportButtonComponent } from '@components/misc/aw-export-button/aw-export-button.component';
import { LoadingComponent } from '../../loading/loading.component';
import { PageEvent } from '@angular/material/paginator';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgIf, NgFor, NgStyle } from '@angular/common';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth/auth.service';
import { BillingSubscriptionModalComponent } from './billing-subscription-modal/billing-subscription-modal.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { DiscountService } from '../../../services/discount/discount.service';
import { BillingService } from '../../../services/billing/billing.service';
import { HelperService } from '../../../services/helper/helper.service';
import { DashboardUser } from '../../../../dashboard-models/dashboard-user';
import { BillingDetails, DisplayBillingUnit } from '@airwallet/shared-models/billing/billing';
import { TableHeaderOptions } from '@airwallet/shared-models/aw-components/tableHeaderOptions';
import { FilterOption, FilterType } from '@airwallet/shared-models/aw-components/filterOption';
import { FilterSortParams, Sort } from '@airwallet/shared-models/search-params/FilterSortParams';
import { ToastrService } from 'ngx-toastr';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { BillingCardComponent } from '@components/settings/billing/billing-card-modal/billing-card.component';

@Component({
    selector: 'app-billing-new',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss'],
    standalone: true,
    imports: [
        PageLayoutComponent,
        NgIf,
        AwFilterButtonComponent,
        LoadingComponent,
        RouterLink,
        AwTableComponent,
        NgFor,
        CustomToolTipComponent,
        AwHoverIconComponent,
        AwDotPopupButtonComponent,
        NgStyle,
        AwCheckboxComponent,
        BillingSubscriptionModalComponent,
        TranslateModule,
        AwExportButtonComponent,
        CustomModalComponent,
        BillingCardComponent
    ]
})
export class BillingComponent implements OnInit {
    @ViewChild(BillingCardComponent) billingCardComponent: BillingCardComponent;

    protected view = 'billing.inactive';
    protected isMobile = false;

    isOperator = false;
    modalRef: NgbModalRef;
    user: DashboardUser = this.helperService.getUser();
    billingDetails: BillingDetails;
    dropdownComponents: AwDotPopupButtonComponent[] = [];
    globalDiscount: number = 0;
    selectAll: boolean;
    unselectedUnits: string[]; // used when all is selected
    selectedUnits: string[];
    customersLocationsNames: Record<string, { value: string; label: string }[]> = {};
    customers: { value: string; label: string }[] = [];
    activeBillingUnits: DisplayBillingUnit[] = [];
    inactiveBillingUnits: DisplayBillingUnit[] = [];
    tableHeaderOptions: TableHeaderOptions[];
    tableHeaderOptionsInactive: TableHeaderOptions[];
    defaultColumnWidthInactive: number[] = [15, 15, 20, 20, 15, 10, 5];
    defaultColumnWidthActive: number[] = [15, 15, 20, 20, 15, 10, 5];
    filterOptionsInactive: FilterOption[] = [
        {
            key: 'customer_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.customer',
            selectOptions: [],
            hasDependencyOn: 'location_id'
        },
        {
            key: 'location_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.location',
            selectOptions: []
        },
        {
            key: 'unit_type',
            type: FilterType.SELECT,
            value: null,
            label: 'billing.unit_types',
            selectOptions: [
                { value: 'WASHER', label: this.translate.instant('billing.washer') },
                { value: 'DRYER', label: this.translate.instant('billing.dryer') },
                { value: 'OTHER', label: this.translate.instant('billing.other') },
                { value: 'TERMINAL', label: this.translate.instant('billing.terminal') }
            ]
        },
        { key: 'created', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'customers.created' }
    ];
    filterOptionsActive: FilterOption[] = [
        {
            key: 'customer_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.customer',
            selectOptions: [],
            hasDependencyOn: 'location_id'
        },
        {
            key: 'location_id',
            type: FilterType.SELECT,
            value: null,
            label: 'misc.location',
            selectOptions: []
        },
        {
            key: 'unit_type',
            type: FilterType.SELECT,
            value: null,
            label: 'billing.unit_types',
            selectOptions: [
                { value: 'WASHER', label: this.translate.instant('billing.washer') },
                { value: 'DRYER', label: this.translate.instant('billing.dryer') },
                { value: 'OTHER', label: this.translate.instant('billing.other') },
                { value: 'TERMINAL', label: this.translate.instant('billing.terminal') }
            ]
        },
        {
            key: 'subscribed',
            type: FilterType.SELECT,
            value: null,
            label: 'transactions.status',
            selectOptions: [
                { value: 'canceled', label: 'billing.canceled' },
                { value: 'active', label: 'billing.active' }
            ]
        },
        { key: 'period_start', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'billing.activation_date' }
    ];
    paramsForActive: FilterSortParams;
    paramsForInactive: FilterSortParams;

    //Loading variables
    dotButtonLoadingIndex: string | null = null;
    loadingActiveBilling: boolean = false;
    loadingInactiveBilling: boolean = false;
    initLoading: boolean = true;
    vatNumberIsPending: boolean = false;
    savingCard: boolean = false;

    constructor(
        private modalService: NgbModal,
        private billingService: BillingService,
        private helperService: HelperService,
        private router: Router,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private authService: AuthService,
        private discountService: DiscountService,
        private toast: ToastrService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    //#region init-functions
    async ngOnInit() {
        this.initLoading = true;
        this.initFilterParams();
        this.isOperator = await this.authService.getOperatorStatus();
        await this.setDetails();
        await Promise.all([this.loadActiveBilling(), this.loadInactiveBilling(), this.getCustomersAndLocationNames()]);
        await this.hideCustomerFilterIfNotOperator();
        this.initLoading = false;
    }

    private async hideCustomerFilterIfNotOperator() {
        if (!this.isOperator) {
            const filterOptionActive: FilterOption = this.filterOptionsActive.find(option => option.key === 'customer_id');
            filterOptionActive.hidden = true;
            filterOptionActive.value = this.user.uid;
            this.filterOptionsActive.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[this.user.uid];

            const filterOptionInactive: FilterOption = this.filterOptionsInactive.find(option => option.key === 'customer_id');
            filterOptionInactive.hidden = true;
            filterOptionInactive.value = this.user.uid;
            this.filterOptionsInactive.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[this.user.uid];
        }
    }

    initFilterParams() {
        this.paramsForInactive = {
            filter: {},
            sortBy: { key: 'created', order: 'desc' },
            pageSize: 15,
            pageNumber: 0,
            total: 0
        };
        this.paramsForActive = {
            filter: {},
            sortBy: { key: 'activated', order: 'desc' },
            pageSize: 15,
            pageNumber: 0,
            total: 0
        };
        this.selectAll = false;
        this.unselectedUnits = [];
        this.selectedUnits = [];
    }

    async setDetails() {
        await this.billingService.getCustomerDetails().then(customer => {
            if (customer.onboardingStateActive) {
                if (!customer.details.vat_number_details && !customer.details.vat_number_not_provided && customer.shallowAccountDetails.business_type !== 'individual') {
                    this.router.navigate([`account/update_vat`]);
                } else if (customer.details.vat_number_details?.verification.status === 'pre_pending' || customer.details.vat_number_details?.verification.status === 'pending') {
                    this.vatNumberIsPending = true;
                } else {
                    this.vatNumberIsPending = false;
                }
                this.globalDiscount = customer.globalDiscount?.percentage ?? 0;
            }
            this.billingDetails = customer;
        });
        this.setTableHeaderOptions();
        // Reorder the table headers for the inactive table on mobile so that checkbox is in front instead of last
        if (this.isMobile) this.tableHeaderOptionsInactive.unshift(this.tableHeaderOptionsInactive.pop());
    }
    //#endregion

    //#region filter options
    async getCustomersAndLocationNames() {
        await this.billingService.getCustomersAndLocationNames().then(data => {
            this.customersLocationsNames = data.subcustomersLocationNames;
            data.customers.unshift({ value: this.user.uid, label: this.translate.instant('transactions.my_account') });
            this.customers = data.customers;
            this.filterOptionsActive.find(option => option.key === 'customer_id').selectOptions = data.customers;
            this.filterOptionsInactive.find(option => option.key === 'customer_id').selectOptions = data.customers;
        });
    }

    handleFilterValueChange(event: Record<string, any>) {
        if (event.key === 'location_id') {
            if (this.view === 'billing.active') this.filterOptionsActive.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[event.value];
            if (this.view === 'billing.inactive') this.filterOptionsInactive.find(option => option.key === 'location_id').selectOptions = this.customersLocationsNames[event.value];
        }
    }

    getLocationName(unit: DisplayBillingUnit): string {
        if (!this.customersLocationsNames[unit.owner_uid]) return 'Not found';
        return this.customersLocationsNames[unit.owner_uid].find(location => location.value === unit.location_id)?.label;
    }
    getCustomerName(unit: DisplayBillingUnit): string {
        return this.customers.find(customer => customer.value === unit.owner_uid)?.label;
    }

    catchSortChanged(event: Sort) {
        if (this.view === 'billing.active') {
            this.paramsForActive.pageNumber = 0;
            this.paramsForActive.sortBy = event;
            this.paramsForActive.prev_cursor = null;
            this.paramsForActive.next_cursor = null;
            this.loadActiveBilling();
        } else {
            this.paramsForInactive.pageNumber = 0;
            this.paramsForInactive.sortBy = event;
            this.paramsForInactive.prev_cursor = null;
            this.paramsForInactive.next_cursor = null;
            this.loadInactiveBilling();
        }
    }

    catchFilterChanged(event: any) {
        if (this.view === 'billing.active') {
            this.paramsForActive.pageNumber = 0;
            this.paramsForActive.total = 0;
            this.paramsForActive.filter = event;
            this.paramsForActive.prev_cursor = null;
            this.paramsForActive.next_cursor = null;
            this.loadActiveBilling();
        } else {
            this.paramsForInactive.pageNumber = 0;
            this.paramsForInactive.total = 0;
            this.paramsForInactive.filter = event;
            this.paramsForInactive.prev_cursor = null;
            this.paramsForInactive.next_cursor = null;
            this.loadInactiveBilling();
        }
    }
    //#endregion

    //#region Pagination
    async handlePageActive(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.paramsForActive.pageSize !== e.pageSize) {
            this.paramsForActive.pageSize = e.pageSize;
            this.paramsForActive.pageNumber = 0;
            this.paramsForActive.prev_cursor = null;
            this.paramsForActive.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.paramsForActive.pageNumber = e.pageIndex;
        this.paramsForActive.pageSize = e.pageSize;
        this.paramsForActive.action = action;
        await this.loadActiveBilling();
    }

    async handlePageInactive(e: PageEvent) {
        let action: 'next' | 'prev' | 'first' | 'last' = 'next';
        if (this.paramsForInactive.pageSize !== e.pageSize) {
            this.paramsForInactive.pageSize = e.pageSize;
            this.paramsForInactive.pageNumber = 0;
            this.paramsForInactive.prev_cursor = null;
            this.paramsForInactive.next_cursor = null;
        } else {
            if (e.pageIndex - 1 === e.previousPageIndex) action = 'next';
            if (e.pageIndex + 1 === e.previousPageIndex) action = 'prev';
            if (e.pageIndex < e.previousPageIndex - 1) action = 'first';
            if (e.pageIndex > e.previousPageIndex + 1) action = 'last';
        }
        this.paramsForInactive.pageNumber = e.pageIndex;
        this.paramsForInactive.pageSize = e.pageSize;
        this.paramsForInactive.action = action;
        await this.loadInactiveBilling();
    }
    // #endregion

    //#region Load billing units
    async loadActiveBilling(): Promise<void> {
        this.loadingActiveBilling = true;
        this.paramsForActive.filter.list = 'active';
        await this.billingService
            .getBillingUnits(this.paramsForActive)
            .then(data => {
                const tmpUnits: DisplayBillingUnit[] = [];
                data.units.forEach(unit => {
                    tmpUnits.push({
                        ...unit,
                        ...this.discountService.getHoverIconProperties(unit, this.globalDiscount),
                        isChecked: false
                    });
                });
                this.activeBillingUnits = tmpUnits;
                this.paramsForActive.total = data.total;
                if (data.next_cursor) this.paramsForActive.next_cursor = data.next_cursor;
                if (data.prev_cursor) this.paramsForActive.prev_cursor = data.prev_cursor;
            })
            .catch(error => {
                console.error(error);
            });
        this.loadingActiveBilling = false;
    }

    async loadInactiveBilling(): Promise<void> {
        this.loadingInactiveBilling = true;
        this.paramsForInactive.filter.list = 'inactive';
        await this.billingService
            .getBillingUnits(this.paramsForInactive)
            .then(data => {
                const tmpUnits: DisplayBillingUnit[] = [];
                data.units.forEach(unit => {
                    tmpUnits.push({
                        ...unit,
                        ...this.discountService.getHoverIconProperties(unit, this.globalDiscount),
                        isChecked: this.selectAll ? !this.unselectedUnits.includes(unit.serial) : this.selectedUnits.includes(unit.serial)
                    });
                });
                this.inactiveBillingUnits = tmpUnits;
                this.paramsForInactive.total = data.total;
                if (data.next_cursor) this.paramsForInactive.next_cursor = data.next_cursor;
                if (data.prev_cursor) this.paramsForInactive.prev_cursor = data.prev_cursor;
            })
            .catch(error => {
                console.error(error);
            });
        this.loadingInactiveBilling = false;
    }
    //#endregion

    //#region Unit selection
    toggleUnitSelection(unit: DisplayBillingUnit, isChecked: boolean): void {
        unit.isChecked = isChecked;
        if (isChecked) {
            if (!this.selectAll) this.selectedUnits.push(unit.serial);
            this.unselectedUnits = this.unselectedUnits.filter(serial => serial !== unit.serial);
        } else {
            this.selectedUnits = this.selectedUnits.filter(serial => serial !== unit.serial);
            if (this.selectAll) {
                this.unselectedUnits.push(unit.serial);
            }
        }
        if (this.selectAll && this.unselectedUnits.length === this.inactiveBillingUnits.length) this.selectAll = false;
        if (this.selectedUnits.length === this.paramsForInactive.total) {
            this.selectAll = true;
            this.selectedUnits = [];
            this.unselectedUnits = [];
        }
        if (this.unselectedUnits.length === this.paramsForInactive.total) {
            this.selectAll = false;
            this.selectedUnits = [];
            this.unselectedUnits = [];
        }
    }

    selectAllUnits(isChecked: boolean): void {
        this.selectAll = isChecked;
        for (const unit of this.inactiveBillingUnits) {
            unit.isChecked = isChecked;
        }
        this.selectedUnits = [];
        this.unselectedUnits = [];
    }
    //#endregion

    getMenuOptions(unit: DisplayBillingUnit): string[] {
        return [unit.canceled ? 'billing.un_do_cancel' : 'billing.cancel'];
    }

    openInvoices() {
        window.open(this.billingDetails.invoicePortalUrl, '_blank');
    }

    saveCard() {
        this.savingCard = true;
        this.billingCardComponent
            .saveCard()
            .then(() => {
                this.modalRef.dismiss('all');
            })
            .catch(() => {
                this.toast.error(this.translate.instant('billing.card_unsuccessfully_updated'), this.translate.instant('misc.attention'));
            });
        this.savingCard = false;
    }

    async catchDotMenuClicked(event: string, unit: DisplayBillingUnit, index: number) {
        this.dotButtonLoadingIndex = unit.serial;
        await this.billingService
            .cancelSubscription(unit.serial, event === 'billing.cancel', unit.owner_uid)
            .then(() => {
                this.loadActiveBilling();
            })
            .catch(error => {
                console.error(error);
            });
        this.dotButtonLoadingIndex = null;
    }

    onDropdownOpened(openDropdown: AwDotPopupButtonComponent): void {
        this.dropdownComponents.forEach(dropdown => {
            if (dropdown !== openDropdown) dropdown.closeDropdown();
        });
    }

    async openBillingSubscriptionModal(modal: NgbModalModule) {
        if (this.vatNumberIsPending) {
            await this.setDetails();
            if (this.vatNumberIsPending) {
                this.toast.info(this.translate.instant('billing.vat_number_is_pending'), this.translate.instant('misc.attention'));
                return;
            }
        }
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });
    }

    async setTableHeaderOptions() {
        if (this.billingDetails.hasDiscount) {
            this.defaultColumnWidthInactive = [15, 15, 15, 15, 10, 10, 5];
            this.defaultColumnWidthActive = [15, 15, 15, 15, 10, 10, 5];
        }

        this.tableHeaderOptions = [
            {
                sortKey: 'serial',
                title: this.translate.instant('device.serial_num'),
                width: this.defaultColumnWidthInactive[0] + '%'
            },
            {
                sortKey: '',
                title: !this.isOperator ? '' : this.translate.instant('misc.customer'),
                width: this.defaultColumnWidthInactive[1] + '%'
            },
            {
                sortKey: '',
                title: this.translate.instant('misc.location'),
                width: this.defaultColumnWidthInactive[2] + '%'
            },
            {
                sortKey: 'lname',
                title: this.translate.instant('misc.device'),
                width: this.defaultColumnWidthInactive[3] + '%'
            },
            {
                sortKey: '',
                title: this.translate.instant('transactions.status'),
                width: this.defaultColumnWidthInactive[4] + '%'
            },
            {
                sortKey: 'activated',
                title: this.translate.instant('billing.activated'),
                width: this.defaultColumnWidthInactive[5] + '%',
                alignment: 'right'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[6] + '%',
                alignment: 'right'
            }
        ];

        this.tableHeaderOptionsInactive = [
            {
                sortKey: 'serial',
                title: this.translate.instant('device.serial_num'),
                width: this.defaultColumnWidthInactive[0] + '%'
            },
            {
                sortKey: '',
                title: !this.isOperator ? '' : this.translate.instant('misc.customer'),
                width: this.defaultColumnWidthInactive[1] + '%'
            },
            {
                sortKey: '',
                title: this.translate.instant('misc.location'),
                width: this.defaultColumnWidthInactive[2] + '%'
            },
            {
                sortKey: 'lname',
                title: this.translate.instant('misc.device'),
                width: this.defaultColumnWidthInactive[3] + '%'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[4] + '%'
            },
            {
                sortKey: 'created',
                title: this.translate.instant('customers.created'),
                width: this.defaultColumnWidthInactive[5] + '%',
                alignment: 'right'
            },
            {
                sortKey: '',
                title: '',
                width: this.defaultColumnWidthInactive[6] + '%',
                alignment: 'right'
            }
        ];

        if (this.billingDetails.hasDiscount) {
            const discountColumn = {
                sortKey: '',
                title: this.translate.instant('billing.discount'), // Assuming you have a translation key for 'discount'
                sortDirection: 'desc' as 'desc',
                width: '15%'
            };
            // Inserting the discount column after the 'device_name' column
            const deviceNameIndex = this.tableHeaderOptionsInactive.findIndex(option => option.sortKey === 'lname');
            this.tableHeaderOptionsInactive.splice(deviceNameIndex + 1, 0, discountColumn);
            // Inserting the discount column after the 'device_name' column
            const statusIndex = this.tableHeaderOptions.findIndex(option => option.title === this.translate.instant('transactions.status'));
            this.tableHeaderOptions.splice(statusIndex + 1, 0, discountColumn);
        }
    }
}
