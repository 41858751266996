import { z } from 'zod';
///  Validators.pattern('[- +()0-9]+')
import { phoneNumberCountryData } from '../../../shared_data/phone_number_data';

export const phoneSchema = z
    .object({
        phone_number_call_code: z.string().min(1),
        phone_number: z.string().regex(/[- +()0-9]+/)
    })
    .superRefine((data, ctx) => {
        const cc = data.phone_number_call_code.split('-')[0].trim();
        const country = phoneNumberCountryData.find(country => {
            if (country.callCode.includes(cc)) {
                return true;
            }
        });
        if (country) {
            console.log(country);
            if (data.phone_number.length < country.phLengthMin) {
                ctx.addIssue({
                    code: z.ZodIssueCode.too_small,
                    minimum: country.phLengthMin,
                    message: 'zod.errors.phone_number.min_length',
                    type: 'string',
                    inclusive: true,
                    path: ['phone_number']
                });
            } else if (data.phone_number.length > country.phLengthMax) {
                ctx.addIssue({
                    code: z.ZodIssueCode.too_big,
                    maximum: country.phLengthMax,
                    message: 'zod.errors.phone_number.max_length',
                    type: 'string',
                    inclusive: true,
                    path: ['phone_number']
                });
            }
        }
    });

export type ValidatePhoneZod = Required<z.infer<typeof phoneSchema>>;

export const emailSchema = z.object({
    email: z.string().email()
});

export type ValidateEmailZod = Required<z.infer<typeof emailSchema>>;
