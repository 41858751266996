import { Directive, ElementRef, input, InputSignal, OnInit } from '@angular/core';
import { FormBase } from '../aw-forms';
@Directive({
    selector: '[appControl]',
    standalone: true
})
export class ControlDirective<T> implements OnInit {
    appControl: InputSignal<FormBase<T>> = input();
    constructor(private el: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        this.setEventsOnControls(this.appControl(), this.el);
    }

    private setEventsOnControls(events: FormBase<T>, el: ElementRef<HTMLElement>) {
        for (const key in events) {
            if (Object.hasOwn(events, key)) {
                el.nativeElement.addEventListener(key, events[key]);
            }
        }
    }
}
