<div class="location-hr-wrapper">
    <div class="custom-location-hr">
        <span>{{ "misc.terminals" | translate }}</span>
    </div>
</div>

<!-- LOADING -->
<div *ngIf="showLoadingIndicator" class="loading-height">
    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
</div>

<div *ngIf="!showLoadingIndicator">
    <div *ngIf="hasTerminals" class="card-list">
        <div *ngFor="let unit of terminalList">
            <div class="card-item" (click)="cardClickAction(unit, terminalModal, '')">
                <table>
                    <tr class="card-item-title-area">
                        <td colspan="2" style="width: 100%">
                            <div class="wrapper">
                                <div class="title">Anton terminal</div>
                                <div class="subscription-state mobile">
                                    <img [src]="getTerminalStateIcon(unit.maintenance, unit.subscribed)" [alt]="getTerminalStateText(unit.maintenance, unit.subscribed)" />
                                    <span [class]="unit.maintenance ? 'maintenance' : unit.subscribed === undefined ? 'active' : unit.subscribed ? 'active' : 'inactive'">
                                        {{ getTerminalStateText(unit.maintenance, unit.subscribed) }}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="">
                            <hr class="customized" />
                        </td>
                    </tr>

                    <tr>
                        <td class="label">{{ "location.serial_num" | translate }}:</td>
                        <td class="value">{{ unit.serial_number }}</td>
                    </tr>

                    <tr *ngIf="authService.hasLimitedAccess('device_stats', role$ | async)">
                        <td class="label">{{ "location.last_thirty" | translate }}:</td>
                        <td class="value">{{ unit.stats.terminalStarts }} {{ "location.payments" | translate }}</td>
                    </tr>

                    <tr>
                        <td>
                            <!-- empty -->
                        </td>
                    </tr>

                    <!-- Both devices and terminals -->
                    <tr>
                        <td class="subscription-state desktop">
                            <img [src]="getTerminalStateIcon(unit.maintenance, unit.subscribed)" [alt]="getTerminalStateText(unit.maintenance, unit.subscribed)" />
                            <span [class]="unit.maintenance ? 'maintenance' : unit.subscribed === undefined ? 'active' : unit.subscribed ? 'active' : 'inactive'">
                                {{ getTerminalStateText(unit.maintenance, unit.subscribed) }}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="!hasTerminals" class="empty-page-list">
        <p>{{ "location.no_terminals_on_location" | translate }}</p>
    </div>
</div>

<ng-template #terminalModal let-modal>
    <app-terminal-modal
        [isCustomerOperated]="isCustomerOperated"
        [modalRef]="modalRef"
        [locationId]="locId"
        [terminal]="selectedTerminal"
        (deletedTerminalId)="onDeletedTerminal($event)"
        (updatedTerminal)="onUpdatedTerminal($event)"
    ></app-terminal-modal>
</ng-template>
