import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Device } from '@airwallet/shared-models/operator/allDevices';
import { Router } from '@angular/router';
import moment from 'moment/moment';
import { HelperService } from '../../../../services/helper/helper.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-specific-device',
    templateUrl: './specific-device.component.html',
    styleUrls: ['./specific-device.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, TranslateModule]
})
export class SpecificDeviceComponent implements OnInit {
    @Input() device: Device;
    constructor(
        public modalService: NgbModal,
        private router: Router,
        public helperService: HelperService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        moment.locale(this.translate.currentLang);
    }

    getLink(customerUid: string, locationKey?: string, deviceKey?: string): void {
        this.modalService.dismissAll('Cancel');
        let link = '/locations';
        if (customerUid.includes('operated_by')) {
            const extrapolatedUid: string = customerUid.substring(0, customerUid.indexOf('_'));
            link = `customers/${extrapolatedUid}/locations`;
        }
        if (locationKey) {
            link = link.concat(`/${locationKey}`);
            if (deviceKey) {
                link = link.concat(`/devices/${deviceKey}`);
            }
        }
        this.router.navigate([link]);
    }

    getPeriod(milsStamp: number) {
        return milsStamp ? moment.tz(moment(milsStamp, 'X'), 'UTC').tz(moment.tz.guess(false)).format('L').replace(/([.])/g, '/') : ' ';
    }

    round(num: number | null): string {
        return num ? `${this.helperService.roundToTwoDecimals(num)}` : '0.00';
    }
}
