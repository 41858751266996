export interface FilterOption {
    key: string;
    label: string;
    type: FilterType;
    clearable?: boolean; // Default is true. Used for selectoptions where you dont want to be able to clear value.
    hidden?: boolean;
    numberRangeKey?: 'BETWEEN' | 'EQUAL' | 'GREATER' | 'LESS';
    value: any; // Cannot be typed, as this value can be of any type, e.g. string, string[], number, boolean, etc.
    value2?: any; // Only applicable for date ranges
    isDateRange?: boolean;
    hasDependencyOn?: string;
    selectOptions?: SelectOption[];
    placeholder?: string;
    disabled?: boolean;
}

export enum FilterType {
    SELECT = 'SELECT',
    SELECT_MULTIPLE = 'SELECT_MULTIPLE',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    NUMBER_RANGE = 'NUMBER_RANGE',
    DATE = 'DATE',
    DATE_RANGE = 'DATE_RANGE',
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO'
}

export interface SelectOption {
    value: any; // Cannot be typed, as this value can be of any type, e.g. string, string[], number, boolean, etc.
    label: string;
}
