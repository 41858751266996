import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helper/helper.service';
import { CardService } from '../../../services/location/card/card.service';
import { LocationComponent } from '../location.component';
import { ActivatedRoute } from '@angular/router';
import * as Claims from '@airwallet/shared-models/claims';
import { Unit } from '@airwallet/shared-models/terminal';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TerminalModalComponent } from '../terminal-modal/terminal-modal.component';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-terminal-cards-list',
    templateUrl: './terminal-cards-list.component.html',
    styleUrls: ['./terminal-cards-list.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, NgFor, TerminalModalComponent, AsyncPipe, TranslateModule]
})
export class TerminalCardsListComponent implements OnInit {
    @Input() locId: string;
    @Input() modalRef: any;
    // @Input() newTerminal: BehaviorSubject<Unit> = new BehaviorSubject<Unit>(null);
    @Input() inputTerminalList: BehaviorSubject<Unit[]> = new BehaviorSubject<Unit[]>(null);

    showLoadingIndicator = true;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    user: DashboardUser;
    terminalList: Unit[];
    terminalRecord: Record<string, Unit>;
    isCustomerOperated: boolean;
    hasTerminals: boolean;
    uid: string;
    selectedTerminal: Unit;

    constructor(
        private helperService: HelperService,
        public cardService: CardService,
        public authService: AuthService,
        private locationComp: LocationComponent,
        private route: ActivatedRoute,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.inputTerminalList.subscribe(value => {
            if (!value) return;
            this.updateList(value);
            this.showLoadingIndicator = false;
        });
    }

    getTerminalStateIcon(maintenance: boolean, subscribed: boolean): string {
        const maintenanceIcon = '../../../assets/icons/maintenance-icon.svg';
        const checkIcon = '../../../assets/icons/check-circle.svg';
        const crossIcon = '../../../assets/icons/cross.svg';
        if (maintenance) {
            return maintenanceIcon;
        } else {
            return subscribed ? checkIcon : crossIcon;
        }
    }

    getTerminalStateText(maintenance: boolean, subscribed: boolean): string {
        if (maintenance) {
            return this.translate.instant('location.maintenance');
        } else if (subscribed === undefined) {
            return this.translate.instant('location.active');
        }
        return this.translate.instant(subscribed ? 'location.active' : 'location.inactive');
    }

    cardClickAction(unit: Unit, modal: any, modalTemplateString: string) {
        this.cardService.setTerminal(unit);
        this.selectedTerminal = unit;
        this.locationComp.selectedTerminal(modal, modalTemplateString);
    }

    updateList(list: Unit[]) {
        this.terminalList = list;
        this.hasTerminals = list.length === 0 ? false : true;
    }

    onDeletedTerminal(terminalId: string) {
        this.inputTerminalList.next(this.inputTerminalList.value.filter(terminal => terminal.id !== terminalId));
    }
    onUpdatedTerminal(unit: Unit) {
        this.inputTerminalList.next(this.inputTerminalList.value.map(terminal => (terminal.id === unit.id ? unit : terminal)));
    }
}
