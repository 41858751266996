import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CouponService } from '@services/settings/coupon/coupon.service';
import { Location as LocationNavigation, NgClass, NgIf, SlicePipe, UpperCasePipe } from '@angular/common';
import moment from 'moment-timezone';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Datepicker } from 'vanillajs-datepicker';
import { LookUpDetails } from '@airwallet/shared-models/transactions';
import { HelperService } from '@services/helper/helper.service';
import { DashboardUser } from '@dashboard_models/dashboard-user';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Coupon, CreateCouponParams, CustomerDisplay, CustomerLocations, DiscountType, DistributeType, DurationDetails, DurationType, Event, HappyHours, TriggerType, Weekdays } from '@airwallet/shared-models/coupon';
import { Calendar, DateSelectArg, EventChangeArg, EventClickArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomModalComponent } from '@components/misc/custom-modal/custom-modal.component';
import { LoadingComponent } from '@components/loading/loading.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { CustomToolTipComponent } from '@components/misc/custom-tool-tip/custom-tool-tip.component';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { AwCalendarLayoutComponent } from '@components/misc/aw-calendar-layout/aw-calendar-layout.component';
import dayjs from 'dayjs';
import { Week } from '@airwallet/shared-models/location';
import { AwSwitchComponent } from '@components/misc/aw-switch/aw-switch.component';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

interface DurationGroup {
    type: FormControl<DurationType>;
    from: FormControl<number>;
    to: FormControl<number>;
    triggerType: FormControl<TriggerType>;
    target: FormControl<number>;
    given_up_front: FormControl<boolean>;
    happy_hours: FormControl<HappyHours>;
    refill_target: FormControl<number>;
}

interface CouponForm {
    name: FormControl<string>;
    discount_type: FormControl<DiscountType>;
    percentage_discount: FormControl<number>;
    fixed_amount: FormControl<string>;
    currency: FormControl<string>;
    free_cycles: FormControl<number>;
    distribute_type: FormControl<DistributeType>;
    coupon_code: FormControl<string>;
    entries: FormControl<boolean>;
    entries_limit: FormControl<number>;
    duration: FormGroup<DurationGroup>;
    notify_users: FormControl<boolean>;
    notification_title: FormControl<string>;
    notification_description: FormControl<string>;
}

@Component({
    selector: 'app-coupon-form',
    templateUrl: './coupon-form.component.html',
    styleUrls: ['./coupon-form.component.scss'],
    standalone: true,
    imports: [
        AwSwitchComponent,
        AwCalendarLayoutComponent,
        FormsModule,
        ReactiveFormsModule,
        CustomToolTipComponent,
        NgIf,
        MatRadioGroup,
        MatRadioButton,
        NgClass,
        MatCheckbox,
        LoadingComponent,
        CustomModalComponent,
        TranslateModule,
        NgSelectModule,
        UpperCasePipe,
        SlicePipe
    ],
    providers: [
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: {
                disabled: true,
                animation: {
                    enterDuration: 3000,
                    exitDuration: 0
                }
            }
        }
    ]
})
export class CouponFormComponent implements OnInit {
    @ViewChild('couponHappyHourModal') happyModal: ElementRef;
    @ViewChild(NgSelectComponent) dropdown: NgSelectComponent;

    global = false;
    couponForm: FormGroup<CouponForm> | undefined;
    allowNotifyUsers = false;
    trackEntries = true;
    givenUpfront = false;
    couponFormSubmitted = false;
    datepickerStart: Datepicker;
    datepickerEnd: Datepicker;
    lookUpDetails: LookUpDetails;
    dashboardUser: DashboardUser;
    modalRef: NgbModalRef;
    customers: CustomerDisplay[] = [];
    totalLocationAmount: number;
    totalLocationAmountOriginal: number;
    isLoading = false;
    calendar: Calendar;
    happyHourEvents: HappyHours = {
        mon: {},
        tue: {},
        wed: {},
        thu: {},
        fri: {},
        sat: {},
        sun: {}
    };
    isMobile: boolean;
    selectedEventId: string; // only used for when mobile view is interacted with
    processedEvents: { day: string; events: any[] }[];
    durationType = DurationType;
    TriggerType = TriggerType;
    discountType = DiscountType;
    distributeType = DistributeType;
    couponToEdit: Coupon | undefined;
    isDeleteLoading: boolean;
    isEdit = false;
    isOperator: boolean;
    hasSubCustomers = false;
    init = true;
    wasDragging = false;
    handleTouchMoveVar = this.handleTouchMove.bind(this);
    layoutWeek: Week;
    saveLoading = false;
    isFormReady = false;
    locations: CustomerLocations[] = [];
    selectedLocations: CustomerLocations[] = [];
    customerLocationStats: { allCustomers: CustomerDisplay[]; locationAmount: number };
    specificCustomerLocationStats: { coupon: Coupon; customersForCoupon: CustomerDisplay[]; locationAmount: number; allCustomers: CustomerDisplay[]; allLocationsAmount: number };
    customersReusable: CustomerDisplay[] = [];
    unfoldedCustomers: Set<string> = new Set<string>();
    constructor(
        private couponService: CouponService,
        private locationNavigation: LocationNavigation,
        public translate: TranslateService,
        public helperService: HelperService,
        private breakpointObserver: BreakpointObserver,
        public modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
        private toast: ToastrService
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit() {
        this.isLoading = true;
        const couponId: string = this.route.snapshot.paramMap.get('key');
        if (couponId) {
            this.isEdit = true;
        }
        await this.getCustomersForDisplayOrSpecificCoupon();
        this.dashboardUser = this.helperService.getUser();
        this.setupCouponForm();
        this.initDatePicker();
        this.copyCustomers();
        this.selectAllLocations(this.isEdit ? this.specificCustomerLocationStats.customersForCoupon : this.customers);
        this.updateCouponTypeValidator();
        if (this.customers.length === 1) {
            // Only has one customer, so we unfold it by default
            this.unfoldedCustomers.add(this.customers[0].name);
        }
        if (this.isEdit && this.couponToEdit?.distribute_type === DistributeType.USER_SPECIFIC) {
            this.entries.setValue(false);
            this.entries_limit.setValue(this.couponToEdit.entries?.users.limit);
        }
        this.isLoading = false;
    }

    // Workaround for a missing feature
    onDropdownOpen(): void {
        setTimeout(() => {
            if (this.dropdown?.dropdownPanel) {
                this.dropdown.dropdownPanel.scrollElementRef.nativeElement.scrollTop = 0;
            }
        }, 0);
    }

    async getCustomersForDisplayOrSpecificCoupon() {
        if (this.isEdit && this.global !== true) {
            const couponKey: string = this.route.snapshot.paramMap.get('key');
            const response = await this.couponService.getSpecificCouponData(couponKey);
            this.couponToEdit = response.coupon;
            if (this.couponToEdit.percentage_discount) {
                this.couponToEdit.percentage_discount = this.couponToEdit.percentage_discount * 100;
            }
            this.specificCustomerLocationStats = response;
            this.customersReusable = response.allCustomers;
        } else if (!this.isEdit) {
            const response = await this.couponService.getCustomersForDisplay();
            this.customerLocationStats = response;
            this.customersReusable = response.allCustomers;
        }
    }

    // Creating a copy of customers that can be modified,
    // Used to limit db calls from getSubCustomers when creating a coupon
    copyCustomers() {
        this.customers = [...this.customersReusable];
        this.processCustomerAndExpansion();
        this.customerHasLocations();

        this.customers.sort((a, b) => a.name.localeCompare(b.name));

        this.customers.forEach(customer => {
            customer.locations = customer.locations.filter(location => location.id && location.name && location.currency); // FIX for some location objects have been seen as empty
            customer.locations.sort((a, b) => a.name.localeCompare(b.name));

            customer.locations.forEach(location => {
                this.locations.push({
                    customerUid: customer.uid,
                    customerName: customer.name,
                    locationId: location.id,
                    locationName: location.name
                });
            });
        });
    }

    processCustomerAndExpansion() {
        if (this.customers.length > 1) {
            this.hasSubCustomers = true;
        } else {
            this.hasSubCustomers = false;
            this.customers.forEach(customer => {
                customer.isExpanded = true;
            });
        }
    }

    //#region happy Hour
    processEvents() {
        this.processedEvents = [];
        Object.keys(this.happyHourEvents)
            // Sorts the days, by the const days object, which is the order of the days in a week.
            .sort((a, b) => {
                const days = { mon: 0, tue: 1, wed: 2, thu: 3, fri: 4, sat: 5, sun: 6 };
                return days[a] - days[b];
            })
            .forEach(day => {
                const events = Object.keys(this.happyHourEvents[day]).map(eventKey => {
                    const startMilTime = this.happyHourEvents[day][eventKey].start;
                    const endMilTime = this.happyHourEvents[day][eventKey].end;

                    //Formatting military time to HH:MM
                    const formattedStart = this.formatTime(startMilTime);
                    const formattedEnd = this.formatTime(endMilTime);

                    return {
                        start: formattedStart,
                        end: formattedEnd
                    };
                });

                // Sorts the events based by the time, for each day.
                events.sort((a, b) => {
                    return parseInt(a.start.replace(':', '')) - parseInt(b.start.replace(':', ''));
                });

                if (events.length > 0) {
                    this.processedEvents.push({ day, events });
                }
            });
    }

    formatTime(militaryTime: string): string {
        const hours = militaryTime.substring(0, 2);
        const minutes = militaryTime.substring(2);
        return `${hours}:${minutes}`;
    }

    translateDay(day: string): string {
        return this.translate.instant(`coupon.happy_hour_day_long.${day}`);
    }

    renderHappyHourWeekCalendar() {
        const calendarEl: HTMLElement = document.getElementById('calendar');
        if (!calendarEl) return;
        this.calendar = new Calendar(calendarEl, {
            plugins: [interactionPlugin, timeGridPlugin],
            initialView: this.isMobile ? 'timeGridDay' : 'timeGridWeek',
            dayHeaderFormat: { weekday: 'short' },
            headerToolbar: {
                start: '',
                center: this.isMobile ? 'myCustomLeftButton myCustomRightButton' : '',
                end: ''
            },
            customButtons: {
                myCustomLeftButton: {
                    icon: 'chevron-left',
                    click: this.mobileNavigator.bind(this)
                },
                myCustomRightButton: {
                    icon: 'chevron-right',
                    click: this.mobileNavigator.bind(this)
                }
            },
            editable: true,
            eventResizableFromStart: true,
            eventStartEditable: true,
            eventOverlap: false,
            longPressDelay: 500,
            selectable: true,
            displayEventEnd: true,
            selectOverlap: false,
            selectMirror: true,
            allDaySlot: false,
            handleWindowResize: true,
            eventDragMinDistance: 5,
            slotDuration: '00:15:00',
            slotLabelInterval: '01:00',
            slotMinTime: '00:00:00',
            slotMaxTime: '24:00:00',
            scrollTime: '06:00:00',
            locale: this.dashboardUser?.settings?.country === 'US' ? 'en-US' : 'en-GB',
            firstDay: this.dashboardUser?.settings?.country === 'US' ? 0 : 1,
            slotLabelFormat:
                this.dashboardUser?.settings?.country === 'US'
                    ? {
                          // am pm time
                          hour: 'numeric',
                          minute: '2-digit',
                          omitZeroMinute: true,
                          meridiem: 'short'
                      }
                    : {
                          // military time
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                      },
            eventTimeFormat:
                this.dashboardUser?.settings?.country === 'US'
                    ? {
                          // am pm time
                          hour: 'numeric',
                          minute: '2-digit',
                          omitZeroMinute: true,
                          meridiem: 'short'
                      }
                    : {
                          // military time
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false
                      },
            dayHeaderContent: arg => {
                return `${this.translate.instant(`misc.weekdays.${arg.date.toString().split(' ')[0].toLowerCase()}.short`)}.`;
            },
            selectAllow: arg => {
                // blocks if user tries to select over multiple days
                const start: string = arg.startStr;
                const end: string = arg.endStr;
                return this.validateSelectionAndDrag(start, end);
            },
            eventAllow: dropInfo => {
                // blocks if user tries to drag a block to cross between two days
                const start: string = dropInfo.startStr;
                const end: string = dropInfo.endStr;
                return this.validateSelectionAndDrag(start, end);
            },
            select: this.handleDateSelect.bind(this), // when calendar is hold and cursor dragged (selection is made)
            eventClick: this.handleEventClick.bind(this), // if an event is clicked
            dateClick: this.handleDateClick.bind(this), // only used for mobile
            eventChange: this.handleEventChange.bind(this), // updates events
            events: this.loadEvents(),
            viewDidMount: () => {
                if (this.init) {
                    this.init = false;
                }
            },
            eventContent: (arg, el) => {
                if (!this.init) {
                    document.addEventListener('touchmove', this.handleTouchMoveVar, { passive: false });

                    if (this.wasDragging) {
                        this.wasDragging = false;
                        document.removeEventListener('touchmove', this.handleTouchMoveVar);
                    }
                }
                const startStr = moment(arg.event.start).format('HH:mm');
                const endStr = moment(arg.event.end).format('HH:mm');
                if (endStr === '00:00' && startStr === '00:00') {
                    const newTitle = `${startStr} - ${dayjs(arg.event.end).subtract(1, 'minute').format('HH:mm')}`;
                    return el('div', { className: 'fc-content', style: 'display:flex; justify-content:center' }, el('div', { className: 'fc-title', style: 'font-size: 10px;' }, newTitle));
                } else {
                    const newTitle = `${startStr} - ${endStr}`;
                    return el('div', { className: 'fc-content', style: 'display:flex; justify-content:center' }, el('div', { className: 'fc-title', style: 'font-size: 10px;' }, newTitle));
                }
            },
            eventDragStop: () => {
                this.wasDragging = true;
            }
        });

        this.calendar.render();
    }

    handleTouchMove(e: TouchEvent) {
        e.preventDefault();
    }

    saveSelectedHappyHour(editCouponSetup?: boolean) {
        this.happy_hours.setValue(this.happyHourEvents);

        this.processEvents();
        if (this.processedEvents.length < 1) {
            this.happy_hours.setErrors({ invalid: true });
            this.happy_hours.setValue(null);
        }
        this.modalService.dismissAll('Close');
        this.layoutWeek = this.happyHourEvents;

        if (!editCouponSetup) {
            // is not edit coupon setup, but simply saving the happy hour
            this.couponFormSubmitted = true;
            if (this.couponForm.valid && (!this.isAllLocationsSelected() || this.global)) {
                const finalCouponToEdit: Coupon = this.couponToEdit;
                this.name.value ? (finalCouponToEdit.name = this.name.value) : null;
                this.couponService.editCoupons(finalCouponToEdit, this.selectedLocations, this.name.value && this.name.value !== this.couponToEdit.name ? true : false);
            }
        }
    }

    removeEvent(eventId?: string) {
        const event = this.calendar.getEventById(eventId ? eventId : this.selectedEventId);
        event.remove(); // removes the event
        delete this.happyHourEvents[this.getWeekdayFromDateStr(event.startStr, moment(event.startStr).creationData().format as string)][event.id];
        !eventId ? (this.selectedEventId = null) : null;
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    handleDateClick(arg: any) {
        if (this.isMobile) {
            this.selectedEventId = null;
        }
    }

    mobileNavigator(arg: PointerEvent) {
        const isUS: boolean = this.dashboardUser?.settings?.country === 'US' ? true : false;
        if (arg.target['className'].includes('right')) {
            // going forwards
            if (moment(this.calendar.getDate()).weekday() !== (isUS ? 6 : 0))
                // allowed to go forwards if weekday is 5 or lower in US, else 6 or lower in other countries (saturday is 6)
                this.calendar.next();
        } else {
            // going backwards
            if (moment(this.calendar.getDate()).weekday() !== (isUS ? 0 : 1))
                // allowed to go backwards if weekday is 0 or higher in US, else 1 or higher in other countries (sunday is 0)
                this.calendar.prev();
        }
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    validateSelectionAndDrag(start: string, end: string): boolean {
        // end of day is tagged as begining of next day, validation here check for that
        return moment(start).weekday() === moment(end).weekday() || // if same day, all "OK"
            (moment(end).format('HHmm') === '0000' && // OR if end is value '0000' (we know it is the last event)
                (moment(start).weekday() === moment(end).weekday() - 1 || // AND if endStr is one day ahead, all "OK"
                    (moment(start).weekday() === 6 && moment(end).weekday() === 0))) // OR if sunday it should be start on saturday, all "OK"
            ? true
            : false;
    }

    handleDateSelect(arg: DateSelectArg) {
        this.calendar.unselect(); // clears the selects before it adds it as a event
        const id: string = this.helperService.createPushKey();
        const dateFormat = moment(arg.startStr).creationData().format as string;
        const start: string = arg.startStr;
        const end: string = arg.endStr;
        const addEventParams = { start, end, id };
        const event = this.calendar.addEvent(addEventParams);

        const weekday: Weekdays = this.getWeekdayFromDateStr(start, dateFormat);
        if (this.happyHourEvents[weekday]) {
            this.happyHourEvents[weekday][id] = {
                start: `${moment(start, dateFormat).format('HHmm')}`,
                end: `${moment(end, dateFormat).format('HHmm') === '0000' ? '2400' : moment(end, dateFormat).format('HHmm')}`
            };
        } else {
            this.happyHourEvents[weekday] = {
                [id]: {
                    start: `${moment(start, dateFormat).format('HHmm')}`,
                    end: `${moment(end, dateFormat).format('HHmm') === '0000' ? '2400' : moment(end, dateFormat).format('HHmm')}`
                }
            };
        }
        document.removeEventListener('touchmove', this.handleTouchMoveVar);
    }

    addDeleteButtonToEvent(arg: EventClickArg) {
        const el: Element = arg.el.children.item(0).children.item(0).children.item(0); // element that holds the event label (div)
        const closeBtn = document.createElement('button');
        closeBtn.className = 'aw-fc-close-icon';

        closeBtn.addEventListener('click', () => {
            this.removeEvent(arg.event.id);
        });

        el.appendChild(closeBtn); // inserts the button into the HTML
    }

    removeDeleteButtonFromEvents() {
        const eventElement = document.querySelector('.aw-fc-close-icon');
        if (eventElement) eventElement.remove();
    }

    handleEventClick(arg: EventClickArg) {
        if (this.isMobile) {
            this.selectedEventId = arg.event.id;
        } else {
            this.removeDeleteButtonFromEvents(); // remove previous close icon before adding a new
            this.addDeleteButtonToEvent(arg); // add close icon to event
        }
    }

    handleEventChange(arg: EventChangeArg) {
        // Function updates the event
        const { event, oldEvent } = arg;
        const dateFormat = moment(event.startStr).creationData().format as string;

        if (moment(oldEvent.startStr).weekday() !== moment(event.startStr).weekday()) {
            // day have changed so old entry should be removed
            delete this.happyHourEvents[this.getWeekdayFromDateStr(oldEvent.startStr, dateFormat)][event.id];
        }

        if (this.happyHourEvents[this.getWeekdayFromDateStr(event.startStr, dateFormat)]) {
            this.happyHourEvents[this.getWeekdayFromDateStr(event.startStr, dateFormat)][event.id] = {
                start: `${moment(event.startStr, dateFormat).format('HHmm')}`,
                end: `${moment(event.endStr, dateFormat).format('HHmm') === '0000' ? '2400' : moment(event.endStr, dateFormat).format('HHmm')}`
            };
        } else {
            this.happyHourEvents[this.getWeekdayFromDateStr(event.startStr, dateFormat)] = {
                [event.id]: {
                    start: `${moment(event.startStr, dateFormat).format('HHmm')}`,
                    end: `${moment(event.endStr, dateFormat).format('HHmm') === '0000' ? '2400' : moment(event.endStr, dateFormat).format('HHmm')}`
                }
            };
        }
    }

    getWeekdayFromDateStr(dateStr: string, dateFormat: string): Weekdays {
        // find the weekday in three-char format from our enum
        return Weekdays[Object.keys(Weekdays)[moment(dateStr, dateFormat).weekday()]];
    }

    loadEvents(): Event[] {
        const payload = [];
        for (const weekday in this.happyHourEvents) {
            for (const eventId in this.happyHourEvents[weekday]) {
                const event: Event = this.happyHourEvents[weekday][eventId];
                const sundayFix: number = // sundayFix is to ensure that the sunday is the upcoming Sunday
                    weekday === 'sun' && // must be a sunday
                    moment(weekday, 'ddd').endOf('day').isBefore() // and if end of day is before now, then it is the previous sunday and not the upcoming
                        ? 1
                        : 0; // if statement is true, then 1 to add a week, else 0 to not add
                payload.push({
                    start: moment(`${moment(weekday, 'ddd').add(sundayFix, 'week').format('YYYY-MM-DD')} ${event.start}`, 'YYYY-MM-DD HHmm').toISOString(),
                    end: moment(`${moment(weekday, 'ddd').add(sundayFix, 'week').format('YYYY-MM-DD')} ${event.end}`, 'YYYY-MM-DD HHmm').toISOString(),
                    id: eventId
                });
            }
        }
        return payload;
    }

    //#endregion
    initDatePicker() {
        if (document.getElementById('from') && document.getElementById('to')) {
            this.datepickerStart = new Datepicker(document.getElementById('from'), {
                language: this.translate.currentLang,
                todayButton: true,
                clearButton: true,
                autohide: true,
                format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
            });
            this.datepickerEnd = new Datepicker(document.getElementById('to'), {
                language: this.translate.currentLang,
                todayButton: true,
                clearButton: true,
                autohide: true,
                format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'
            });
        }
    }

    setupCouponForm() {
        this.couponForm = new FormGroup<CouponForm>({
            name: new FormControl(this.couponToEdit?.name ?? null, [Validators.required]),
            discount_type: new FormControl(this.couponToEdit?.discount_type ?? this.discountType.PERCENTAGE, [Validators.required]),
            percentage_discount: new FormControl(this.couponToEdit?.percentage_discount ?? null, [Validators.required, Validators.max(100), Validators.min(0), Validators.pattern(/^\d+$/)]),
            fixed_amount: new FormControl(this.couponToEdit?.fixed_amount ? this.handleFixedAmount(null, this.couponToEdit?.fixed_amount) : null, []),
            currency: new FormControl(this.couponToEdit?.currency ?? this.dashboardUser.settings.currency, []),
            free_cycles: new FormControl(this.couponToEdit?.free_cycles ?? null, [Validators.pattern(/^\d+$/), Validators.max(999)]),
            distribute_type: new FormControl(this.couponToEdit?.distribute_type ?? this.distributeType.ALL, [Validators.required]),
            coupon_code: new FormControl(this.couponToEdit?.coupon_code ?? null, [this.codeMatchesRules()]),
            entries: new FormControl(this.couponToEdit?.entries.users.limit ? false : true, []),
            entries_limit: new FormControl(this.couponToEdit?.entries.users.limit ?? null, []),
            duration: new FormGroup<DurationGroup>({
                type: new FormControl(this.couponToEdit?.duration.type ?? DurationType.ONCE, [Validators.required]),
                from: new FormControl(this.couponToEdit?.duration.from ?? null, []),
                to: new FormControl(this.couponToEdit?.duration.to ?? null, []),
                triggerType: new FormControl(this.couponToEdit?.duration.triggerType ?? null, []),
                target: new FormControl(this.couponToEdit?.duration.target ?? null, []),
                given_up_front: new FormControl(this.couponToEdit?.duration.type === DurationType.EVERY && this.couponToEdit?.duration.given_up_front === true ? true : null, []),
                happy_hours: new FormControl(this.couponToEdit?.duration.happy_hours ?? null, []),
                refill_target: new FormControl(this.couponToEdit?.duration.refill_target ?? null, [])
            }),
            notify_users: new FormControl(this.couponToEdit?.notify_users ?? false, []),
            notification_title: new FormControl(this.couponToEdit?.notification_title ?? null, []),
            notification_description: new FormControl(this.couponToEdit?.notification_description ?? null, [])
        });

        this.isFormReady = true;

        if (this.couponToEdit && this.isEdit) {
            if (this.couponToEdit.duration.type === DurationType.HAPPYHOUR) {
                this.happyHourEvents = this.couponToEdit.duration.happy_hours;
                this.saveSelectedHappyHour(true);
            }
            if (this.couponToEdit.duration.type === DurationType.EVERY && this.couponToEdit.duration.given_up_front === true) {
                this.givenUpfront = true;
            }
            if (this.couponToEdit.duration && this.couponToEdit.duration.target) {
                this.handleTargetAmount();
            }

            if (this.couponToEdit.entries.users.limit) {
                this.trackEntries = false;
            }
        }
    }

    // Checking if the coupon code is a valid key for our firebase database
    codeMatchesRules(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const code = control.value;

            const rejectedChars = ['.', '#', '$', '[', ']'];

            if (code) {
                for (const char of rejectedChars) {
                    if (code.includes(char)) {
                        return { regex_error: true };
                    }
                }
            }
            return null;
        };
    }

    //#endregion
    toggleEntries() {
        this.trackEntries = !this.trackEntries;
        this.entries.patchValue(this.trackEntries);

        if (this.trackEntries) {
            this.entries_limit.clearValidators();
            this.entries_limit.setValue(null);
        } else {
            this.entries_limit.setValidators([Validators.required, Validators.min(1)]);
        }

        this.couponForm.updateValueAndValidity();
    }

    toggleNotifyUsers() {
        this.allowNotifyUsers = !this.allowNotifyUsers;
        this.notify_users.patchValue(this.allowNotifyUsers);
        this.couponService.setNotifyUsersToggled(this.allowNotifyUsers);

        if (!this.allowNotifyUsers) {
            this.notification_title.clearValidators();
            this.notification_title.setValue(null);
            this.notification_description.clearValidators();
            this.notification_description.setValue(null);
        } else {
            this.notification_title.setValidators([Validators.required]);
            this.notification_description.setValidators([Validators.required]);
        }

        this.notification_title.updateValueAndValidity();
        this.notification_description.updateValueAndValidity();
    }

    toggleGivenUpfront() {
        this.givenUpfront = !this.givenUpfront;
        this.couponService.setGivenUpfrontToggled(this.givenUpfront);

        this.given_up_front.setValue(this.givenUpfront ?? null);
    }

    onCodeInput(event: any) {
        let inputVal = event.target.value;
        inputVal = inputVal.toUpperCase();
        event.target.value = inputVal;
        this.coupon_code.setValue(inputVal);
    }

    openHappyHourModal(modal: NgbModalModule) {
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'xl',
            animation: false
        });

        this.renderHappyHourWeekCalendar();
    }

    getCustomersLength() {
        return this.customers.length;
    }

    customerHasLocations() {
        this.customers = this.customers.filter(customer => {
            return Object.values(customer.locations).length > 0;
        });
    }

    couponTypeChange() {
        this.updateCouponTypeValidator();

        this.totalLocationAmount = this.totalLocationAmountOriginal;

        //Loops through all locations to remove those locations with other currencies used than the users own
        if (this.discount_type.value === this.discountType.FIXED || this.triggerType.value === TriggerType.SPENT) {
            this.copyCustomers(); // Reloads customers because we remove the ability to select customer locations with other currencies than the users

            if (this.customers) {
                this.customers = this.customers
                    .map(customer => {
                        const filteredLocations = customer.locations.filter(location => {
                            if (location.currency === this.dashboardUser.settings.currency) {
                                return true;
                            } else {
                                this.totalLocationAmount--;
                                return false;
                            }
                        });

                        if (filteredLocations.length > 0) {
                            return { ...customer, locations: filteredLocations };
                        } else {
                            return null; // Filter out customers with no valid locations
                        }
                    })
                    .filter(Boolean); // Remove null entries (customers with no valid locations)
            }
        } else {
            this.copyCustomers();
        }
        this.selectAllLocations(this.isEdit ? this.specificCustomerLocationStats.customersForCoupon : this.customers);

        if (!(this.triggerType.value === TriggerType.SPENT || this.triggerType.value === TriggerType.STARTS)) {
            this.givenUpfront = false;
            this.given_up_front.patchValue(false);
        }
    }

    updateCouponTypeValidator() {
        const selectedCouponType = this.discount_type.value;
        const changes = {
            percentage: {
                reset: ['fixed_amount', 'free_cycles'],
                setValidators: ['percentage_discount']
            },
            fixed: {
                reset: ['percentage_discount', 'free_cycles'],
                setValidators: ['fixed_amount']
            },
            freeCycles: {
                reset: ['percentage_discount', 'fixed_amount'],
                setValidators: ['free_cycles']
            }
        };

        const selectedChanges = changes[selectedCouponType] || changes.percentage;

        for (const controlName of selectedChanges.reset) {
            const control = this[controlName] as AbstractControl;
            this[controlName].reset();
            this[controlName].setValidators(null);
            control.updateValueAndValidity();
        }

        for (const controlName of selectedChanges.setValidators) {
            const control = this[controlName] as AbstractControl;
            if (controlName === 'percentage_discount') {
                this[controlName].setValidators([Validators.required, Validators.max(100), Validators.min(0), Validators.pattern(/^\d+$/)]);
            } else if (controlName === 'free_cycles') {
                this[controlName].setValidators([Validators.required, Validators.pattern('^[0-9]*$'), Validators.max(999)]);
            } else {
                this[controlName].setValidators(Validators.required);
            }
            control.updateValueAndValidity();
        }
    }

    distributeTypeChange() {
        const selectedDistributionType = this.distribute_type.value;
        const changes = {
            allUsers: {
                reset: ['coupon_code'],
                setValidators: []
            },
            selectedUsers: {
                reset: ['coupon_code'],
                setValidators: []
            },
            code: {
                reset: [],
                setValidators: ['coupon_code']
            }
        };

        const selectedChanges = changes[selectedDistributionType] || changes.allUsers;

        for (const controlName of selectedChanges.reset) {
            const control = this[controlName] as AbstractControl;
            this[controlName].reset();
            this[controlName].setValidators(null);
            control.updateValueAndValidity();
        }

        for (const controlName of selectedChanges.setValidators) {
            const control = this[controlName] as AbstractControl;
            if (controlName === 'coupon_code') {
                this[controlName].setValidators([Validators.required, this.codeMatchesRules(), this.checkForSpaces()]);
            } else {
                this[controlName].setValidators(Validators.required);
            }
            control.updateValueAndValidity();
        }
        if (this.distribute_type.value !== this.distributeType.ALL && this.allowNotifyUsers) {
            this.toggleNotifyUsers();
        }
    }

    checkForSpaces(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const code = control.value;

            if (code) {
                if (code.includes(' ')) {
                    return { space_error: true };
                }
            }
            return null;
        };
    }

    couponDurationChange() {
        const selectedCouponDuration: DurationType = this.type.value;

        this.updateDurationForm(selectedCouponDuration);
        if (!this.isEdit) {
            this.processedEvents = null;
            this.happyHourEvents = {
                mon: {},
                tue: {},
                wed: {},
                thu: {},
                fri: {},
                sat: {},
                sun: {}
            };
        }
    }

    updateDurationForm(selectedCouponDuration: DurationType): void {
        this.updateFormValidators(selectedCouponDuration);
        this.updateDurationDetails(selectedCouponDuration);
    }

    updateFormValidators(selectedCouponDuration: DurationType): void {
        const durationGroup = this.duration;
        const changes = {
            forever: {
                reset: ['triggerType', 'target', 'happy_hours', 'refill_target', 'from', 'to'],
                setValidators: []
            },
            once: {
                reset: ['triggerType', 'target', 'happy_hours', 'refill_target', 'from', 'to'],
                setValidators: []
            },
            happyHour: {
                reset: ['triggerType', 'target', 'refill_target', 'from', 'to'],
                setValidators: ['happy_hours']
            },
            refill: {
                reset: ['triggerType', 'target', 'happy_hours', 'from', 'to'],
                setValidators: ['refill_target']
            },
            period: {
                reset: ['triggerType', 'target', 'happy_hours', 'refill_target'],
                setValidators: ['from', 'to']
            },
            every: {
                reset: ['triggerType', 'target', 'happy_hours', 'refill_target', 'from', 'to'],
                setValidators: ['triggerType', 'target', 'given_up_front']
            }
        };

        const selectedChanges = changes[selectedCouponDuration] || changes.forever;

        for (const controlName of selectedChanges.reset) {
            const control = this[controlName] as AbstractControl;
            this[controlName].setValidators(null);
            control.updateValueAndValidity();
        }

        for (const controlName of selectedChanges.setValidators) {
            const control = durationGroup.get(controlName);
            if (control) {
                control.setValidators(Validators.required);
                if (controlName === 'target') {
                    control.setValidators([Validators.min(1), Validators.pattern(/^\d+$/), Validators.required]);
                }
                control.updateValueAndValidity();
            }
        }
    }

    updateDurationDetails(selectedCouponDuration: DurationType): void {
        switch (selectedCouponDuration) {
            case DurationType.FOREVER:
            case DurationType.ONCE:
                this.setDurationDetails({
                    type: selectedCouponDuration,
                    triggerType: null,
                    target: null,
                    given_up_front: null,
                    happy_hours: null,
                    refill_target: null,
                    from: null,
                    to: null
                });
                break;

            case DurationType.EVERY:
                this.setDurationDetails({
                    type: selectedCouponDuration,
                    triggerType: TriggerType.STARTS,
                    target: !this.isEdit ? null : this.couponToEdit.duration.target ? this.couponToEdit.duration.target : null,
                    given_up_front: !this.isEdit ? null : this.couponToEdit.duration.given_up_front ? this.couponToEdit.duration.given_up_front : null,
                    happy_hours: null,
                    refill_target: null,
                    from: null,
                    to: null
                });
                break;

            case DurationType.HAPPYHOUR:
                this.setDurationDetails({
                    type: selectedCouponDuration,
                    triggerType: null,
                    target: null,
                    given_up_front: null,
                    happy_hours: !this.isEdit ? null : this.couponToEdit.duration.happy_hours ? this.couponToEdit.duration.happy_hours : null,
                    refill_target: null,
                    from: null,
                    to: null
                });
                break;

            case DurationType.REFILL:
                this.setDurationDetails({
                    type: selectedCouponDuration,
                    triggerType: null,
                    target: null,
                    given_up_front: null,
                    happy_hours: null,
                    refill_target: !this.isEdit ? null : this.couponToEdit.duration.refill_target ? this.couponToEdit.duration.refill_target : null,
                    from: null,
                    to: null
                });
                break;

            case DurationType.PERIOD:
                this.setDurationDetails({
                    type: selectedCouponDuration,
                    triggerType: null,
                    target: null,
                    given_up_front: null,
                    happy_hours: null,
                    refill_target: null,
                    from: !this.isEdit ? null : this.couponToEdit.duration.from ? this.couponToEdit.duration.from : null,
                    to: !this.isEdit ? null : this.couponToEdit.duration.to ? this.couponToEdit.duration.to : null
                });
                this.initDatePicker();
                break;

            default:
                break;
        }
    }

    setDurationDetails(durationDetails: DurationDetails): void {
        this.couponForm.patchValue({ duration: durationDetails });
    }

    datePickerChanged(pickerChanged: 'from' | 'to') {
        pickerChanged === 'from'
            ? this.duration_period_from.setValue(parseInt(moment(this.datepickerStart.dates[0], 'x').startOf('day').format('X')))
            : this.duration_period_to.setValue(parseInt(moment(this.datepickerEnd.dates[0], 'x').endOf('day').format('X')));

        !this.datepickerStart.dates[0] ? this.duration_period_from.setValue(null) : null;
        !this.datepickerEnd.dates[0] ? this.duration_period_to.setValue(null) : null;

        if (!this.datepickerStart.dates[0] && !this.datepickerEnd.dates[0]) {
            // change to date range triggers a reset in the query id so let's remove it.
            this.lookUpDetails && this.lookUpDetails.filter_fingerprint ? (this.lookUpDetails.filter_fingerprint = null) : null;
        }

        this.datepickerStart.setOptions({ maxDate: this.datepickerEnd.dates[0] });
        this.datepickerEnd.setOptions({ minDate: this.datepickerStart.dates[0] });
    }

    get duration_period_from() {
        return this.from;
    }

    get duration_period_to() {
        return this.to;
    }

    selectAllLocations(customers: CustomerDisplay[]) {
        const locationsToSet: CustomerLocations[] = [];
        for (const customer of customers) {
            for (const location of customer.locations) {
                locationsToSet.push({ customerUid: customer.uid, customerName: customer.name, locationId: location.id, locationName: location.name });
            }
        }

        this.selectedLocations = [...locationsToSet];
    }

    itemOfIndexClass(item: CustomerLocations): string {
        const correctedIndex = this.customers.findIndex(customer => customer.uid === item.customerUid);
        const customer = this.customers[correctedIndex];
        const locationIndex = customer.locations.findIndex(location => location.id === item.locationId);
        let classString = 'location-item';
        if (locationIndex === 0) {
            classString += ' first-border-item';
        } else if (locationIndex === this.customers[correctedIndex].locations.length - 1) {
            classString += ' last-border-item';
        }

        return classString;
    }

    isUnfolded(customerName: string) {
        return this.unfoldedCustomers.has(customerName);
    }

    setUnfolded(event: MouseEvent, customerName: string) {
        event.stopPropagation();
        if (this.unfoldedCustomers.has(customerName)) {
            this.unfoldedCustomers.delete(customerName);
        } else {
            this.unfoldedCustomers.add(customerName);
        }
    }

    onClearAll() {
        this.selectedLocations = [];
    }

    isAllLocationsSelected() {
        return this.selectedLocations.length === this.totalLocationAmount;
    }

    customSearchFn(term: string, item: CustomerLocations) {
        term = term.toLowerCase();
        return item.customerName.toLowerCase().indexOf(term) > -1 || item.locationName.toLowerCase().indexOf(term) > -1;
    }

    async createCoupon() {
        this.saveLoading = true;
        this.couponFormSubmitted = true;
        if (this.selectedLocations.length > 100) {
            this.toast.info(this.translate.instant('coupon.auto_global'), this.translate.instant('misc.info'));
            //     this.toast.error(this.translate.instant('coupon.error.max_locations'), this.translate.instant('misc.error'));
            //     this.saveLoading = false;
            //     return;
        }
        if (this.couponForm.valid) {
            let percentageToSave = this.percentage_discount.value;
            if (this.discount_type.value === this.discountType.PERCENTAGE) {
                percentageToSave = this.helperService.roundToTwoDecimals(percentageToSave / 100);
            }

            let entriesObject: Coupon['entries'] = null;

            if (this.distribute_type.value === this.distributeType.CODE && this.entries.value === false && this.entries_limit.value) {
                entriesObject = {
                    users: {
                        count: 0,
                        limit: this.entries_limit.value
                    }
                };
            } else {
                entriesObject = {
                    users: {
                        count: 0
                    }
                };
            }

            const couponParams: CreateCouponParams = {
                coupon: { ...this.couponForm.getRawValue(), percentage_discount: percentageToSave, entries: entriesObject, global: this.global, fixed_amount: parseInt(this.fixed_amount.value) },
                appliedCustomerLocations: this.selectedLocations
            };

            if (couponParams.coupon.entries.users.limit === 1 && couponParams.coupon.fixed_amount && couponParams.coupon.duration.type === 'once') {
                couponParams.coupon.distribute_type = DistributeType.USER_SPECIFIC;
            }

            couponParams.coupon.fixed_amount = this.cleanFormatedAmount(this.fixed_amount.value);
            couponParams.coupon.duration.target = this.cleanFormatedAmount(String(this.target.value));
            couponParams.coupon.duration.refill_target = this.cleanFormatedAmount(String(this.refill_target.value));

            await this.couponService
                .createCoupons(couponParams)
                .then(() => {
                    this.saveLoading = false;
                    this.toast.success(this.translate.instant('coupon.create_success'), this.translate.instant('misc.success'));
                    this.locationNavigation.back();
                })
                .catch(err => {
                    this.saveLoading = false;
                    if (err.error.error.code.includes('coupon_code_exists')) {
                        this.toast.error(this.translate.instant('coupon.error.coupon_code_exists'), this.translate.instant('misc.error'));
                        this.coupon_code.setErrors({ code_exists: err.error.error.code });
                        this.couponForm.updateValueAndValidity();
                    } else if (err.error.error.code.includes('coupon_name_exists')) {
                        this.toast.error(this.translate.instant('coupon.error.coupon_name_exists'), this.translate.instant('misc.error'));
                        this.name.setErrors({ name_exists: err.error.error.code });
                        this.couponForm.updateValueAndValidity();
                    } else {
                        this.toast.error(this.translate.instant('coupon.create_error'), this.translate.instant('misc.error'));
                    }
                });
        } else {
            this.saveLoading = false;
        }
    }

    async updateCoupon() {
        this.saveLoading = true;
        this.couponFormSubmitted = true;
        if (this.selectedLocations.length > 100) {
            this.toast.info(this.translate.instant('coupon.auto_global'), this.translate.instant('misc.info'));
            //     this.toast.error(this.translate.instant('coupon.error.max_locations'), this.translate.instant('misc.error'));
            //     this.saveLoading = false;
            //     return;
        }
        if (this.couponToEdit.distribute_type === DistributeType.USER_SPECIFIC) {
            this.toast.warning(this.translate.instant('coupon.cannot_edit_voucher'), this.translate.instant('misc.info'));
            this.saveLoading = false;
            return;
        }
        if (this.couponForm.valid) {
            const finalCouponToEdit: Coupon = this.couponToEdit;
            if (this.discount_type.value === this.discountType.PERCENTAGE) {
                const percentageToSave: number = this.percentage_discount.value;
                finalCouponToEdit.percentage_discount = this.helperService.roundToTwoDecimals(percentageToSave / 100);
            }
            // only allowed changed values
            let shouldCheckForName = false;
            if (this.name.value && this.name.value !== this.couponToEdit.name) {
                shouldCheckForName = true;
            }
            if (this.name.value) {
                finalCouponToEdit.name = this.name.value;
            }
            await this.couponService
                .editCoupons(finalCouponToEdit, this.selectedLocations, shouldCheckForName)
                .then(() => {
                    this.saveLoading = false;
                    this.toast.success(this.translate.instant('coupon.update_success'), this.translate.instant('misc.success'));
                    this.locationNavigation.back();
                })
                .catch(err => {
                    if (err.error.error_code.includes('coupon_name_exists')) {
                        this.name.setErrors({ name_exists: err.error.error_code });
                        this.couponForm.updateValueAndValidity();
                    }

                    this.saveLoading = false;
                    this.toast.error(this.translate.instant('coupon.update_error'), this.translate.instant('misc.error'));
                });
        } else {
            this.saveLoading = false;
        }
    }

    //#region formart currency amounts
    cleanFormatedAmount(amount?: string): number | null {
        if (!amount) {
            return null;
        }
        return parseInt(amount.replaceAll(',', '.').replaceAll('.', ''));
    }

    handleFixedAmount(event?: any, isFixedAmount?: number) {
        const valueToSet: string = this.formatValue(event && !isFixedAmount ? event.target.value.replaceAll(',', '.') : (isFixedAmount / 100).toString());
        if (event) {
            if (valueToSet === 'NaN' || Number(valueToSet) <= 0) {
                this.fixed_amount.setErrors({ invalidNumber: true });
            } else {
                this.fixed_amount.patchValue(valueToSet);
                this.fixed_amount.setErrors(null);
            }
        } else if (isFixedAmount) {
            return valueToSet;
        }
    }

    validatePositiveInteger(event?: any): void {
        const input = event.target as HTMLInputElement;
        let value = input.value.replace(/[^0-9]/g, '');
        if (Number(value) > 999999) {
            value = '999999';
        }
        input.value = value;
    }

    handleTargetAmount(event?: any) {
        if (this.triggerType.value === TriggerType.SPENT) {
            const valueToSet: string = this.formatValue(event ? event.target.value.replaceAll(',', '.') : (this.target.value / 100).toString());
            if (valueToSet === 'NaN' || Number(valueToSet) <= 0) {
                this.target.setErrors({ invalidNumber: true });
                return;
            } else {
                this.target.patchValue(valueToSet);
                this.target.setValidators([Validators.required, Validators.min(1)]);
            }
        }
    }

    handleRefillTargetAmount(event?: any) {
        const valueToSet: string = this.formatValue(event ? event.target.value.replaceAll(',', '.') : (parseInt(this.fixed_amount.value) / 100).toString());
        if (valueToSet === 'NaN' || Number(valueToSet) <= 0) {
            this.refill_target.setErrors({ invalidNumber: true });
        } else {
            this.refill_target.patchValue(valueToSet);
            this.refill_target.setErrors(null);
        }
    }

    formatValue(value: any) {
        value = value.replaceAll(',', '.');
        let decimalFactor = 1;
        if (value.includes('.')) {
            const lengthAfterPoint = value.substring(value.lastIndexOf('.') + 1).length;
            decimalFactor = lengthAfterPoint === 0 ? 1 : lengthAfterPoint === 1 ? 10 : lengthAfterPoint === 2 ? 100 : 1;
        }
        value = String(value).replaceAll('.', '');
        value = value / decimalFactor;
        return this.helperService.localizeNumber(value, 2, 2);
    }

    //#endregion

    //#region delete methods
    async deleteCoupon(): Promise<void> {
        this.isDeleteLoading = true;
        await this.couponService
            .archiveCoupons(this.couponToEdit.key, this.couponToEdit.global)
            .then(() => {
                this.toast.success(this.translate.instant('coupon.delete_success'), this.translate.instant('misc.success'));
                this.closeModal();
                this.router.navigate(['/coupon']);
            })
            .catch(() => {
                this.toast.error(this.translate.instant('coupon.delete_error'), this.translate.instant('misc.error'));
            })
            .finally(() => {
                this.isDeleteLoading = false;
            });
    }

    openDeleteCouponModal(modal: NgbModalModule) {
        this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title',
            windowClass: 'no-autofocus'
        });
    }

    //#endregion

    closeModal() {
        this.modalService.dismissAll('Cancel');
    }

    countCharacters(textarea: HTMLTextAreaElement, counterId: string) {
        const characterCount = textarea.value.length;
        const maxLength = parseInt(textarea.getAttribute('maxlength'));
        const countDisplay = document.getElementById(counterId);
        countDisplay.textContent = characterCount + '/' + maxLength;
    }

    get name() {
        return this.couponForm.get('name');
    }

    get discount_type() {
        return this.couponForm.get('discount_type');
    }

    get percentage_discount() {
        return this.couponForm.get('percentage_discount');
    }

    get fixed_amount() {
        return this.couponForm.get('fixed_amount');
    }

    get currency() {
        return this.couponForm.get('currency');
    }

    get free_cycles() {
        return this.couponForm.get('free_cycles');
    }

    get distribute_type() {
        return this.couponForm.get('distribute_type');
    }

    get coupon_code() {
        return this.couponForm.get('coupon_code');
    }

    get entries() {
        return this.couponForm.get('entries');
    }

    get entries_limit() {
        return this.couponForm.get('entries_limit');
    }

    get duration() {
        return this.couponForm.get('duration') as FormGroup;
    }

    get type() {
        return this.duration.get('type');
    }

    get triggerType() {
        return this.duration.get('triggerType');
    }

    get target() {
        return this.duration.get('target');
    }

    get given_up_front() {
        return this.duration.get('given_up_front');
    }

    get happy_hours() {
        return this.duration.get('happy_hours');
    }

    get refill_target() {
        return this.duration.get('refill_target');
    }

    get from() {
        return this.duration.get('from');
    }

    get to() {
        return this.duration.get('to');
    }

    get notify_users() {
        return this.couponForm.get('notify_users');
    }

    get notification_title() {
        return this.couponForm.get('notification_title');
    }

    get notification_description() {
        return this.couponForm.get('notification_description');
    }
}
