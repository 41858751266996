import { Component, input, InputSignal, OnInit, output, OutputEmitterRef } from '@angular/core';
import { z } from 'zod';
import { AwForm, Controls, FormBase, FormControlService } from './aw-forms';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { phoneNumberCountryData } from '../../../../../../shared_data/phone_number_data';
import { AwFormLabelComponent } from './aw-form-label/aw-form-label.component';
import { isValidFormBody } from '@airwallet/shared-zod/zodValidation';
import { phoneSchema } from '@airwallet/shared-zod/forms/general/general';
import { ControlLowerTypes } from '@airwallet/shared-models/form-creator';
import { ControlDirective } from './directives/control.directive';
import { LoadingComponent } from '@components/loading/loading.component';

/**
 * @param <S> - The shape of the schema
 * @param <T> - The type of the form
 * @param <K> - The key of the form
 * @input form
 * @input buttonLabel
 * @input isFetching
 * @output onSuccess
 *
 */

@Component({
    selector: 'app-aw-form-creator',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, TranslateModule, AwFormLabelComponent, ControlDirective, LoadingComponent],
    templateUrl: './aw-form-creator.component.html',
    styleUrl: './aw-form-creator.component.scss'
})
export class AwFormCreatorComponent<S extends z.ZodRawShape, T, K extends keyof T> implements OnInit {
    schema: InputSignal<z.ZodObject<S> | z.ZodEffects<z.ZodObject<S>>> = input.required();
    form: InputSignal<AwForm<T, K>> = input.required();
    buttonLabel: InputSignal<string> = input('device.save_changes');
    onSuccess: OutputEmitterRef<boolean> = output<boolean>();
    isFetching: InputSignal<boolean> = input(false);
    size: InputSignal<'sm' | 'lg'> = input('lg');
    formGroup: FormGroup<Controls<K>>;
    controls: FormBase<T>[] = [];
    phoneNumberPicker: { all: Record<string, string>[]; fav: Record<string, string>[] } = getCallCodesAndCountry();
    loadForm = false;
    formSubmitted = false;
    constructor(private formControlService: FormControlService<T, K>) {}

    ngOnInit(): void {
        this.formGroup = this.formControlService.toFormGroup(this.form());
        this.controls = this.assignControls();
        this.loadForm = true;
        this.observeControls(this.controls);
    }

    assignControls() {
        const arr = Object.keys(this.form()).map(key => {
            const control = this.form()[key];
            control.key = key;
            return control;
        });
        arr.sort((a, b) => a.order - b.order);
        return arr;
    }

    observeControls(controls: FormBase<T>[]) {
        controls.forEach(control => {
            // All the functions that are available on the form control, are now available on the control object and binded to the form control
            this.bindToNgControl(control);

            this.formGroup.controls[control.key].valueChanges.subscribe(value => {
                control.value = value;
                control.errors = this.formGroup.controls[control.key].errors;
            });
        });
    }

    bindToNgControl(control: FormBase<T>) {
        // Binds our methods to the form control so we can call them from the parent component and see the values
        const formControl = this.formGroup.controls[control.key] as FormControl<T>;

        control.setValue = value => {
            formControl.setValue(value);
            control.value = value;
        };

        control.setErrors = errors => {
            formControl.setErrors(errors);
            control.errors = formControl.errors;
        };

        control.disable = () => {
            formControl.disable();
            control.disabled = true;
        };

        control.enable = () => {
            formControl.enable();
            control.disabled = false;
        };
    }

    showError(control: FormBase<T>) {
        return this.formGroup.controls[control.key].invalid;
    }

    getFormError(control: FormBase<T>, details?: string) {
        const errors = this.formGroup.controls[control.key].errors;
        if (errors) {
            if (details && errors['details']) {
                return errors['details'];
            } else if (errors['zod_error'] && !details) {
                return errors['zod_error'];
            } else if (errors && !details) {
                return errors[Object.keys(errors)[0]];
            }
        }
    }

    getControlPhone(lowerType: ControlLowerTypes) {
        return this.controls.find(c => c.lowerType === lowerType);
    }

    getControl(key: string) {
        return this.formGroup.controls[key];
    }

    validateForm() {
        console.log('Validating form', this.formGroup);
        this.formSubmitted = true;
        if (this.formGroup.invalid || this.isFetching()) {
            return;
        }
        try {
            // First check if phone is a control if so we need to check if the phone number is valid
            // Phone schema has a custom validation function
            const phoneControl = this.controls.find(control => control.controlType === 'phone-number');
            if (phoneControl) {
                isValidFormBody(this.formGroup, phoneSchema);
            }

            isValidFormBody(this.formGroup, this.schema());
            this.onSuccess.emit(true);
            // If the form is valid, emit the form values
        } catch (formGroup) {
            console.log('Form is invalid', formGroup);
            this.formGroup = formGroup;
            this.addErrorsToControl();
        }
    }

    // will make it possible to see the errors on the form controls, from parent component
    addErrorsToControl() {
        Object.keys(this.formGroup.controls).forEach(key => {
            const controlErrors = this.formGroup.controls[key].errors;
            if (controlErrors) {
                const controlObj = this.controls.find(control => control.key === key);
                if (controlObj) {
                    controlObj.setErrors(controlErrors);
                }
            }
        });
    }
}

// Should probably be a helper function
function getCallCodesAndCountry(): {
    all: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
    fav: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
} {
    const allList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
    const favList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
    const favCountries: string[] = ['UK', 'BE', 'DK', 'NL', 'NO', 'FI', 'FR', 'ES'];
    for (const item of phoneNumberCountryData) {
        const { callCode, country } = item;
        allList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'all' });

        if (favCountries.includes(item.code)) favList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'fav' });
    }

    // sorting in alphabetical order to easier scan the list in the dropdown
    const res = {
        all: allList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null)),
        fav: favList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null))
    };

    return res;
}
