<!--Desktop View-->
<div class="row cont" style="min-height: 200px" *ngIf="!isMobile">
    <div class="pt-4 col-12 box">
        <div class="" style="padding: 0 1rem 1.25rem 1rem">
            <table style="width: 100%" class="table-title">
                <tr>
                    <th colspan="0" class="header1 sort-label noselect" (click)="sortName()">
                        {{ "customers.company_name" | translate }}
                        <img *ngIf="sortSelected === 'name'" src="../../../assets/icons/{{ sortByName === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.company_name' | translate }}" />
                    </th>
                    <th colspan="0" class="header2 sort-label noselect" (click)="sortPhone()">
                        {{ "customers.phone" | translate }}
                        <img *ngIf="sortSelected === 'phone'" src="../../../assets/icons/{{ sortByPhone === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.phone' | translate }}" />
                    </th>
                    <th colspan="0" class="header3 sort-label noselect" (click)="sortStatus()">
                        {{ "customers.status" | translate }}
                        <img *ngIf="sortSelected === 'status'" src="../../../assets/icons/{{ sortByStatus === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.status' | translate }}" />
                    </th>
                    <th colspan="0" class="header4 sort-label noselect" (click)="sortTotal()" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                        {{ "customers.total" | translate }}
                        <img *ngIf="sortSelected === 'total'" src="../../../assets/icons/{{ sortByTotal === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.total' | translate }}" />
                    </th>
                    <th colspan="0" class="header5 sort-label noselect" (click)="sortOperatorShare()" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                        {{ "customers.operator_share" | translate }}
                        <img *ngIf="sortSelected === 'operatorShare'" src="../../../assets/icons/{{ sortByOperatorShare === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.operator_share' | translate }}" />
                    </th>
                    <th colspan="0" class="header6 sort-label noselect" (click)="sortCustomerShare()" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                        {{ "customers.customer_share" | translate }}
                        <img *ngIf="sortSelected === 'customerShare'" src="../../../assets/icons/{{ sortByCustomerShare === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.customer_share' | translate }}" />
                    </th>
                    <th colspan="0" class="header7 sort-label noselect" (click)="sortCreated()">
                        {{ "customers.created" | translate }}
                        <img *ngIf="!sortSelected || sortSelected === 'created'" src="../../../assets/icons/{{ sortByCreated === 'asc' ? 'arrow-up-blue' : 'arrow-down-blue' }}.svg" alt="{{ 'customers.created' | translate }}" />
                    </th>
                </tr>
            </table>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="subCustomersArr.length">
                <div *ngFor="let subCustomer of subCustomersArr | paginate: pageSize : pageNumber">
                    <div class="card-item-extended" [routerLink]="['/customers/' + subCustomer.key + '/locations']" *ngIf="subCustomer.created">
                        <table style="width: 100%" class="table-content">
                            <tr>
                                @if (subCustomer.call_name) {
                                    <th colspan="0" class="header1">{{ subCustomer.call_name }}</th>
                                } @else {
                                    <th colspan="0" class="header1">{{ subCustomer.name }}</th>
                                }
                                <th colspan="0" [className]="subCustomer.phone.length ? 'header2' : 'header2 phone-missing'">
                                    {{ subCustomer.phone.length ? subCustomer.phone : ("customers.when_complete" | translate) }}
                                </th>

                                <th *ngIf="!allDevicesActiveOnSubCustomer" colspan="0" class="header3 phone-missing">
                                    {{ "customers.fetching_status" | translate }}
                                </th>
                                <th
                                    *ngIf="allDevicesActiveOnSubCustomer"
                                    colspan="0"
                                    [className]="
                                        status(subCustomer.key, subCustomer.status).status === 'complete'
                                            ? 'complete header3 status'
                                            : status(subCustomer.key, subCustomer.status).status === 'inactive_devices'
                                              ? 'inactive_devices header3 status'
                                              : status(subCustomer.key, subCustomer.status).status === 'pending'
                                                ? 'header3 status'
                                                : status(subCustomer.key, subCustomer.status).status === 'restricted_soon'
                                                  ? 'restrictedSoon header3 status'
                                                  : 'incomplete header3 status'
                                    "
                                >
                                    <img
                                        [src]="
                                            status(subCustomer.key, subCustomer.status).status === 'complete'
                                                ? '../../../assets/icons/check-circle.svg'
                                                : status(subCustomer.key, subCustomer.status).status === 'inactive_devices'
                                                  ? '../../../assets/icons/cross.svg'
                                                  : status(subCustomer.key, subCustomer.status).status === 'pending'
                                                    ? '../../../assets/icons/pending.svg'
                                                    : status(subCustomer.key, subCustomer.status).status === 'restricted_soon'
                                                      ? '../../../assets/icons/warning.svg'
                                                      : '../../../assets/icons/cross.svg'
                                        "
                                        alt=""
                                    />

                                    {{ status(subCustomer.key, subCustomer.status).statusLabel }}
                                </th>
                                <th colspan="0" class="header4" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                                    {{ total(subCustomer.total, subCustomer.currency) }}
                                </th>
                                <th colspan="0" class="header5" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                                    {{ operatorShare(subCustomer.operatorShare, subCustomer.currency) }}
                                </th>
                                <th colspan="0" class="header6" *ngIf="authService.hasLimitedAccess('limited_customers_page', role$ | async)">
                                    {{ customerShare(subCustomer.customerShare, subCustomer.currency) }}
                                </th>
                                <th colspan="0" class="header7">
                                    {{ helperService.createdLabelForLists(subCustomer.created) }}
                                </th>
                            </tr>
                        </table>
                    </div>
                </div>

                <mat-paginator
                    id="matpage"
                    [length]="subCustomersArr.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="pageNumber"
                    (page)="handlePage($event)"
                    showFirstLastButtons
                    class="pagination"
                ></mat-paginator>
            </div>

            <p class="m-5 d-flex justify-content-around" *ngIf="!subCustomersArr.length">
                {{ "customers.no_customers_yet" | translate }}
            </p>
        </div>
        <div *ngIf="loading" class="m-5">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
    </div>
</div>

<!--Mobile View-->
<div class="cont" style="min-height: 200px" *ngIf="isMobile">
    <div class="mobile-card empty-card" *ngIf="loading">
        <div>
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
    </div>

    <div class="mobile-card empty-card" *ngIf="subCustomersArr.length === 0 && !loading">
        <div>
            <p>{{ "customers.no_customers_yet" | translate }}</p>
        </div>
    </div>
    <div *ngIf="!loading" class="mobile-body">
        <div *ngIf="subCustomersArr.length">
            <div *ngFor="let subCustomer of subCustomersArr | paginate: pageSize : pageNumber">
                <div class="mobile-card" [routerLink]="['/customers/' + subCustomer.key + '/locations']" *ngIf="subCustomer.created">
                    <p class="card-title">
                        <strong>{{ subCustomer.call_name || subCustomer.name }}</strong>
                    </p>
                    <p
                        *ngIf="allDevicesActiveOnSubCustomer"
                        [className]="
                            status(subCustomer.key, subCustomer.status).className === 'complete'
                                ? 'complete status'
                                : status(subCustomer.key, subCustomer.status).className === 'inactive_devices'
                                  ? 'inactive_devices status'
                                  : 'incomplete status'
                        "
                    >
                        <img
                            src="../../../assets/icons/{{
                                status(subCustomer.key, subCustomer.status).className === 'complete'
                                    ? status(subCustomer.key, subCustomer.status).className === 'incomplete' || status(subCustomer.key, subCustomer.status).className === 'inactive_devices'
                                        ? 'cross'
                                        : 'check-circle'
                                    : 'cross'
                            }}.svg"
                            alt="{{ subCustomer.status === 'complete' ? 'status is complete' : 'status is incomplete' }}"
                        />
                        {{ status(subCustomer.key, subCustomer.status).statusLabel }}
                    </p>
                    <p *ngIf="!allDevicesActiveOnSubCustomer" class="phone-missing">{{ "customers.fetching_status" | translate }}</p>
                </div>
            </div>
            <div class="mobile-paginator">
                <mat-paginator
                    id="matpage"
                    [length]="subCustomersArr.length"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions"
                    [pageIndex]="pageNumber"
                    (page)="handlePage($event)"
                    showFirstLastButtons
                    class="pagination"
                ></mat-paginator>
            </div>
        </div>
    </div>
</div>
