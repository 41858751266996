import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { SubCustomerPermission } from '@airwallet/shared-models/sub-customer';
import { Details } from '@airwallet/shared-models/details';
import { DashboardUser } from '../../../dashboard-models/dashboard-user';
import { CustomerService } from '../customer/customer.service';
import { lastValueFrom } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    baseUrl: string = environment.baseUrl;
    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private authService: AuthService,
        private customerService: CustomerService
    ) {}

    async getSubcustomerPermissionsFromSub(user: DashboardUser): Promise<SubCustomerPermission> {
        return new Promise((resolve, reject) => {
            const subCustomerPermissionSub = this.customerService
                .readSubCustomerPermission(user.uid)
                .snapshotChanges()
                .subscribe(action => {
                    subCustomerPermissionSub.unsubscribe();
                    const subCustomerPermissions = action.payload.val();
                    return resolve(subCustomerPermissions);
                });
        });
    }

    async getOnboardingStatus(customerDetails: Details, user: DashboardUser): Promise<boolean> {
        let subCustomerPermissions: SubCustomerPermission = {
            allow_billing: true,
            allow_location: true,
            allow_refund: true,
            allow_change_billing: true,
            allow_advanced_share: true,
            lock_advanced_share: false,
            allow_coupon: false
        };
        if (user.uid.includes('_operated_by_')) {
            subCustomerPermissions = await this.getSubcustomerPermissionsFromSub(user);
        }
        let onboardingComplete = true;
        const isLife: boolean = customerDetails && customerDetails.agreement_type === 'life';
        const { billing, capabilities, external_account } = customerDetails.onboarding_state;
        if (
            (billing && external_account && capabilities === 'active') ||
            (!isLife && external_account && capabilities === 'active') ||
            (!subCustomerPermissions?.allow_billing && external_account && capabilities === 'active') ||
            (subCustomerPermissions?.allow_billing && subCustomerPermissions?.no_share && billing) ||
            (!subCustomerPermissions?.allow_billing && subCustomerPermissions?.no_share)
        ) {
            onboardingComplete = true;
        } else {
            onboardingComplete = false;
        }

        return onboardingComplete;
    }

    async getStripeKYCUrl(): Promise<string> {
        const stripeKYCUrl = '';
        await lastValueFrom(this.http.get(`${environment.baseUrl}/api_account/stripe_kyc_link?success_url=${window.location.origin}&failure_url=${window.location.origin}`))
            .then((res: any) => {
                window.open(res.url, '_self');
            })
            .catch(err => {
                console.error(err);
            });
        return stripeKYCUrl;
    }
}
